<template>
    <div class="overlay">
        <div v-if="index === 0 || index === 2 || index === 4">
            <v-card class="pa-4  instruction-card">
                <v-card-title class="d-flex justify-center text-center instruction-title">
                    <div v-if="index === 0" >
                        <p class="text-h4 font-weight-bold text-darken-2">
                            📘 INSTRUCCIONES PARTE I - VOCABULARIO
                        </p>
                        <p class="text-body-1">
                            En cada pregunta busque la palabra que se define o se describe.
                        </p>
                        <p class="text-body-1" >Se le mostrará la pregunta enfocada y, al responderla, pasará a la siguiente, así hasta que vuelva al apartado de instrucciones de la siguiente sección.</p>
                        <p class="text-caption font-italic grey--text">
                            Para ver algunos ejemplos de preguntas, revise los ejemplos siguientes:
                        </p>
                    </div>
                    <div v-if="index === 2">
                        <p class="text-h4 font-weight-bold text-darken-2"><strong>INSTRUCCIONES, PARTE II, VELOCIDAD DE COMPRENSIÓN</strong></p>
                        <div class="text-responsive formatted-text">
                            <p>En esta parte de la prueba, debe leer tan rápidamente como pueda, sin cometer errores. En cada oración, busque la palabra que falta.</p>
                            <p class="text-caption font-italic grey--text">
                                Para ver algunos ejemplos de preguntas, revise los ejemplos siguientes:
                            </p>
                        </div>
                    </div>
                    <div v-if="index === 4">
                        <p class="text-h4 font-weight-bold text-darken-2"><strong>INSTRUCCIONES, PARTE III, NIVEL DE COMPRENSIÓN</strong></p>
                        <p class="text-responsive">
                            Lea el párrafo y luego busque las respuestas a las preguntas. Lea el párrafo otra vez si es necesario.
                        </p>
                        <p class="text-caption font-italic grey--text">
                            Ver Ejemplo:
                        </p>
                        <p class="book-paragraph px-4">
                            Texto : El lunes pasado yo ví algo que era más grande que un automóvil. Tenía alas y podía volar más rápido que un águila. Llevaba pasajeros. No le tenía miedo ni a la tierra ni al agua.
                        </p>  
                    </div>
                </v-card-title>
                <!-- Preguntas de ejemplo -->
                <div  class="scrollable-content">
                    <div v-for="(question, qIndex) in questions" :key="question.id" class="pa-4">
                        <v-card class="mt-2 example-question">
                            <v-card-title class="primary white--text">
                                {{ `Ejemplo ${qIndex + 1}: ${question.text}` }}
                            </v-card-title>
                            <v-container class="pa-4" fluid>
                                <v-btn
                                    v-for="option in question.options"
                                    :key="option.id"
                                    class="option-button"
                                    :class="{ 
                                    'yellow darken-2 white--text': studentReply[question.id]?.answerId === option.id,
                                    'green success white--text': question.correctAnswer === option.id && studentReply[question.id]?.answerId 
                                    }"
                                    @click="answerQuestion(question.id, option.id)"
                                    :disabled="question.correctAnswer === option.id && studentReply[question.id]?.answerId"
                                >
                                    {{ `${option.id}) ${option.text}` }}
                                    
                                </v-btn>
                                
                                <!-- Mensaje de retroalimentación -->
                                <div v-if="studentReply[question.id]?.answerId" class="mt-2">
                                    <v-alert
                                    :type="studentReply[question.id]?.answerId === question.correctAnswer ? 'success' : 'error'"
                                    dense
                                    >
                                    {{
                                        studentReply[question.id]?.answerId === question.correctAnswer 
                                        ? '¡Respuesta Correcta!' 
                                        : 'Respuesta Incorrecta'
                                    }}
                                    </v-alert>
                                    <div v-if="studentReply[question.id]?.answerId !== question.correctAnswer" class="correct-answer">
                                    <v-icon color="success">mdi-check-all</v-icon>
                                    <strong>Correcto:</strong> 
                                    {{ question.options.find(o => o.id === question.correctAnswer).text }}
                                    </div>
                                </div>
                                </v-container>
                        </v-card>
                    </div>
                </div>
                <v-card-actions class="instruction-actions">
                    <p class="text-caption red--text mb-2">
                        ⚠️ Espere la autorización del profesor para continuar
                    </p>
                    <v-btn 
                        @click="emitFinish" 
                        class="custom-btn" 
                        style="background-color: rgba(63, 81, 181, 0.8); color: white;"
                        large
                    >
                        <v-icon left>mdi-arrow-right-circle</v-icon>
                        EMPEZAR PRUEBA
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
        <div v-if="index === 1 || index === 3 " class="question-container">
            <div class="basic-question-card">
                <v-card class="mt-2">
                    <v-card-title class="white--text primary">
                        {{ `Pregunta ${currentIndex + 1}: ${currentQuestion.text}` }}
                    </v-card-title>
                    <v-container fluid>
                        <v-btn
                        v-for="option in currentQuestion.options"
                            :key="option.id"
                            :class="{ 
                                'yellow darken-2 white--text': studentReply[currentQuestion.id]?.answerId === option.id
                            }"
                            class="option-button"
                            @click="answerQuestion(currentQuestion.id, option.id)"
                        >
                            {{ `${option.id}) ${option.text}` }}
                        </v-btn>
                    </v-container>
                    <div class="navigation-buttons compact">
                        <button @click="prevQuestion" :disabled="currentIndex === 0">⬅ Anterior</button>
                    </div>
                    <div class="question-counter">
                        Pregunta {{ currentIndex + 1 }} de {{ questions.length }}
                    </div>
                </v-card>
            </div>
        </div>
        <div v-if="index === 5" class="question-container">
            <v-card class="mt-4 pa-4 text-question-card" ref="scrollContainer" >
            <div v-if="currentText" class="mb-4">
                <v-card-title class="d-flex justify-center mb-3" :style="{ backgroundColor: currentTextColor }" >
                    <p class="text-title">{{ currentText.title }}</p>
                </v-card-title>
                <div class="d-flex justify-space-between align-center">
                <v-select
                  v-model="fontSize"
                  :items="fontSizes"
                  label="Tamaño del texto"
                  class="ma-2"
                  dense
                  outlined
                ></v-select>
                <v-select
                  v-model="lineHeight"
                  :items="lineHeights"
                  label="Interlineado"
                  class="ma-2"
                  dense
                  outlined
                ></v-select>
              </div>
                <v-card-text>
                    <div class="book-paragraph"  >
                    <p :style="{ fontSize: fontSize + 'px', lineHeight: lineHeight }" v-for="(paragraph, i) in currentText.paragraphs" :key="i">
                        {{ paragraph }}
                    </p>
                    </div>
                </v-card-text>
                </div>

                <div v-if="currentQuestion" class="question-wrapper">
                    <v-card class="mt-2" >
                        <v-card-title  :style="{ backgroundColor: currentColor }"  >
                            {{ `Pregunta ${currentIndex + 1}: ${currentQuestion.text}` }}
                        </v-card-title>
                        <v-container class="pa-4" fluid>
                            <v-btn
                                v-for="option in currentQuestion.options"
                                :key="`${currentQuestion.id}_${option.id}`"
                                :class="{ 
                                    'yellow darken-2 white--text': studentReply[currentQuestion.id]?.answerId === option.id
                                }"
                                class="option-button"
                                @click="answerQuestion(currentQuestion.id, option.id)"
                            >
                                {{ `${option.id}) ${option.text}` }}
                            </v-btn>
                        </v-container>
                        <div class="navigation-buttons">
                            <button @click="prevQuestion" :disabled="currentIndex === 0"> ⬅ Anterior</button>
                         
                        </div>
                        <div v-if="currentText" class="question-counter">
                            Texto {{ currentTextIndex + 1 }}/{{ texts.length }} y Pregunta {{ currentIndex + 1 }}/{{ questions.length }}
                        </div>
                    
                    </v-card>
                </div>
            </v-card>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        questions: Array,
        studentReply: Object,
        texts: Array,
        index: Number,
        beforeIndex:Number,
    },
    data() {
        return {
            questionStartTime: 0,
            currentIndex: this.beforeIndex,
            fontSize: 16,
            lineHeight: 1.5,
            fontSizes: [12, 14, 16, 18, 20,22,24], 
            lineHeights: [1.2, 1.5, 1.8, 2],
            colors: [
            '#E8F5E9', // Verde claro
            '#E1F5FE', // Azul cielo
            '#FCE4EC', // Rosa pálido
            '#FFF8E1', // Amarillo crema
            '#E8EAF6', // Lila suave
            '#F1F8E9', // Verde menta
            '#F3E5F5', // Lavanda
            '#FFF3E0', // Melocotón claro
            '#E0F7FA', // Azul hielo
            '#FBE9E7', // Coral claro
            '#E8F5E9'  // Verde claro (repetir si necesario)
            ],
            currentColor: ''
        };
    },
    watch: {
        currentIndex: {
            immediate: true,
            handler(newIdx) { // Solo usar newIdx
                if (this.questions && this.questions[newIdx]) {
                    this.questionStartTime = Date.now();
                }
            }
        },
        currentTextIndex(newVal) {
            this.currentColor = this.colors[newVal % this.colors.length];
            
            // Scroll al principio del texto
            this.$nextTick(() => {
                const container = this.$refs.scrollContainer?.$el; // Acceder al elemento nativo
                if(container) {
                container.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                }
            });
        }
    },
    computed: {
        currentQuestion() {
            return Array.isArray(this.questions) && 
                this.questions.length > this.currentIndex 
                ? this.questions[this.currentIndex] 
                : null;
        },
        currentText() {
            if (this.index !== 5 || !this.currentQuestion) return null;
            
            return this.texts.find(text => 
                text.questions.some(q => q.id === this.currentQuestion.id)
            );
            },
        currentTextIndex() {
            if (!this.currentQuestion) return 0;
            return this.texts.findIndex(text => 
                text.questions.some(q => q.id === this.currentQuestion.id)
            );
        }
    },
    methods: {
        answerQuestion(questionId, answerId) {
            const timeElapsed = Date.now() - this.questionStartTime;
            
            this.$emit('answered', {
                questionId: questionId,
                answerId: answerId,
                time: timeElapsed/1000,
                beforeIndex:this.currentIndex,
            });
            
            if ([1, 3, 5].includes(this.index)) {
                if (this.currentIndex < this.questions.length - 1) {
                    this.currentIndex++;
                } else {
                    this.emitFinish();
                }
            }
        },
        emitFinish() {
            this.$emit("finished");
        },
        prevQuestion() {
            if (this.currentIndex > 0) {
                const timeElapsed = Date.now() - this.questionStartTime;
                this.$emit("changeQuestion", {
                    questionId:  this.currentQuestion.id,
                    beforeIndex:this.currentIndex,
                    time: timeElapsed/1000,
                });
                this.currentIndex--;
            }
        }
    },
    mounted() {
        this.currentColor = this.colors[0];
    }
};
</script>  

