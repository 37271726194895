<template>
  <v-card class="mt-4 p-4" width="95vw" v-if="!loading">
    <v-card-text>
      <v-row justify="center">
        <v-card width="90%" class="mt-4 p-4 elevation-2 rounded">
          <v-card-title class="d-flex justify-space-between p-5 primary white--text">
            Modificar datos
            <info-button class="mr-4" title="Información sobre el apartado de Texto" message="
              <ul>
                <li>Puedes modificar los datos del texto en el apartado de arriba incluyendo el autor, título, descripción, año de publicación, tipo de texto y fuente.</li>
                <li>Utiliza el botón 'Guardar cambios' para guardar las modificaciones realizadas en el texto.</li>
                <li>Para cada párrafo, puedes editar el subtítulo, la descripción y añadir una imagen. Las imágenes de los párrafos se utilizan para el minijuego de imágenes.</li>
                <li>Para guardar la imagen en el texto simplemente realice un click en Añadir imagen en el parrafo correspondiente.</li>
                <li>El botón 'Editar párrafo' te permite guardar los cambios realizados en el párrafo específico.</li>
                <li>'AddImageWords' permite añadir imágenes asociadas a palabras específicas en los párrafos.</li>
              </ul>
            "></info-button>
          </v-card-title>

          <v-row class="justify-center pa-2">
            <v-col cols="12" md="3" class="mx-3">
              <v-text-field label="Autor" v-model="textData.author"></v-text-field>
              <v-text-field label="Título" v-model="textData.title"></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="mx-3">
              <v-text-field label="Descripción" v-model="textData.description"></v-text-field>
              <v-text-field label="Fecha Publicación" v-model="textData.publicationYear"></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="mx-3">
              <v-autocomplete label="Tipo" v-model="textData.type" :items="type"></v-autocomplete>
              <v-text-field label="Fuente" v-model="textData.font"></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-center mx-3">
              <v-btn class="ma-2 white--text" @click="editText" color="deep-purple accent-4">
                Guardar cambios
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <v-row class="mt-5" v-for="(data, i) in textParagraphs" :key="i" justify="center">
        <v-card class="mt-4" width="90%">
          <v-card-title class="d-flex justify-space-between p-5" style="background-color: rgba(63, 81, 181, 0.8); color: black;">
            Párrafo {{ i + 1 }}
            <info-button class="mr-4" title="Información sobre esta pagina" message="
              <ul>
                <li>Puedes modificar los datos del texto incluyendo el autor, título, descripción, año de publicación, tipo de texto y fuente.</li>
                <li>Para cada párrafo, puedes editar el subtítulo, la descripción y añadir una imagen. Las imágenes de los párrafos se utilizan para el minijuego de imágenes.</li>
                <li>Utiliza el botón 'Guardar cambios' para guardar las modificaciones realizadas en el texto.</li>
                <li>El botón 'Editar párrafo' te permite guardar los cambios realizados en el párrafo específico.</li>
                <li>El componente 'AddImageWords' permite añadir imágenes asociadas a palabras específicas en los párrafos.</li>
              </ul>
            "></info-button>
          </v-card-title>
          <v-text-field class="ma-3" label="Subtítulo (Opcional)" v-model="data.subTitle"></v-text-field>

          <v-row>
            <v-col :cols="data.image ? 7 : 12">
              <v-textarea class="w-full ma-5" v-model="data.description"></v-textarea>
            </v-col>
            <v-col class="mr-4" v-if="data.image" cols="4">
              <div class="image-container">
                <v-img v-if="data.image" :src="data.image" class="contained-image"></v-img>
              </div>
            </v-col>
          </v-row>

          <v-row class="ma-5">
            <v-col cols="6">
              <v-file-input
                  @change="handleImageChange($event, data.id)" 
                  :loading="loadingFile" 
                  accept="image/*"
                  placeholder="Seleccione una Imagen" 
                  prepend-icon="mdi-camera" 
                  v-model="image"
                  label="Añadir imagen"
                />
            </v-col>
            <v-col cols="6" class="d-flex justify-space-around">
              <v-row class="d-flex justify-space-around">
                <v-btn @click="editParagraph(data)" class="white--text" color="deep-purple accent-4">
                  Editar párrafo
                </v-btn>
                <add-image-words :text="data.description" :paragraphs="textParagraphs" :textId="textId"
                  :images="textData.images_words" @words-saved="getText"></add-image-words>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-card-text>
    <div class="text-center ma-2">
      <v-snackbar :color="color" v-model="snackbar">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-card>
  <v-row v-else justify="center">
    <v-col>
      <v-progress-circular size="200" :width="3" color="primary" indeterminate></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import AddImageWords from '@/components/teacher/text/forms/AddImageWords.vue';
import InfoButton from '@/components/UI/InfoButton.vue';
export default {
  components: { AddImageWords, InfoButton},
  data() {
    return {
      type: ["Texto descriptivo", "Texto expositivo", "Texto científico", "Texto argumentativo", "Texto literario",
        "Texto publicitario", "Texto instructivo", "Texto histórico", "Texto jurídico", "Texto digital", "Texto periodístico"],
      text: '',
      color: '',
      snackbar: false,
      loadingFile: null,
      image: null,
      textData: {},
      textParagraphs: [],
      textSize: '17px',
      textId: null,
      loading: null
    }
  },
  methods: {
    setSnackBar(color, text, snack) {
      this.color = color;
      this.text = text;
      this.snackbar = snack;
    },
    async editText() {
      try {
        // Separar datos editables y excluir la imagen
        const editableData = {
          title: this.textData.title,
          author: this.textData.author,
          description: this.textData.description,
          publicationYear: this.textData.publicationYear,
          type: this.textData.type,
          font: this.textData.font
        };

        // 1. Actualizar datos principales
        await axios.put(
          `teacher/text/editText/${this.textId}`,
          editableData // Enviar solo datos válidos
        );

        this.setSnackBar('green', 'Texto modificado con éxito.', true);
        await this.getText();
      } catch (e) {
        console.error("Error al editar:", e.response?.data);
        this.setSnackBar('red', 'Error: ' + (e.response?.data?.error || e.message), true);
      }
    },
    async editParagraph({ id, description, subTitle }) {
      try {
        const data = { description, subTitle };
        await axios.put('teacher/text/edit/' + id, data);
        this.setSnackBar('green', 'Párrafo modificado con éxito.', true);
      } catch (e) {
        console.log(e);
        this.setSnackBar('red', 'Error al modificar un parrafo.', true);
      }
    },
    handleImageChange(file, id) {
      this.image = file;
      this.addImage(id);
    },

    async addImage(id) {
      if (!this.image) {
        console.log("No image selected");
        return;
      }
      try {
        this.loadingFile = true;
        const formData = new FormData();

        if (typeof this.image === 'string') {
          formData.append('image', this.image);
        } else if (this.image instanceof File) {
          formData.append('image', this.image);
        }
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };

        console.log("ID del párrafo:", id);

        await axios.post(`teacher/text/image/${id}`, formData, config);
        this.setSnackBar('green', 'Imagen agregada con éxito.', true);
        await this.getText();
      } catch (e) {
        this.setSnackBar('red', 'Error al agregar la imagen.', true);
        this.image = null;
        this.loadingFile = false;
      } finally {
        this.image = null;
        this.loadingFile = false;
      }
    },
    async getText() {
      const response = await axios.get(`teacher/text/${this.textId}`);
      this.textData = { 
        ...response.data.text,
      };
      this.textParagraphs = response.data.text.paragraph;
      console.log(response.data.text.paragraph);
    },
  },
  async created() {
    this.loading = true;
    this.textId = this.$route.params.id;
    await this.getText();
    this.loading = false;
  },
}
</script>
