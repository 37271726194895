<template>
    <v-container  fluid>
  
      <v-row fluid class="mt-8 justify-center" style="flex-wrap: wrap-reverse;" >
        <v-col cols="12" md="8" lg="7" class="mb-4 mb-md-0">
        <v-card class="h-100" outlined style="border: 0.2px solid #333;">
            <add-teacher :bottom-style="false" />
          </v-card> 
        </v-col>

        <v-col cols="12" md="4" lg="3">
          <v-card class="h-100 d-flex flex-column justify-center" outlined style=" height: 100%; border: 0.2px solid #333;">
            <v-card-actions >
              <v-col class="justify-center align-center py-5">
                <v-row class="justify-center align-center ">
                  <edit-user></edit-user>                
                </v-row>
                <v-row class="py-5 justify-center align-center">
                  <AddStudent></AddStudent>
                </v-row>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      
  
      <v-row v-if="loading" justify="center">
        <v-col class="d-flex justify-center align-center">
          <v-progress-circular v-if="loading" size="200" :width="3" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
  
    </v-container>
  </template>
  
  <script>
  
  import AddTeacher from '@/components/teacher/admin/AddTeacher.vue';
  import EditUser from '@/components/teacher/admin/EditUser';
  import AddStudent from '@/components/teacher/admin/AddStudent.vue';

  export default {
  
    data() {
      return {
        loading: null,
      }
    },
    components: { AddTeacher, EditUser, AddStudent},
    computed: {
      
    },
  
    methods: {
      
    },
    
  }
  </script>
