<template>
  <v-app color="grey lighten-4" flat height="100%" width="100%" tile>
    <v-navigation-drawer v-model="drawer" app :width="miniVariant ? '60' : '200'" mini-variant.sync="miniVariant" permanent color="primary">
      <v-list dense>
        <v-list-item v-if="!miniVariant">
          <v-list-item-content>
            <v-list-item-title class="title white--text">
              Rol
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">Estudiante</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-app-bar-nav-icon class="white--text" @click.stop="miniVariant = !miniVariant"></v-app-bar-nav-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="miniVariant">
          <v-list-item-action>
            <v-app-bar-nav-icon class="white--text" @click.stop="miniVariant = !miniVariant"></v-app-bar-nav-icon>
          </v-list-item-action>
        </v-list-item>
        
        <v-list-item v-if="!miniVariant">
          <v-list-item-content>
            <v-list-item-title class="title white--text">
              {{ userInfo ? `${userInfo.name} ${userInfo.lastname} ${userInfo.secondLastName}` : '' }}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">{{ userInfo ? userInfo.email : '' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list>
        <v-list-item @click="changePage('asignaturas')">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-school</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!miniVariant">
            <v-list-item-title class="white--text">Asignaturas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-row v-if="!miniVariant">
        <v-col class="mt-10" cols="12">
          <v-btn @click="logOut" block color="error">Cerrar Sesión</v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <v-main>
      <v-container class="pa-0" fluid>
        <!-- Toolbar -->
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ currentRouteName }}</v-toolbar-title>
        </v-toolbar>

        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      miniVariant: false,
      group: null,
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo;
    },
    currentRouteName() {
      const name = this.$route.path.split('/')[2];
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  },
  methods: {
    async logOut() {
      await this.$store.dispatch("logOut");
      await this.$router.push("/login");
    },
    changePage(where) {
      if (where === 'asignaturas' && this.$route.path !== "/estudiante/" + where) {
        this.$router.push("/estudiante/" + where);
      }
    }
  },
};
</script>
