import StudentScreen from "../pages/students/StudentScreen";
import StudentLessons from "../pages/students/StudentLessons";
import ActivityDetail from "../pages/students/activities/unfinish/ActivityDetail";
import StudentActivities from "../pages/students/StudentActivities";
import StudentPolls from "../pages/students/StudentPolls";
import PollDetail from "../pages/students/poll/unfinish/PollDetail";
import PollLectum from "../pages/students/poll/unfinish/PollLectum";
import DetailFinish from "../pages/students/activities/finish/DetailFinish";
import PollInteramericana from "../pages/students/poll/unfinish/PollInteramericana";
import PollHabitosLectores from "../pages/students/poll/unfinish/PollHabitosLectores";
import store from "../store";

export const studentRoute = {
  path: "/estudiante",
  redirect: "estudiante/asignaturas",
  component: StudentScreen,
  async beforeEnter(to, from, next) {
    try {
      await store.dispatch("userInfo");
      const userRole = store.getters.getUserInfo.rol;
      if (userRole === 1) {
        next("/profe");
      } else if (userRole === 0) {
        next();
      } else {
        next("/login");
      }
    } catch (error) {
      next("/login");
    }
  },
  children: [
    { path: "asignaturas", component: StudentLessons },
    { path: "actividades/:id", component: StudentActivities, props: true },
    { path: "diagnosticos/:id", component: StudentPolls, props: true },
    { path: "diagnostico/LectumA/:id", component: PollLectum, props: true },
    { path: "diagnostico/LectumB/:id", component: PollLectum, props: true },
    {
      path: "diagnostico/Interamericana/:id",
      component: PollInteramericana,
      props: true,
    },
    {
      path: "diagnostico/Habitos/:id",
      component: PollHabitosLectores,
      props: true,
    },
    { path: "diagnostico/:id", component: PollDetail, props: true },
    { path: "textos/:id", component: ActivityDetail, props: true },
    { path: "finalizada/:id", component: DetailFinish },
  ],
};
