<template>
  <v-dialog v-model="showModal" persistent max-width="800">
    <v-card>
      <v-card-title class="d-flex align-center primary white--text">
        <v-icon left>mdi-table</v-icon>
        Respuestas para: {{ subQuestionText }} → {{ optionText }}
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('update:showModal', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-card-text>
        <v-list dense>
          <v-subheader>Estudiantes que seleccionaron esta opción ({{ filteredReplies.length }})</v-subheader>
          
          <v-list-item
            v-for="(reply, index) in filteredReplies"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title>{{ reply.student.name }} {{ reply.student.lastName }}</v-list-item-title>
              <v-list-item-subtitle>{{ reply.student.email }}</v-list-item-subtitle>
            </v-list-item-content>
            
    
          </v-list-item>
          
          <v-alert v-if="!filteredReplies.length" type="info" class="mt-4">
            No hay respuestas para esta combinación
          </v-alert>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    questionId: Number,
    subQuestionId: Number,
    optionId: Number,
    replies: Array,
    questions: Array // Nuevo prop para recibir las preguntas
  },
  
  computed: {
    filteredReplies() {
      return this.replies.filter(reply => {
        const answer = reply.answer[this.questionId]?.answerId;
        return answer && answer[this.subQuestionId] === this.optionId;
      });
    },
    
    subQuestionText() {
      const question = this.questions.find(q => q.id === this.questionId);
      if (!question) return '';
      const subQuestion = question.sub_questions.find(sq => sq.id === this.subQuestionId);
      return subQuestion?.text || '';
    },
    
    optionText() {
      const question = this.questions.find(q => q.id === this.questionId);
      if (!question) return '';
      const option = question.options.find(o => o.id === this.optionId);
      return option?.text || '';
    }
  },

};
</script>