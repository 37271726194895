<template>
    <v-dialog v-model="modalOpen" max-width="1200" persistent>
      <v-card v-if="reply">
        <v-card-title class="primary white--text">
          Respuestas de {{ reply.student.name }} {{ reply.student.lastName }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
  
        <v-card-text class="mt-3 text-h6">
          Tiempo total: {{ formatTime(reply.time) }}  
        </v-card-text>
  
        <div class="mt-4 pa-4" v-for="(question, index) in questions" :key="question.id">
          <v-card class="mb-6 question-section">
            <v-card-title class="white--text book-paragraph primary">
              <v-icon left color="white">mdi-comment-question</v-icon>
              {{ `Pregunta ${index + 1}: ${question.text}` }}
            </v-card-title>
  
            <v-card-text>
              <!-- Single Choice -->
              <div v-if="question.type === 'single_choice'" class="options-container">
                <v-radio-group :model-value="getAnswer(question.id)?.answerId">
                    <v-radio
                        v-for="option in question.options"
                        :key="`option-${question.id}-${option.id}`"
                        :value="option.id"
                        color="success"
                        readonly
                    >
                        <template v-slot:label>
                        <span :class="{ 'selected-option': getAnswer(question.id)?.answerId === option.id }">
                            {{ option.text }}
                        </span>
                        </template>
                    </v-radio>
                    </v-radio-group>
              </div>
  
              <!-- Multiple Choice -->
              <div v-if="question.type === 'multiple_choice'" class="options-container">
                <v-checkbox
                v-for="option in question.options"
                :key="`moption-${question.id}-${option.id}`"
                :label="option.text"
                :value="option.id"
                :model-value="getAnswer(question.id)?.answerId"
                color="success"
                readonly
                class="custom-checkbox"
                >
                <template v-slot:label>
                    <span :class="{
                    'selected-option': getAnswer(question.id)?.answerId?.includes(option.id)
                    }">
                    {{ option.text }}
                    </span>
                </template>
                </v-checkbox>
              </div>
  
              <!-- Matrix -->
              <div v-if="question.type === 'matrix'" class="matrix-container">
                <v-table class="matrix-table">
                  <thead>
                    <tr class="matrix-header">
                      <th class="subquestion-header"></th>
                      <th 
                        v-for="option in question.options" 
                        :key="`header-${question.id}-${option.id}`"
                        class="option-header"
                      >
                        {{ option.text }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr 
                      v-for="sub in question.sub_questions" 
                      :key="`row-${question.id}-${sub.id}`"
                      class="matrix-row"
                    >
                      <td class="subquestion-cell">{{ sub.text }}</td>
                      <td 
                        v-for="option in question.options" 
                        :key="`cell-${question.id}-${sub.id}-${option.id}`"
                        class="option-cell"
                      >
                      <v-radio-group v-model="reply.answer[question.id].answerId[sub.id]"  class="radio-group">
                        <v-radio 
                            :value="option.id" 
                        :key="`cell-${question.id}-${sub.id}-${option.id}`"
                            color="success"
                            class="matrix-radio"
                        >
                
                        <template v-slot:label>
                        <span :class="{
                            'selected-option': getMatrixAnswer(question.id, sub.id) === option.id
                        }">
                                {{ option.text }}
                            
                        </span>
                        </template>
                    </v-radio>
                    </v-radio-group>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </div>
  
              <!-- Open Question -->
              <v-textarea
                v-if="question.type === 'open'"
                :model-value="getAnswer(question.id)?.answerText"
                readonly
              />
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  

  export default {
    props: {
        modalOpen: { type: Boolean, required: true }, // Forzar como obligatorio
      reply: Object,
      questions: Array, 
      pollActivity: Object
    },
    data() {
      return {
        currentTab: 0,
        sections: []
      };
    },
    methods: {
        getAnswer(questionId) {
      return this.reply.answer[String(questionId)];
    },

    // Método para respuestas de matriz
    getMatrixAnswer(questionId, subId) {
      const answer = this.getAnswer(questionId)?.answerId;
      return answer ? answer[subId] : null;
    },
      formatTime(seconds) {
        if (!seconds || seconds < 0) return 'N/A';

        const totalSeconds = Math.floor(seconds); // Redondear correctamente
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;

        return minutes > 0
        ? `${minutes}m${remainingSeconds}s`
        : `${remainingSeconds}s`;
      },
      closeModal() {
        console.log(this.questions)
        this.$emit('update:modalOpen', false);
      }
    }
  };
  </script>
