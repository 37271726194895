<template>
  <v-card width="100%">
    <v-text-field class="px-5" v-model="search" append-icon="mdi-magnify" 
                 label="Buscar estudiante" single-line hide-details></v-text-field>
    <v-data-table height="300px" fixed-header :search="search" 
                :headers="filteredHeaders" :items="processedReplies" 
                item-key="id" class="m-3 elevation-1">
      <template v-slot:no-data>
        <p>Nadie ha realizado la actividad.</p>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="showDetails(item)">
          <v-icon color="primary" outlined>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.total_time="{ item }">
        {{ formatTime(item.total_time) }}
      </template>
      <template v-slot:item.percentage="{ item }">
        {{ calculatePercentage(item.points) }}
      </template>
    </v-data-table>

    <StudentReplyDiagnostic
        :modalOpen.sync="showModal"
        :reply="currentReply"
        :idActividad="pollActivityId"
        @closed="showModal = false"
        :diagnosticType="diagnosticIs"
        :studentSectionScores="currentSectionScores"
        :sections="sections"
      />
  </v-card>
</template>

<script>
import StudentReplyDiagnostic from './StudentReplyDiagnostic.vue';

export default {
  props: {
    replies: {
      type: Array,
      required: true
    },
    totalPoints: {
      type: Number,
      default: 50
    },
    diagnosticIs: {
      type: String,
      required: true
    },
    pollActivityId: {
      type: Number,
      required: true
    },
    sectionScoresByStudent:{
      type: Array,
      required: true
    },
    sections:{
      Object
    }
  },
  components: { StudentReplyDiagnostic },
  data() {
    return {
      search: '',
      showModal: false,
      currentReply: null,
      baseHeaders: [
        { text: 'Nombre', value: 'student.name', sortable: false },
        { text: 'Apellido', value: 'student.lastName', sortable: false },
        { text: 'Puntos', value: 'points', sortable: false },
        { text: 'Nota %', value: 'percentage', sortable: false },
        { text: 'Ver datos', value: 'actions', sortable: false }
      ],
      timeHeader: { 
        text: 'Tiempo total', 
        value: 'total_time', 
        sortable: false 
      },
      currentSectionScores:{}
    };
  },
  computed: {
    filteredHeaders() {
      if (this.diagnosticIs === 'Interamericana') {
        return [this.baseHeaders[0], this.baseHeaders[1], this.timeHeader, ...this.baseHeaders.slice(2)];
      }
      return this.baseHeaders;
    },
    processedReplies() {
      return this.replies.map(reply => {
        const processed = {
          ...reply,
          percentage: this.calculatePercentage(reply.points),
        };

        if (this.diagnosticIs === 'Interamericana') {
          processed.total_time = this.calculateTotalTime(reply);
        }

        return processed;
      });
    }
  },
  methods: {
    calculateTotalTime(reply) {
      try {
        const answers = JSON.parse(reply.answer);
        return Object.values(answers).reduce((total, answer) => total + (answer.time || 0), 0);
      } catch (error) {
        console.error('Error parsing answers:', error);
        return 0;
      }
    },
    formatTime(seconds) {
      if (this.diagnosticIs !== 'Interamericana') return 'N/A';
      
      const totalSeconds = Math.floor(seconds); // Redondear correctamente
      const minutes = Math.floor(totalSeconds / 60);
      const remainingSeconds = totalSeconds % 60;

      return minutes > 0
        ? `${minutes}m${remainingSeconds}s`
        : `${remainingSeconds}s`;
    },
    calculatePercentage(points) {
      return ((points / this.totalPoints) * 100).toFixed(2) + '%';
    },
    showDetails(item) {
      this.currentReply = item;
      this.currentSectionScores = this.sectionScoresByStudent[item.id]; 
      this.showModal = true;
    }
  }
};
</script>