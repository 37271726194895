var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('v-card',{staticClass:"mt-4 pa-4 scrollable-content--large",attrs:{"flat":""}},[_vm._l((_vm.questions),function(question,index){return [_c('v-card',{key:'question-'+question.id,staticClass:"mb-6 question-section"},[_c('v-card-title',{staticClass:"white--text book-paragraph primary"},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("mdi-comment-question")]),_vm._v(" "+_vm._s(`Pregunta ${index + 1}: ${question.text}`)+" ")],1),_c('v-card-text',[(question.type === 'single_choice')?_c('div',{staticClass:"options-container"},[_c('v-radio-group',{on:{"change":_vm.saveToLocalStorage},model:{value:(_vm.studentReply[question.id].answerId),callback:function ($$v) {_vm.$set(_vm.studentReply[question.id], "answerId", $$v)},expression:"studentReply[question.id].answerId"}},_vm._l((question.options),function(option){return _c('v-radio',{key:`option-${question.id}-${option.id}`,attrs:{"value":option.id,"color":"success"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"option-label"},[_vm._v(_vm._s(option.text))])]},proxy:true}],null,true)})}),1)],1):_vm._e(),(question.type === 'multiple_choice')?_c('div',{staticClass:"options-container"},[_c('medium',{staticClass:"caption"},[_vm._v(" Seleccione "+_vm._s(question.max_selections)+" opciones ")]),_c('medium',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.selectionCounter(question.id))+" de "+_vm._s(question.max_selections)+" seleccionadas ")]),_vm._l((question.options),function(option){return _c('v-checkbox',{key:`moption-${question.id}-${option.id}`,staticClass:"custom-checkbox",attrs:{"label":option.text,"value":option.id,"disabled":_vm.isOptionDisabled(question.id, option.id),"color":"success"},on:{"change":_vm.saveToLocalStorage},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:{
                    'selected-option': _vm.isChecked(question.id, option.id),
                    'disabled-option': _vm.isOptionDisabled(question.id, option.id)
                    }},[_vm._v(" "+_vm._s(option.text)+" ")])]},proxy:true}],null,true),model:{value:(_vm.studentReply[question.id].answerId),callback:function ($$v) {_vm.$set(_vm.studentReply[question.id], "answerId", $$v)},expression:"studentReply[question.id].answerId"}})})],2):_vm._e(),(question.type === 'matrix')?_c('div',{staticClass:"matrix-container"},[_c('v-table',{staticClass:"matrix-table"},[_c('thead',[_c('tr',{staticClass:"matrix-header"},[_c('th',{staticClass:"subquestion-header"}),_vm._l((question.options),function(option){return _c('th',{key:`header-${question.id}-${option.id}`,staticClass:"option-header"},[_vm._v(" "+_vm._s(option.text)+" ")])})],2)]),_c('tbody',_vm._l((question.sub_questions),function(sub){return _c('tr',{key:`row-${question.id}-${sub.id}`,staticClass:"matrix-row"},[_c('td',{staticClass:"subquestion-cell"},[_vm._v(_vm._s(sub.text))]),_vm._l((question.options),function(option){return _c('td',{key:`cell-${question.id}-${sub.id}-${option.id}`,staticClass:"option-cell"},[_c('v-radio-group',{staticClass:"radio-group",on:{"change":function($event){return _vm.handleMatrixChange(question.id, sub.id, $event)}},model:{value:(_vm.studentReply[question.id].answerId[sub.id]),callback:function ($$v) {_vm.$set(_vm.studentReply[question.id].answerId, sub.id, $$v)},expression:"studentReply[question.id].answerId[sub.id]"}},[_c('v-radio',{staticClass:"matrix-radio",attrs:{"value":option.id,"color":"success"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:{
                            'selected-option': _vm.studentReply[question.id].answerId[sub.id] === option.id,
                        }},[_vm._v(" "+_vm._s(option.text)+" ")])]},proxy:true}],null,true)})],1)],1)})],2)}),0)])],1):_vm._e(),(question.type === 'open')?_c('v-textarea',{on:{"input":function($event){return _vm.handleAnswerChange(question.id, 'open', $event)}},model:{value:(_vm.studentReply[question.id].answerText),callback:function ($$v) {_vm.$set(_vm.studentReply[question.id], "answerText", $$v)},expression:"studentReply[question.id].answerText"}}):_vm._e()],1)],1)]}),_c('v-card-actions',{staticClass:"d-flex justify-center sticky-footer"},[_c('v-btn',{staticClass:"white--text",attrs:{"x-large":"","color":"deep-purple accent-4"},on:{"click":_vm.submit}},[_vm._v(" Terminar Diagnóstico ")])],1)],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded-lg text-center"},[_c('div',{staticClass:"rounded-lg",staticStyle:{"background-color":"rgba(63, 81, 181, 0.8)","color":"white"}},[_c('h1',{staticClass:"ml-4 pt-2"},[_vm._v("Diagnóstico de Hábitos Lectores")])])])
}]

export { render, staticRenderFns }