<template>
    <div>
      <!-- Tabs para navegar entre secciones -->
      <v-tabs v-model="currentTab" grow color="deep-purple">
        <v-tab v-for="(section, index) in sections" :key="index">
          <div v-if="index===0 || index===2 || index===4">
            <v-icon left>mdi-information</v-icon>
              Intrucciones 
          </div>
          <div v-if="index===1">
              <v-icon left>mdi-format-letter-case</v-icon>
              Parte I, Vocabulario
          </div>
          <div v-if="index===3">
              <v-icon left>mdi-av-timer</v-icon>
              Parte II, Nivel de velocidad
          </div>
          <div v-if="index===5">
              <v-icon left>mdi-book-open</v-icon>
              Parte III, Nivel de comprensión
          </div>
        </v-tab>
      </v-tabs>

      <!-- Contenido de las secciones -->
      <v-tabs-items touchless v-model="currentTab">
        <v-tab-item v-for="(section, index) in sections" :key="index">
          <div class="pa-4">
            <v-card class="mt-4 pa-2">
                <!-- Secciones de Intrucción -->
                <div  class="scrollable-content--large" v-if="[0, 2, 4].includes(index)">
                  <v-card-title class="d-flex justify-center align-center">
                      <div class="text-center" v-if="index === 0" >
                          <p class="text-h4 font-weight-bold  text-darken-2">
                              📘 INSTRUCCIONES PARTE I - VOCABULARIO
                          </p>
                          <p class="text-body-1">
                              En cada pregunta busque la palabra que se define o se describe.
                          </p>
                          <p class="text-body-1">
                            Se le mostrará la pregunta enfocada y, al responderla, pasará a la siguiente, así hasta que vuelva al apartado de instrucciones de la siguiente sección.                          </p>
                            <p class="text-caption font-italic grey--text">
                            Para ver algunos ejemplos de preguntas, revise los ejemplos siguientes:
                        </p>
                      </div>
                      <div class="text-center" v-if="index === 2">
                        <p class="text-h4 font-weight-bold  text-darken-2">
                         📘 INSTRUCCIONES, PARTE II, VELOCIDAD DE COMPRENSIÓN</p>
                          <p class="text-body-1">
                            En esta parte de la prueba debe leer tan rápidamente como pueda sin cometer errores. En cada oración busque la palabra que falta. 
                          </p>
                          
                          <p class="text-caption font-italic grey--text">
                            Para ver algunos ejemplos de preguntas, revise los ejemplos siguientes:
                        </p>
                      </div>
                      <div class="text-center" v-if="index === 4">
                          <p  class="text-h4 font-weight-bold  text-darken-2">📘 INSTRUCCIONES, PARTE III, NIVEL DE COMPRENSIÓN</p>
                          <p class="text-body-1">
                            Lea el párrafo y luego busque las respuestas a las preguntas. Lea el párrafo otra vez si es necesario.
                          </p>
                          <p class="text-caption font-italic grey--text">
                            Ver Ejemplo:
                        </p>
                          <p class="book-paragraph pa-5">
                              Texto : El lunes pasado yo ví algo que era más grande que un automóvil. Tenía alas y podía volar más rápido que un águila. Llevaba pasajeros. No le tenía miedo ni a la tierra ni al agua.
                          </p>  
                      </div>
                  </v-card-title>
                  <div >
                      <div v-for="(question, qIndex) in section.questions" :key="question.id" class="pa-4">
                          <v-card class="mt-2 example-question">
                              <v-card-title class="primary white--text">
                                  {{ `Ejemplo ${qIndex + 1}: ${question.text}` }}
                              </v-card-title>
                              <v-container class="pa-4 correct-answer" fluid>
                                  <v-btn
                                      v-for="option in question.options"
                                      :key="option.id"
                                      class="option-button"
                                      :class="{ 'green darken-2 white--text': question.correctAnswer === option.id }"
                                  >
                                      {{ `${option.id}) ${option.text}` }}
                                  </v-btn>
                              </v-container>
                          </v-card>
                      </div>
                  </div>
                </div>
               <!-- Secciones de Vocabulario y Velocidad -->

                <div class="scrollable-content--large" v-if="index === 1 || index === 3 " >
                  <div v-for="(question, qIndex) in section.questions" :key="question.id" class="pa-4" >
                      <v-card class="mt-2">
                          <v-card-title class="white--text book-paragraph primary">
                              {{ `Pregunta ${qIndex + 1}: ${question.text}` }}
                          </v-card-title>
                          <v-container class="pa-4" fluid>
                            <v-radio-group :value="question.correctAnswer" readonly>
                                <v-radio v-for="option in question.options" 
                                        :key="option.id"
                                        :value="option.id"
                                        color="success"
                                        disabled>
                                  <template v-slot:label>
                                      <span :class="{ 'correct-answer': option.id === question.correctAnswer }">
                                      <div class="px-2">{{ option.text }} <v-icon v-if="option.id === question.correctAnswer" 
                                              color="success">
                                          mdi-check
                                      </v-icon></div>
                                      </span>
                                  </template>
                                </v-radio>
                            </v-radio-group>
                          </v-container>
                      </v-card>
                    </div>
                </div>
                <div class="scrollable-content--large"  v-if="index === 5" >
                  <div class="navigation">
                      <v-btn 
                        @click="prevText" 
                        :disabled="currentIndex === 0"
                        color="primary"
                        class="mr-2"
                      >
                        <v-icon left>mdi-chevron-left</v-icon>
                        Anterior
                      </v-btn>
                      
                      <span class="text-caption">
                        Texto {{ currentIndex + 1 }} de {{ sections[5].texts.length }}
                      </span>
                      
                      <v-btn 
                        @click="nextText" 
                        :disabled="currentIndex >= sections[5].texts.length - 1"
                        color="primary"
                        class="ml-2"
                      >
                        Siguiente
                        <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <div v-if="currentText" class="text-center mt-2">
                        <h2 class="text--title mb-4">{{ currentText.title }}</h2>
                        <div class="book-paragraph px-3">
                          <p v-for="(paragraph, i) in currentText.paragraphs" :key="i" v-html="paragraph.replace(/\n/g, '<br>')"></p>

                        </div>
                    </div>

                    <div  v-if="currentText"  class="pa-4" >
                      <v-card v-for="(question, qIndex) in currentText.questions" :key="question.id" class="mt-2">
                          <v-card-title class="white--text book-paragraph primary">
                              {{ `Pregunta ${qIndex + 1}: ${question.text}` }}
                          </v-card-title>
                          <v-container class="pa-4" fluid>
                            <v-radio-group :value="question.correctAnswer" readonly>
                                <v-radio v-for="option in question.options" 
                                        :key="option.id"
                                        :value="option.id"
                                        color="success"
                                        disabled>
                                  <template v-slot:label>
                                      <span :class="{ 'correct-answer': option.id === question.correctAnswer }">
                                      <div class="px-2">{{ option.text }} <v-icon v-if="option.id === question.correctAnswer" 
                                              color="success">
                                          mdi-check
                                      </v-icon></div>
                                      </span>
                                  </template>
                                </v-radio>
                            </v-radio-group>
                          </v-container>
                      </v-card>
                    </div>
                </div>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </template>
  
  <script>

import axios from 'axios';
  
  export default {
    data() {
      return {
        currentTab: 0,
        sections: [],
        currentIndex:0,
        currentText:null,
      };
    },
    async created() {
      await this.loadData();
    },
    watch:{
      currentTab(val){
        if (val === 5) {
        this.currentIndex = 0;
        this.currentText = this.sections[5].texts[this.currentIndex];
      }
      }
    },
    methods: {
      prevText() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
          this.currentText = this.sections[5].texts[this.currentIndex];
        }
      },
      nextText() {
        if (this.currentIndex < this.sections[5].texts.length - 1) {
          this.currentIndex++;
          this.currentText = this.sections[5].texts[this.currentIndex];
        }
      },
      async loadData() {
        try {
          const [textsRes, questionsRes] = await Promise.all([
              axios.get("/interamericana"),
              axios.get("/questionInteramericana"),
          ]);
  
          const texts = textsRes.data.texts;
          const questionsBySection = questionsRes.data.questionsBySection;

          // Reestructurar secciones
          this.sections = [
              { type: 'instructions', index: 0 , questions: questionsBySection[0]|| []}, // Sección 0
              { type: 'questions', index: 1, questions: questionsBySection[1] || [] }, // Sección 1
              { type: 'instructions', index: 2 , questions: questionsBySection[2]|| []}, // Sección 2
              { type: 'questions', index: 3, questions: questionsBySection[3] || [] }, // Sección 3
              { type: 'instructions', index: 4 , questions: questionsBySection[4]|| []}, // Sección 4
              { 
                  type: 'texts', 
                  index: 5,
                  texts: texts.map(text => ({
                      ...text,
                      paragraphs: text.paragraph[0].description? text.paragraph[0].description.split('\n') : "[]",
                      questions: questionsBySection[5]?.filter(q => q.description === text.description) || []
                  })) 
              }
          ];
        } catch (error) {
          console.error("Error loading data:", error);
        }
      },

    }
  };
  </script>