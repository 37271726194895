<template>
  <v-card class="mx-auto d-flex flex-column equal-height-card">    <!-- Imagen con altura controlada -->
    <v-img
      class="flex-grow-0"
      :max-height="$vuetify.breakpoint.mobile ? 150 : 200"
      :src="computedImage"
      cover
    ></v-img>

    <v-card-title class="text-break word-wrap">{{ title }}</v-card-title>

    <v-card-text 
      class="flex-grow-1 overflow-y-auto py-0"
      style="max-height: 200px;"
    >
      <div class="description-content">
        <p 
          v-for="(line, index) in parsedDescription" 
          :key="index"
          class="description-line"
        >
          {{ line }}
        </p>
      </div>
    </v-card-text>


    <v-card-actions class="flex-grow-0 pt-2">
  <v-col cols="12" class="px-2">
    <div class="d-flex flex-column gap-2 w-100">
      <div class="d-flex justify-center align-center w-100">
        <slot name="activities"></slot>
      </div>
      <div class="d-flex justify-center align-center w-100">
        <slot name="polls"></slot>
      </div>
    </div>
  </v-col>
</v-card-actions>

  </v-card>
</template>

<script>
export default {
  computed: {
     parsedDescription() {
      return this.description?.split('\n') || []
    },
    cardImage() {
      if(this.image === 'question') return 'question.jpg'
      if(this.image === 'text') return 'text.jpg'
      if(this.image === 'lesson') return 'lesson.jpg'
      if(this.image === 'activity') return 'activity.jpg'
      return 'question.jpg';
    },
    computedImage() {
      // Verifica si hay una imagen personalizada, de lo contrario usa la imagen predeterminada basada en el tipo de card
      return this.customImage ? this.customImage : require(`@/assets/images/${this.cardImage}`);
    }
  },
  props: ["title", "description", "image", "customImage"],
  methods: {
  }
}
</script>

