<template>
    <v-dialog v-model="showModal" max-width="1200" persistent>
      <v-card v-if="reply">
        <v-card-title class="primary white--text">
          Respuestas de {{ reply.student.name }} {{ reply.student.lastName }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3 text-h6">
          Tiempo total: {{ formatTime(reply.time) }}  
        </v-card-text>
  
        <v-tabs v-model="currentTab" grow>
          <v-tab v-for="(section, index) in sections" :key="index">
            {{ section.title }}
          </v-tab>
        </v-tabs>
  
        <v-tabs-items touchless v-model="currentTab">
          <v-tab-item v-for="(section, index) in sections" :key="index">
            <v-card flat>
              <div v-for="(question, qIndex) in section.questions" :key="question.id" class="ma-4">
                <v-card :class="answerClass(question.id)">
                  <v-card-title  style="background-color: rgba(63, 81, 181, 0.8); color: white;">
                    Pregunta {{ qIndex + 1 }}: {{ question.description }}
                  </v-card-title>
  
                  <v-card-text>
                    <!-- Para preguntas de opción múltiple -->
                    <template v-if="question.type !== 3">
                      <v-radio-group :value="question.studentAnswerId" readonly>
                        <v-radio
                          v-for="option in question.options"
                          :key="option.id"
                          :value="option.id"
                          :color="option.id === question.studentAnswerId ? 'green' : ''"
                        >
                          <template v-slot:label>
                            <div class="d-flex align-center">
                              <v-icon
                                v-if="option.id === question.studentAnswerId"
                                color="green"
                                class="mr-2"
                              >
                                mdi-check-circle
                              </v-icon>
                              <span>{{ option.description }}</span>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      
                      <div class="mt-2 primary--text">
                        <v-icon color="green" left>mdi-information</v-icon>
                        Puntuación obtenida: {{ getOptionPoints(question) }} pts
                      </div>
                    </template>

                    <!-- Para preguntas de desarrollo -->
                    <template v-else>
                      <v-textarea
                        :value="question.studentAnswerText"
                        readonly
                        outlined
                        auto-grow
                        class="mt-2"
                      ></v-textarea>
                    </template>
                  </v-card-text>
                </v-card>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      modalOpen: Boolean,
      reply: Object,
      pollActivity: Object
    },
    data() {
      return {
        showModal: false,
        currentTab: 0,
        sections: []
      };
    },
    watch: {
      modalOpen(val) {
        this.showModal = val;
        if (val) this.loadStructure();
      }
    },
    methods: {
      formatTime(seconds) {
        if (!seconds || seconds < 0) return 'N/A';

        const totalSeconds = Math.floor(seconds); // Redondear correctamente
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;

        return minutes > 0
        ? `${minutes}m${remainingSeconds}s`
        : `${remainingSeconds}s`;
      },
      loadStructure() {
        const sectionsMap = {};

        this.pollActivity.questions.forEach(question => {
          const section = question.section || 'Sin Sección';
          if (!sectionsMap[section]) {
            sectionsMap[section] = {
              title: section,
              questions: []
            };
          }
          
          sectionsMap[section].questions.push({
            ...question,
            options: [] // Se llenarán en processAnswers
          });
        });

        this.sections = Object.values(sectionsMap);
        this.processAnswers();
      },
      getOptionPoints(question) {
        if (!question.studentAnswerId) return 0;
        const option = this.pollActivity.answers.find(a => a.id === question.studentAnswerId);
        return option?.points || 0;
      },
      processAnswers() {
        this.sections = this.sections.map(section => ({
          ...section,
          questions: section.questions.map(question => {
            const studentAnswer = this.reply.answer[question.id.toString()];
            let answerText = '';
            let answerId = '';
            let options = [];

            // Obtener opciones disponibles
            if (question.type === 2) { // Pregunta Sí/No
              options = this.pollActivity.answers.filter(a => 
                a.section === question.section && 
                (a.description === 'Sí' || a.description === 'No')
              );
            } else if (question.type === 1) { // Opción múltiple normal
              options = this.pollActivity.answers.filter(a => 
                a.section === question.section &&
                a.description !== 'Sí' && 
                a.description !== 'No'
              );
            }

            // Para preguntas de desarrollo
            if (question.type === 3) {
              answerText = studentAnswer?.answerText || 'Sin respuesta';
            } else {
              answerId = studentAnswer?.answerId;
            }

            return {
              ...question,
              studentAnswerId: answerId,
              studentAnswerText: answerText,
              options: options
            };
          })
        }));
      },
            
      answerClass(questionId) {
        const question = this.findQuestion(questionId);
        return question.isCorrect ? 'correct-answer' : 'incorrect-answer';
      },
  
      selectedAnswerText(questionId) {
        const question = this.findQuestion(questionId);
        return question.alternatives.find(a => a.id === question.studentAnswer)?.description || 'Sin respuesta';
      },
  
      answerIcon(questionId) {
        return this.answerClass(questionId) === 'correct-answer' 
          ? 'mdi-check-circle' 
          : 'mdi-alert-circle';
      },
  
      answerIconColor(questionId) {
        return this.answerClass(questionId) === 'correct-answer' 
          ? 'success' 
          : 'error';
      },
      findAnswer(questionId) {
        return Object.values(this.reply.answer || {}).find(a => a.questionId === questionId);
      },
  
      findQuestion(questionId) {
        for (const section of this.sections) {
          const question = section.questions.find(q => q.id === questionId);
          if (question) return question;
        }
        return null;
      },
  
      closeModal() {
        console.log(this.reply)
        console.log(this.pollActivity)
        this.showModal = false;
        this.$emit('closed');
      }
    }
  };
  </script>
  
  <style>
  .correct-answer {
    border-left: 4px solid #4CAF50;
    background-color: #E8F5E9;
  }
  
  .incorrect-answer {
    border-left: 4px solid #F44336;
    background-color: #FFEBEE;
  }
  
  .student-answer, .correct-answer {
    padding: 12px;
    border-radius: 4px;
    margin: 8px 0;
  }
  </style>