<template>
    <div v-if="visible" class="overlay">
      <v-card >
        <v-card-title class="primary justify-space-between">
          <div>Imagen</div>
          <v-btn class="mt-2 ml-3" color="red" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
            <div class="image-container">
               <img :src="imageUrl" class="contained-image"/>
            </div>
      </v-card>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: true
      },
      imageUrl: {
        type: String,
        required: true
      }
    },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  };
  </script>
  
