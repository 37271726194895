<template>
    <div>
      <div class="rounded-lg text-center">
        <div class="rounded-lg" style="background-color: rgba(63, 81, 181, 0.8); color: white;">
          <h1 class="ml-4 pt-2">Diagnóstico de Hábitos Lectores</h1>
        </div>
      </div>
  
      <v-card flat class="mt-4 pa-4 scrollable-content--large">
        <template v-for="(question, index) in questions">
          <v-card 
            :key="'question-'+question.id"
            class="mb-6 question-section"
          >
            <v-card-title class="white--text book-paragraph primary">
              <v-icon left color="white">mdi-comment-question</v-icon>
              {{ `Pregunta ${index + 1}: ${question.text}` }}
            </v-card-title>
  
            <v-card-text>
              <!-- Single Choice -->
              <div v-if="question.type === 'single_choice'" class="options-container">
                <v-radio-group v-model="studentReply[question.id].answerId" @change="saveToLocalStorage">
                  <v-radio
                    v-for="option in question.options"
                    :key="`option-${question.id}-${option.id}`"
                    :value="option.id"
                    color="success"
                  >
                    <template v-slot:label>
                      <span class="option-label">{{ option.text }}</span>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
  
              <!-- Multiple Choice -->
              <div v-if="question.type === 'multiple_choice'" class="options-container">
                <medium class="caption">
                    Seleccione {{ question.max_selections }} opciones
                </medium>
                <medium class="caption">
                    {{ selectionCounter(question.id) }} de {{ question.max_selections }} seleccionadas
                </medium>
                <v-checkbox
                    v-for="option in question.options"
                    :key="`moption-${question.id}-${option.id}`"
                    :label="option.text"
                    :value="option.id"
                    v-model="studentReply[question.id].answerId"
                    :disabled="isOptionDisabled(question.id, option.id)"
                    @change="saveToLocalStorage"
                    color="success"
                    class="custom-checkbox"
                    >
                    <template v-slot:label>
                        <span :class="{
                        'selected-option': isChecked(question.id, option.id),
                        'disabled-option': isOptionDisabled(question.id, option.id)
                        }">
                        {{ option.text }}
                        </span>
                    </template>
                </v-checkbox>
                </div>
  
              <!-- Matrix -->
              <div v-if="question.type === 'matrix'" class="matrix-container">
                <v-table class="matrix-table">
                <thead>
                    <tr class="matrix-header">
                    <th class="subquestion-header"></th>
                    <th 
                        v-for="option in question.options" 
                        :key="`header-${question.id}-${option.id}`"
                        class="option-header"
                    >
                        {{ option.text }}
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                    v-for="sub in question.sub_questions" 
                    :key="`row-${question.id}-${sub.id}`"
                    class="matrix-row"
                    >
                    <td class="subquestion-cell">{{ sub.text }}</td>
                    <td 
                        v-for="option in question.options" 
                        :key="`cell-${question.id}-${sub.id}-${option.id}`"
                        class="option-cell"
                    >
                        <v-radio-group 
                        v-model="studentReply[question.id].answerId[sub.id]"
                        @change="handleMatrixChange(question.id, sub.id, $event)"
                        class="radio-group"
                        >
                        <v-radio 
                            :value="option.id" 
                            color="success"
                            class="matrix-radio"
                        >
                            <template v-slot:label>
                            <span :class="{
                                'selected-option': studentReply[question.id].answerId[sub.id] === option.id,
                            }">
                                {{ option.text }}
                            </span>
                            </template>
                        </v-radio>
                        </v-radio-group>
                    </td>
                    </tr>
                </tbody>
                </v-table>
            </div>
              <!-- Open Question -->
              <v-textarea
                v-if="question.type === 'open'"
                v-model="studentReply[question.id].answerText"
                @input="handleAnswerChange(question.id, 'open', $event)"
              ></v-textarea>
            </v-card-text>
          </v-card>
        </template>
  
        <v-card-actions class="d-flex justify-center sticky-footer">
          <v-btn
            @click="submit"
            x-large
            color="deep-purple accent-4"
            class="white--text"
          >
            Terminar Diagnóstico
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </template>
  
  <script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      questions: [],
      studentReply: {},
      loading: true,
      startTime: null,
      timeSpent: 0,
      showError: {},
    };
  },
  computed: {
    hasUnansweredQuestions() {
    return this.questions.some(question => {
        const reply = this.studentReply[question.id];
        
        if (question.type === 'open') {
        return !reply?.answerText?.trim();
        }
        if (question.type === 'multiple_choice') {
        return !reply?.answerId?.length;
        }
        if (question.type === 'matrix') {
        return Object.values(reply?.answerId || {}).some(value => !value);
        }
        return reply?.answerId === null || reply?.answerId === undefined;
    });
    },
  },
  methods: {
    isChecked(questionId, optionId) {
      return this.studentReply[questionId].answerId.includes(optionId);
    },
    handleMatrixChange(questionId, subId, value) {
      this.$set(this.studentReply[questionId].answerId, subId, value);
      this.saveToLocalStorage();
    },

    updateMultipleSelection(question, optionId, isChecked) {
      const currentAnswers = [...this.studentReply[question.id].answerId];
      
      if (isChecked) {
        if (currentAnswers.length < question.max_selections) {
          currentAnswers.push(optionId);
        }
      } else {
        const index = currentAnswers.indexOf(optionId);
        if (index > -1) {
          currentAnswers.splice(index, 1);
        }
      }
      
      this.$set(this.studentReply[question.id], 'answerId', currentAnswers);
      this.saveToLocalStorage();
    },

    isOptionDisabled(questionId, optionId) {
      const currentAnswers = this.studentReply[questionId].answerId;
      const question = this.questions.find(q => q.id === questionId);
      return currentAnswers.length >= question.max_selections && 
            !currentAnswers.includes(optionId);
    },

    selectionCounter(questionId) {
      return this.studentReply[questionId].answerId.length;
    },

    initializeAnswers() {
        this.questions.forEach(question => {
            if (!this.studentReply[question.id]) {
            const baseStructure = { questionId: question.id };
            
            if (question.type === 'multiple_choice') {
                baseStructure.answerId = [];
            } 
            else if (question.type === 'matrix') {
                baseStructure.answerId = {};
                // Inicializar cada subpregunta
                question.sub_questions.forEach(sub => {
                baseStructure.answerId[sub.id] = null;
                });
            } 
            else if (question.type === 'open') {
                baseStructure.answerText = '';
            } 
            else {
                baseStructure.answerId = null;
            }
            
            this.$set(this.studentReply, question.id, baseStructure);
            }
        });
    },

    handleAnswerChange(questionId, type, value) {
      const reply = this.studentReply[questionId] || { questionId: questionId };
      
      if (type === 'multiple_choice') {
        reply.answerId = value;
      }
      else if (type === 'matrix') {
        reply.answerId = { ...reply.answerId, ...value };
      }
      else if (type === 'open') {
        reply.answerText = value;
      }
      else {
        reply.answerId = value;
      }
      
      this.$set(this.studentReply, questionId, reply);
      this.saveToLocalStorage();
    },

    saveToLocalStorage() {
      const data = {
        studentReply: this.studentReply,
        startTime: this.startTime,
      };
      localStorage.setItem(`habitosLectores${this.$route.params.id}`, JSON.stringify(data));
    },

    loadFromLocalStorage() {
      const data = localStorage.getItem(`habitosLectores${this.$route.params.id}`);
      if (data) {
        const parsed = JSON.parse(data);

        Object.keys(parsed.studentReply).forEach(qId => {
          if (this.studentReply[qId]) {
            this.$set(this.studentReply, qId, {
              ...this.studentReply[qId],
              ...parsed.studentReply[qId]
            });
          }
        });
        
        this.startTime = parsed.startTime || Date.now();
      }
    },
    validateForm() {
    this.showError = {};
    let hasError = false;
    
    this.questions.forEach(question => {
      const reply = this.studentReply[question.id];
      let isValid = true;

      if (question.type === 'open') {
        isValid = reply?.answerText?.trim() !== '';
      } 
      else if (question.type === 'multiple_choice') {
        isValid = reply?.answerId?.length >= 1 && 
                 reply.answerId.length <= question.max_selections;
      } 
      else if (question.type === 'matrix') {
        isValid = reply?.answerId && 
                 Object.values(reply.answerId).every(val => val !== null && val !== undefined);
      }
      else {
        isValid = !!reply?.answerId;
      }

      if (!isValid) {
        this.$set(this.showError, question.id, true);
        hasError = true;
      }
    });

    return !hasError;
  },

    async submit() {
        if (!this.validateForm() || this.hasUnansweredQuestions) {
            await Swal.fire({
                title: "¡Faltan respuestas!",
                html: `Tienes ${this.questions.filter(q => this.showError[q.id]).length} preguntas sin responder`,
                icon: "warning",
                confirmButtonText: "Entendido",
                footer: 'Por favor completa todas las preguntas requeridas'
            });
            
            // Scroll a la primera pregunta con error
            const firstError = document.querySelector('.v-alert[type="error"]');
            if (firstError) {
                firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          return;
        }


      this.timeSpent = Math.round((Date.now() - this.startTime) / 1000);

      try {
        await Swal.fire({
          title: "¿Desea terminar la evaluación?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí",
          cancelButtonText: "Volver",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios.post("student/poll_reply/" + this.$route.params.id  , {
              answer: JSON.stringify(this.studentReply),
              points: 0, // Encuesta
              time: this.timeSpent
            });
            
            Swal.fire("Completada!", "Las respuestas han sido contestadas", "success");
            localStorage.removeItem(`habitosLectores${this.$route.params.id}`);
            this.$router.push('/estudiante/asignaturas');
          }
        });
      } catch (error) {
        console.error('Error al enviar respuestas:', error);
        Swal.fire("Error", "No se pudieron guardar las respuestas", "error");
      }
    }
  },
  async created() {
    try {
      const response = await axios.get('/questionHabitos');
      this.questions = response.data.questionsBySection[0];
      this.initializeAnswers();
      this.loadFromLocalStorage();

    } catch (error) {
      console.error('Error cargando preguntas:', error);
      Swal.fire('Error', 'No se pudieron cargar las preguntas', 'error');
    }

    
   
  }
};
</script>