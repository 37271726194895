<template>
  <div class="mb-4 pa-4">
    <div class="rounded-lg text-center " >
      <div class="rounded-lg" >
        <h1 class="ml-4 pt-2 ">{{ poll.title }}</h1>
      </div>
      <h3 v-if="poll.description" class="  mx-4 py-2">Descripción: {{ poll.description }}</h3>
    </div>
  
    <v-tabs v-model="currentTab" grow color="deep-purple">
      <v-tab v-for="(section, index) in sections" :key="index">
        <v-icon left>mdi-book-open</v-icon>
          {{ section.title }} 
      </v-tab>
    </v-tabs>

    <v-tabs-items touchless v-model="currentTab">
        <v-tab-item v-for="(section, index) in sections" :key="index">
          <div class="pa-4">
            <!-- Texto de la sección -->
            <v-card class="mt-4 pa-2">

              <div v-for="(question, index) in section.questions" :key="index" class="mb-4">
                <v-card>
                  <v-card-title class="white--text book-paragraph primary"> 
                    <p><v-icon left color="white">mdi-comment-question</v-icon> {{ `Pregunta ${index + 1}: ${question.description}` }}</p>
                  </v-card-title>
                  
                    <v-container v-if="question.type === 2" class="px-4">
                    <v-radio-group row :value="question.answer" readonly>
                        <v-radio v-for="option in getSectionAnswersYESNO(section.title,question)" 
                                  :key="option.id"
                                  :value="option.id"
                                  color="success"
                                  disabled>
                          <template v-slot:label>
                              <span>
                                <div class="px-2">{{ `${option.description} (${option.points} pts)` }}</div>
                              </span>
                          </template>
                        </v-radio>
                    </v-radio-group>
                    </v-container>
                    <v-container v-else-if="question.type === 1" class="px-4">
                      <v-radio-group row :value="question.answer" readonly>
                          <v-radio v-for="option in getSectionAnswers(section.title)" 
                                    :key="option.id"
                                    :value="option.id"
                                    color="success"
                                    disabled>
                            <template v-slot:label>
                                <span>
                                  <div class="px-2">{{ `${option.description} (${option.points} pts)` }}</div>
                                </span>
                            </template>
                          </v-radio>
                      </v-radio-group>
                    </v-container>
                    <v-container v-else-if="question.type === 3" class="px-4">
                      <v-textarea label="Respuesta de Desarrollo" auto-grow disabled></v-textarea>
                    </v-container>
                </v-card>
              </div>
            </v-card>
            </div> 
        </v-tab-item>
      </v-tabs-items>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pollId: null,
      poll: { title: '', description: '', questions: [] },
      currentTab: 0,
      sections: []
    }
  },
  methods: {
    async getPoll() {
      const response = await axios.get('teacher/poll/' + this.pollId);
      const pollData = response.data.poll;
      const sectionsMap = {};

      // Organizar preguntas y respuestas por sección
      pollData.question.forEach(question => {
        const section = question.section || 'Sin Sección';
        if (!sectionsMap[section]) {
          sectionsMap[section] = {
            title: section,
            questions: [],
            answers: []
          };
        }
        sectionsMap[section].questions.push(question);
      });

      pollData.answer.forEach(answer => {
        const section = answer.section || 'Sin Sección';
        if (sectionsMap[section]) {
          sectionsMap[section].answers.push(answer);
        }
      });

      this.poll = {
        title: pollData.title,
        description: pollData.description,
      };
      this.sections = Object.values(sectionsMap);
    },
    getSectionAnswers(sectionTitle) {
      return this.sections.find(section => section.title === sectionTitle)?.answers.filter(answer => answer.description !== 'Sí' && answer.description !== 'No') || [];
    },
    getSectionAnswersYESNO(sectionTitle, question) {
      const answers = this.sections.find(section => section.title === sectionTitle)?.answers.filter(answer => answer.description === 'Sí' || answer.description === 'No') || [];
      if (answers.length === 0) {
        return [
          {  description: 'Sí', id: `yes_${question.id}`, points: '0' },
          {  description: 'No', id: `no_${question.id}`, points: '0' }
        ];
      }
      return answers;
    },
  },
  async created() {
    this.pollId = this.$route.params.id;
    await this.getPoll();
  }
}
</script>

