<template>
  <v-app style="background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);">
    <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
      <div>
        <v-tabs v-model="tab" show-arrows background-color="purple darken-4" icons-and-text dark grow>
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab v-for="i in tabs" :key="i.id">
            <v-icon large>{{ i.icon }}</v-icon>
            <div class="caption py-1">{{ i.name }}</div>
          </v-tab>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="loginForm" v-model="valid" lazy-validation @submit.prevent="submit">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="formLogin.email" :rules="loginEmailRules" label="E-mail" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="formLogin.password" :append-icon="show1?'mdi-eye':'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Contraseña" hint="Mínimo 8 caracteres" counter @click:append="show1 = !show1"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-btn :loading="loadingReset" color="blue darken-1" @click="resetPassword" text>¿Olvidó su contraseña?</v-btn>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" xsm="12">
                      <v-checkbox
                          v-model="checkbox"
                        label="Mantener Sesión Iniciada"
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                      <v-btn :loading="loading" x-large block color="deep-purple lighten-1" class="white--text" type="submit"> Ingresar </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="registerForm" v-model="valid" lazy-validation @submit.prevent="register">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="formCreate.name" :rules="[rules.required]" label="Nombre" maxlength="20" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="formCreate.lastName" :rules="[rules.required]" label="Apellido Paterno" maxlength="20" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="formCreate.secondLastName" :rules="[rules.required]" label="Apellido Materno" maxlength="20" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="formCreate.email" :rules="emailRules" label="E-mail" required></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field v-model="formCreate.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Contraseña" hint="Minimo 8 caracteres" counter @click:append="show1 = !show1"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="formCreate.passwordCheck" block :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, passwordMatchCreate]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Confirmar Contraseña" counter @click:append="show1 = !show1"></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                      <v-btn v-if="formValidCreate" :loading="loading" x-large block :disabled="!valid" color="deep-purple lighten-1" class="white--text" @click="register">Crear</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-dialog>
    <div class="text-center ma-2">
      <v-snackbar
          :color="color"
          v-model="snackbar"
      >
        {{ text }}.

        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Contraseñas deben coincidir";
    },
    passwordMatchCreate() {
      return () => this.formCreate.password === this.formCreate.passwordCheck || "Contraseñas deben coincidir";
    },
    formValidCreate() {
      if(this.formCreate.name === '' || this.formCreate.lastName === '' ||
          this.formCreate.secondLastName === '' || this.formCreate.email === '' || this.formCreate.password === '' ||
          this.formCreate.password !== this.formCreate.passwordCheck
      ) {
        return false;
      }
      return true;

    }
  },
  methods: {
    async resetPassword() {
      this.loadingReset = true;
      let email = this.formLogin.email;
      await axios.post('recover', { email: email }, {
          headers: {
              'Content-Type': 'application/json'
          }
      })
      .then(() => {
        this.loadingReset = false;
        this.snackbar = true;
        this.color = 'green';
        this.text = `Revise su correo: ${ email }`;
      })
      .catch((error) => {
        console.log(error)
        this.loadingReset = false;
        this.snackbar = true;
        this.color = 'red';
        this.text = 'Ingrese un Mail válido';

      })
    },
    async submit() {
      this.loading = true;
      await this.$store.dispatch('login', { login: this.formLogin, check: this.checkbox })
          .then(() => {
            if ( this.$store.getters.getUserInfo.rol === 1) this.$router.push('/profe/asignaturas');
            else this.$router.push('/estudiante');
          })
          .catch( error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Usuario o Contraseña incorrectos!',
            })
            console.log(error)
          });
      this.loading = false;
    },
    async register() {
      this.loading = true;
      if (
        this.formCreate.name === "" ||
        this.formCreate.lastName === "" ||
        this.formCreate.secondLastName === "" ||
        this.formCreate.email === null ||
        this.formCreate.password.length < 8
      ) {
        return;
      }

      console.log(this.formCreate)

      await axios.post("createStudent", this.formCreate, {
          headers: {
            // Elimina Authorization para este endpoint
            "Content-Type": "application/json"
          }
        }).then(() => {
          this.loading = false;
          this.formLogin = this.formCreate;
          this.checkbox = true;
          this.submit();
          return Swal.fire("", "Cuenta creado con éxito!", "success");
        })
        .catch((error) => {
          this.loading = false;
          let errorMessage = "Error al crear la cuenta";
          console.log(error)
          // Manejar diferentes tipos de errores
          if (error.response) {
            // Error desde el backend
            errorMessage = error.response.data.message || errorMessage;
            
            // Manejar errores de validación de Laravel
            if (error.response.status === 422 && error.response.data.errors) {
              const firstError = Object.values(error.response.data.errors)[0][0];
              errorMessage = firstError;
            }
          }

          Swal.fire({
            icon: "error",
            title: "Error",
            text: errorMessage,
          });
        });


    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  },
  data: () => ({
    loading: null,
    items: ['Profesor', 'Estudiante'],
    dialog: true,
    tab: 0,
    tabs: [
      {id: 1, name:"Iniciar Sesión", icon:"mdi-account"},
      {id: 2, name:"Crear Cuenta", icon:"mdi-account-outline"}
    ],
    loadingReset: null,
    valid: true,
    checkbox: true,
    snackbar: false,
    color: '',
    text: '',

    formLogin : {
      email: '',
      password: ''
    },

    formCreate: {
      name: '',
      lastName: '',
      secondLastName: '',
      email: '',
      password: '',
      passwordCheck: '',
      rol: 0,
    },

    loginPassword: "",
    loginEmail: "",
    loginEmailRules: [
      v => !!v || "Campo Requerido",
      v => /.+@.+\..+/.test(v) || "E-mail debe ser válido"
    ],
    emailRules: [
      v => !!v || "Campo Requerido",
      v => /.+@.+\..+/.test(v) || "E-mail debe ser válido"
    ],

    show1: false,
    rules: {
      required: value => !!value || "Campo Requerido",
      min: v => (v && v.length >= 8) || "Min 8 caracteres"
    }
  }),
}
</script>

