<template>
  <v-container class="pa-8" fluid>
    <v-row justify="center">
      <v-col justify="center">
        <v-card >
          <v-card-title class="primary white--text d-flex justify-space-between">Crear Diagnóstico
            <info-button class="mr-3" title="Información sobre la creación de un diagnóstico" message="
            <ul>
              <li><strong>Título:</strong> Ingrese el título del diagnóstico.</li>
              <li><strong>Descripción:</strong> Ingrese una descripción del diagnóstico.</li>
              <li><strong>Agregar Secciones:</strong> Para agregar secciones, haga clic en el botón 'Agregar Sección'.</li>
              <li><strong>Agregar Preguntas a una Sección:</strong> Dentro de cada sección, puede agregar preguntas de tres tipos:</li>
              <li><strong>1.- Respuestas asociadas globales:</strong> Preguntas de opción múltiple con respuestas predefinidas y globales para todas las preguntas de este tipo.</li>
              <li><strong>2.- Sí o No:</strong> Preguntas con una respuesta binaria, donde se agregara un puntaje asociado, que valdra para todas las preguntas de este tipo en la sección</li>
              <li><strong>3.- Desarrollo:</strong> Preguntas abiertas donde los estudiantes pueden escribir una respuesta en texto libre.</li>
              <li><strong>Orden de Preguntas:</strong> Puede mover las preguntas hacia arriba o hacia abajo usando las flechas junto a cada pregunta para ajustar su orden.</li>
              <li><strong>Alternativas Globales:</strong> Para agregar alternativas globales a una sección, haga clic en 'Agregar Alternativa' y complete la descripción y los puntos correspondientes.</li>
              <li><strong>Sección:</strong> Para editar o eliminar una sección, haga clic en los iconos junto al título de la sección.</li>
              <li><strong>Cancelar Creación:</strong> Para cancelar la creación del diagnóstico, haga clic en 'Cancelar'. Se le pedirá confirmación antes de cancelar los cambios.</li>
            </ul>
            "></info-button>
          </v-card-title>
          <v-form @submit.prevent="submit" ref="form" lazy-validation>
            <v-card-text>
              <v-text-field v-model="form.title" prepend-icon="mdi-format-title" label="Título Diagnóstico" required></v-text-field>
              <v-textarea prepend-icon="mdi-content-copy" v-model="form.description" auto-grow filled color="deep-purple" label="Descripción" rows="2"></v-textarea>
              
              <v-divider></v-divider>
              
                <div  style="color: white;" class=" rounded-lg primary d-flex justify-space-between align-center">
                  <v-card-title>Secciones</v-card-title>
                  <v-btn   style="color: white;" class="rounded-lg mx-3 " color="deep-purple" @click="openSectionModal">Agregar Sección</v-btn>
                </div>
                <v-tabs v-model="currentTab" grow color="deep-purple">
                  <v-tab v-for="(section, index) in sections" :key="`tab-${index}`">
                    <v-icon left>mdi-book-open</v-icon>
                    {{ section.title }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items touchless v-model="currentTab">
                  <v-tab-item v-for="(section, index) in sections" :key="`tabitem-${index}`">
                    <v-card class="my-3 pa-2" outlined>
                      <div class="d-flex justify-center align-center">
                        <v-btn class="pa-1" icon color="primary" @click="editSection(section, index)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn class="pa-1" icon color="red" @click="confirmDeleteSection(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                      <v-divider></v-divider>
                      
                      <!-- Contenido de la sección actual -->
                      <div class="my-2 pa-3" v-for="(question, qIndex) in section.questions" 
                          :key="`question-${index}-${qIndex}`">
                          <v-card-title class="white--text book-paragraph primary"> 
                            <p><v-icon left color="white">mdi-comment-question</v-icon> {{ `Pregunta ${index + 1}: ${question.description}` }}</p>
                          </v-card-title>
                      

                        <v-container v-if="question.type === 2" class="px-4">
                          <v-radio-group row :value="question.answer" readonly>
                              <v-radio v-for="option in getSectionAnswersYESNO(section.title,question)" 
                                        :key="option.id"
                                        :value="option.id"
                                        color="success"
                                        disabled>
                                <template v-slot:label>
                                    <span>
                                      <div class="px-2">{{ `${option.description} (${option.points} pts)` }}</div>
                                    </span>
                                </template>
                              </v-radio>
                          </v-radio-group>
                        </v-container>
                        <v-container v-else-if="question.type === 1" class="px-4">
                          <v-radio-group row :value="question.answer" readonly>
                              <v-radio v-for="option in getSectionAnswers(section.title)" 
                                        :key="option.id"
                                        :value="option.id"
                                        color="success"
                                        disabled>
                                <template v-slot:label>
                                    <span>
                                      <div class="px-2">{{ `${option.description} (${option.points} pts)` }}</div>
                                    </span>
                                </template>
                              </v-radio>
                          </v-radio-group>
                        </v-container>
                        <v-container v-else-if="question.type === 3">
                          <v-textarea label="Respuesta de Desarrollo" auto-grow disabled></v-textarea>
                        </v-container>
                      </div>
                    </v-card>
                </v-tab-item>
              </v-tabs-items>         
            </v-card-text>

            <v-card-actions class="mx-3">
              <v-btn color="error" @click="confirmCancel">Cancelar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit">Crear Diagnóstico</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center ma-2">
      <v-snackbar :color="color" v-model="snackbar">
        {{ text }}.
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
      </v-snackbar>
    </div>
    <add-section-poll
      :dialog="sectionModal"
      :section="newSection"
      @save="saveSection"
      @close="closeSectionModal"
      @generateSectionNumber="generateSectionNumber"
    ></add-section-poll>
  </v-container>
</template>

<script>
import axios from 'axios';
import InfoButton from '@/components/UI/InfoButton.vue';
import AddSectionPoll from '../AddSectionPoll.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    InfoButton,
    AddSectionPoll
  },
  data() {
    return {
      snackbar: false,
      color: "",
      text: "",
      currentTab: 0,
      dialog: false,
      sectionModal: false,
      sections: [],
      newSection: {
        title: '',
        questions: [],
        answers: [],
        yesPoints: 0,
        noPoints: 0,
      },
      form: {
        title: "",
        description: "",
        questions: [],
        answers: [],
      },
      editSectionIndex: null,
      questionTypes: [
        { value: 1, text: 'Respuestas asociadas globales' },
        { value: 2, text: 'Sí o No' },
        { value: 3, text: 'Desarrollo' }
      ]
    };
  },
  methods: {
    setSnackBar(color, text, snack) {
      this.color = color;
      this.text = text;
      this.snackbar = snack;
    },
    getSectionAnswers(sectionTitle) {
      return this.sections.find(section => section.title === sectionTitle)?.answers.filter(answer => answer.description !== 'Sí' && answer.description !== 'No') || [];
    },
    getSectionAnswersYESNO(sectionTitle, question) {
      const answers = this.sections.find(section => section.title === sectionTitle)?.answers.filter(answer => answer.description === 'Sí' || answer.description === 'No') || [];
      if (answers.length === 0) {
        return [
          {  description: 'Sí', id: `yes_${question.id}`, points: '0' },
          {  description: 'No', id: `no_${question.id}`, points: '0' }
        ];
      }
      return answers;
    },
    async resetForm() {
      this.form = {
        title: "",
        description: "",
        questions: [],
        answers: [],
      };
      this.sections = [];
    },
    generateSectionNumber() {
      return this.sections.length + 1;
    },
    openSectionModal() {
      this.newSection = {
        title: '',
        questions: [],
        answers: [],
      };
      this.sectionModal = true;
    },
    closeSectionModal() {
      this.sectionModal = false;
    },
    saveSection(section) {
      if (this.editSectionIndex !== null) {
        this.sections.splice(this.editSectionIndex, 1, section);
        this.editSectionIndex = null;
      } else {
        if (!section.title) {
          section.title = `Sección ${this.sections.length + 1}`;
        }
        this.sections.push(section);
      }
      this.newSection = {
        title: '',
        questions: [],
        answers: [],
      };
      this.closeSectionModal();
    },
    editSection(section, index) {
      this.newSection = JSON.parse(JSON.stringify(section));
      this.editSectionIndex = index;
      this.sectionModal = true;
    },
    confirmDeleteSection(index) {
      Swal.fire({
        title: '¿Estás seguro de eliminar la sección?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminarla',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.sections.splice(index, 1);
          Swal.fire(
            'Eliminada',
            'La sección ha sido eliminada.',
            'success'
          )
        }
      })
    },
    confirmCancel() {
      Swal.fire({
        title: '¿Estás seguro de cancelar la creación del diagnóstico?',
        text: "¡No se guardarán los cambios!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, cancelar',
        cancelButtonText: 'Volver'
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetForm();
        }
      })
    },
    async submit() {
      if (!this.form.title || this.sections.length === 0) {
        this.setSnackBar("red", "Faltan Datos", true);
        return;
      }

      this.sections.forEach(section => {
        section.questions.forEach(question => {
          this.form.questions.push({ description: question.description, order: question.order, type: question.type, section: section.title });
        });
        section.answers.forEach(answer => {
          this.form.answers.push({ description: answer.description, points: answer.points, section: section.title });
        });
        if (section.noPoints!= null && section.yesPoints &&section.noPoints != 0 && section.yesPoints != 0 ) {
            this.form.answers.push({ description: "Sí", points: section.yesPoints, section:  section.title });
            this.form.answers.push({ description: "No", points: section.noPoints, section:  section.title });
        }
      });
      console.log(this.form);
      try {
        await axios.post('teacher/poll/create', this.form);
        this.setSnackBar("green", "Diagnóstico creado con éxito", true);
        await this.$store.dispatch('teacher/poll');
        this.resetForm();
      } catch (e) {
        console.error(e);
        this.form = {
          ...this.form,
          questions: [],
          answers: [],
        };
        this.setSnackBar("red", "Error al crear el diagnóstico", true);
      }
    },
  },
};
</script>

