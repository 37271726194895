<template>
    <!-- Modal para compartir texto -->
    <v-dialog v-model="showShareModal" max-width="80%" persistent>
      <v-card>
        <v-card-title class="primary white--text d-flex justify-space-between">
          Compartir Texto
          <div class="d-flex align-center">
            <info-button 
            class="mr-4" 
            title="Información sobre compartir textos" 
            message="
              <ul>
                <li><strong>Selección de Profesores:</strong> Solo profesores registrados en el sistema. Use el buscador para filtrar.</li>
                <li><strong>Compartir:</strong> Seleccione uno o más profesores y haga clic en 'Compartir'.</li>
                <li><strong>Efecto:</strong> Los profesores recibirán una copia completa del texto con todas sus preguntas y respuestas.</li>
                <li><strong>Derechos:</strong> Cada profesor podrá modificar su propia copia sin afectar el texto original.</li>
              </ul>
            "
          ></info-button>
          <v-btn  class="ml-5" color="red" @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar Profesores"
            single-line
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-data-table
          height="400"
          fixed-header
          :search="search"
          v-model="selectedTeachers"
          :headers="headers"
          :items="teachers"
          item-key="id"
          show-select
          class="elevation-1"
        >
          <template v-slot:no-data>
            <p>No hay profesores disponibles para compartir</p>
          </template>
        </v-data-table>
        <v-card-actions class="flex mt-1 justify-space-between">
          <v-btn @click="closeModal" color="error">Cancelar</v-btn>
          <v-btn 
            color="primary" 
            @click="confirmShareText"
            :disabled="selectedTeachers.length === 0"
          >
            Compartir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from "axios";
  import Swal from "sweetalert2";
  import InfoButton from "@/components/UI/InfoButton.vue";
  
  export default {
    components: { InfoButton },
    props: {
      showShareModal: Boolean,
      textId: Number
    },
    data() {
      return {
        search: '',
        teachers: [],
        selectedTeachers: [],
        headers: [
          { text: 'Nombre', align: 'start', value: 'name' },
          { text: 'Apellido Paterno', value: 'lastName' },
          { text: 'Apellido Materno', value: 'secondLastName' },
          { text: 'Email', value: 'email' }
        ]
      };
    },
    methods: {
      async getTeachers() {
        try {
          const response = await axios.get(`teacher/text/teachers/${this.textId}`);
          this.teachers = response.data.teachers;
        } catch (error) {
          Swal.fire('Error', 'No se pudieron cargar los profesores', 'error');
        }
      },
  
      closeModal() {
        this.$emit('update:show-share-modal', false);
        this.selectedTeachers = [];
      },
  
      async confirmShareText() {
        const result = await Swal.fire({
          title: '¿Confirmar compartir texto?',
          html: `Se creará una copia completa para ${this.selectedTeachers.length} profesor(es)`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar'
        });
  
        if (result.isConfirmed) {
          await this.shareText();
        }
      },
  
      async shareText() {
        const teacherIds = this.selectedTeachers.map(t => t.id);
        
        try {
            await axios.post(`teacher/text/share/${this.textId}`, {
                target_teacher_id: teacherIds
            });
          
          Swal.fire('¡Éxito!', 'Texto compartido correctamente', 'success');
          this.closeModal();
        } catch (error) {
          const message = error.response?.data?.error || 'Error al compartir el texto';
          Swal.fire('Error', message, 'error');
        }
      }
    },
    watch: {
      showShareModal(val) {
        if (val) {
          this.getTeachers();
        }
      }
    }
  };
  </script>
