import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#3f51b5", // Debe coincidir con tu variable
        secondary: "#4caf50",
        error: "#ff5252",
      },
    },
  },
});
