<template>
  <div class="mt-6">
    <v-row  class="align-stretch "  v-if="!loading && polls.length > 0">
      <v-col class="d-flex justify-center card-grid-container" v-for="(poll, i) in polls" :key="i" cols="12" sm="6" md="4" lg="3">
        <card-component
            :title="poll.poll_name"
            :description="`Responder antes del: ${poll.limit}\n${poll.description}`" 
            image="activity"
        >
          <template  v-slot:activities>
            <v-btn class="responsive-button mb-3"  color="primary" @click="confirmAndGoToPoll(poll)">Realizar</v-btn>
          </template>
          <template v-slot:polls>
            <v-chip class="responsive-button" color="error" >
              Pendiente
            </v-chip>
          </template>
        </card-component>
      </v-col>
    </v-row>

    <loading-component v-if="loading"/>

    <v-row v-if="!loading && polls.length===0" style="height: 60vh" align="center">
      <v-col>
        <v-img height="60%" width="60%" :src="require('@/assets/images/undraw_empty_street_sfxm.svg')"></v-img>
        <p class="mt-5">Aún no tiene diagnósticos asignadas</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardComponent from "@/components/UI/CardComponent";
import LoadingComponent from "@/components/UI/LoadingComponent";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  props: ['loading'],
  components: { LoadingComponent, CardComponent },
  data() {
    return {};
  },
  computed: {
    polls() {
      const now = new Date();
      return this.$store.getters['student/getStudentPollsNoReply'].filter(poll => {
        // Convertir fechas a objetos Date para comparación
        const limitDate = new Date(poll.limit);
        return limitDate > now; // Solo incluir si la fecha límite es futura
      });
    },
  },
  methods: {
    confirmAndGoToPoll(poll) {
      let title = '';
      let text = '';

      switch (poll.poll_id) {
        case 12:
        case 13:
          title = '¿Deseas comenzar este diagnóstico "Lectum", prueba de comprensión lectora?';
          text = "La prueba consiste en 5 textos. Tendrás 90 minutos para responder.";
          break;
        case 14:
          title = '¿Deseas comenzar este diagnostico "Interamericana"?';
          text = "En esta prueba puede ser que no tenga tiempo para contestar todas las preguntas pero debe tratar de contestar tantas como pueda. Antes de cada parte de la prueba tendrá ejemplos que demuestren lo que ha de hacerse." +
                  "El examinador le dirá cuando debe empezar y cuando debe dejar de trabajar en cada parte. Puede trabajar en cada parte solamente durante el tiempo señalado para esta."
          break;
        default:
          title = '¿Deseas comenzar este diagnóstico?';
          text = "Una vez que comiences, deberás completarla.";
      }

      Swal.fire({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, comenzar!',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.goToPoll(poll.id);
        }
      });
    },
    
    async isLectum(id) {
      try {
        const response = await axios.get(`student/poll_activity/isLectum/${id}`); 
        if (response.data.ok) {
          return response.data.poll; // Devuelve el poll si se encontró
        } else {
          throw new Error(response.data.error);
        }
      } catch (error) {
        console.error('Error al obtener el poll:', error);
        return null; // Manejo de errores, devuelve null si hay algún problema
      }
    },
    async goToPoll(id) {
      const poll = await this.isLectum(id);
      console.log(poll)
      if (poll && poll.id === 13) {  // Verifica que el poll existe y es el que buscas
        this.$router.push('/estudiante/diagnostico/LectumB/'+ id);
      } else if(poll && poll.id === 12){
        this.$router.push('/estudiante/diagnostico/LectumA/'+ id);
      } else if(poll && poll.id === 14){
        this.$router.push('/estudiante/diagnostico/Interamericana/'+ id);
      } else if(poll && poll.id === 15){
        this.$router.push('/estudiante/diagnostico/Habitos/'+ id);
      } else{
        this.$router.push('/estudiante/diagnostico/' + id);
      }
    }
  },
};
</script>