<template>
  <loading-component v-if="loading" />
  <div v-else>
    <v-tabs v-model="tab">
      <v-tab href="#tab-1">Texto</v-tab>
      <v-tab href="#tab-2">Palabras Guardadas</v-tab>
      <v-tab href="#tab-3">Actividad</v-tab>
      <v-tab disabled>Tiempo {{ timeFormatted }}</v-tab>
    </v-tabs>

    <v-tabs-items touchless v-model="tab">
      <v-tab-item value="tab-1">
        <v-card class="mt-4" width="100vw">
          <v-card-title v-if="!hideTitle" style="border-width: medium">
            <v-row>
              <v-col cols="4">
                <v-text-field class="text--accent-4 mx-0 py-0" readonly label="Autor" :value="textData.author" />
              </v-col>
              <v-col cols="8">
                <v-text-field class="text--accent-4 mx-0 py-0" readonly label="Título" :value="textData.title" />
              </v-col>
              <v-col cols="8">
                <v-text-field v-if="textData.font && textData.font.length > 0" class="text--accent-4 mx-0 py-0" readonly
                  label="Fuente" :value="textData.font" />
              </v-col>
              <v-col cols="2">
                <v-text-field v-if="
                  textData.publicationYear &&
                  textData.publicationYear.length > 0
                " class="text--accent-4 mx-0 py-0" readonly label="Año de publicación"
                  :value="textData.publicationYear" />
              </v-col>
              <v-col cols="2">
                <v-text-field v-if="textData.type && textData.type.length > 0" class="text--accent-4 mx-0 py-0" readonly
                  label="Tipo de texto" :value="textData.type" />
              </v-col>
              <v-col cols="10">
                <v-text-field v-if="textData.description && textData.description.length > 0"
                  class="text--accent-4 mx-0 py-0" readonly label="Descripción" :value="textData.description" />
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="hideTitle = true" v-bind="attrs" v-on="on">
                      mdi-eye-off
                    </v-icon>
                  </template>
                  <span>Esconder Info</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>

          <v-tooltip bottom v-if="hideTitle">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="hideTitle = false" v-bind="attrs" v-on="on">
                mdi-eye
              </v-icon>
            </template>
            <span>Mostrar Info</span>
          </v-tooltip>

          <v-divider class="mx-4" vertical></v-divider>

          <v-card-title class="d-flex ">
            <p class="text-justify mr-8">Tiempo de lectura: <strong>{{ timeFormattedRead }} seg</strong></p>
            <div class="ml-6">
              <v-col class="d-flex align-center">
              <div class="color-box correct-answer"></div>
              <span class="ml-2">Correcto</span>
            </v-col>
            <v-col class="d-flex align-center">
              <div class="color-box incorrect-answer"></div>
              <span class="ml-2">Incorrecto</span>
            </v-col>
            </div>
          </v-card-title>
          
          <v-card-text>
            

            <v-container fluid ref="target" v-if="minigame === null">
              <v-row>
                <v-col class="text--primary" v-for="(data, i) in highlightedTextParagraphs" :key="i" cols="12">
                  <p class="text-justify" :style="{ fontSize: textSize }" v-html="data.description"></p>
                  <v-text-field disabled :label="
                    questionsFromText[i] && questionsFromText[i].answer !== ''
                      ? questionsFromText[i].answer
                      : 'No se realizó pregunta'
                  " />
                </v-col>
              </v-row>
            </v-container>

            <v-container fluid ref="target" v-if="minigame === 'image_minigame'">
              <v-row class="text--primary" v-for="(paragraph, i) in highlightedTextParagraphs" :key="i">
                <v-col cols="8">
                  <p class="text-justify" v-html="paragraph.description"></p>
                  <v-text-field readonly
                    :prepend-icon="questionsFromText[i].answer !== '' ? 'mdi-check-all' : 'mdi-close'"
                    :value="questionsFromText[i].answer !== '' ? questionsFromText[i].answer : 'No se realizó pregunta.'" />
                </v-col>
                <v-col cols="4">
                  <div :class="['image-container', getBorderClass(paragraph.id)]">
                    <v-img v-if="getImage(paragraph.id) != null" :src="getImage(paragraph.id)" class="contained-image"></v-img>
                    <v-card v-else class="contained-image d-flex justify-center align-center">
                      <h2>Ninguna imagen</h2>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <v-container fluid ref="target" v-if="minigame === 'unordered_text'">
              <v-row>
                <v-col cols="6"><strong>Orden correcto</strong></v-col>
                <v-col cols="6"><strong>Orden estudiante</strong></v-col>
              </v-row>
              <v-row v-for="(data, i) in highlightedTextParagraphs" :key="i">
                <v-col class="text--primary" cols="6">
                  <p class="text-justify" :style="{ fontSize: textSize }" v-html="data.description"></p>
                </v-col>
                <v-col cols="6" class="text--primary">
                  <p class="text-justify"
                    :style="{ fontSize: textSize, color: data.id === order[i] ? 'green' : 'red' }">
                    {{originalTextParagraphs.find(el => el.id === order[i]).description}}</p>
                    <v-text-field readonly
                    :prepend-icon="questionsFromText[i].answer !== '' ? 'mdi-check-all' : 'mdi-close'"
                    :value="questionsFromText[i].answer !== '' ? questionsFromText[i].answer : 'No se realizó pregunta.'" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-2">
        <words-table :words="savedWords" />
      </v-tab-item>

      <v-tab-item value="tab-3">
        <v-card-title class="d-flex">
          <p class="text-justify mr-8">Tiempo de respuesta: <strong>{{ timeFormattedAnswer }} seg</strong></p>
          <div class="ml-6">
            <v-col class="d-flex align-center">
            <div class="color-box correct-answer"></div>
              <span class="ml-2">Correcto</span>
            </v-col>
            <v-col class="d-flex align-center">
              <div class="color-box incorrect-answer"></div>
              <span class="ml-2">Incorrecto</span>
            </v-col>
          </div>
        </v-card-title>
        <student-reply :timeAnswer="timeAnswer" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from "axios";
import WordsTable from "@/components/students/WordsTable";
import StudentReply from "@/components/students/activity/finish/StudentReply.vue";
import LoadingComponent from "@/components/UI/LoadingComponent";

export default {
  components: { LoadingComponent, WordsTable, StudentReply },
  data() {
    return {
      order: [],
      hideTitle: false,
      savedWords: [],
      intervalTime: null,
      tab: null,
      textData: [],
      originalTextParagraphs: [], // Almacena los párrafos originales SIN resaltar
      highlightedTextParagraphs: [], // Almacena los párrafos CON resaltados
      textSize: "17px",
      highLights: [],
      activityId: null,
      paragraphQuestion: [],
      reply: null,
      loading: null,
      questionsFromText: [],
      time: '',
      timeread: '',
      timeAnswer: [],
      minigameInfo: null,
      minigame: null,
    };
  },
  methods: {
    parseHighlightsString(str) {
      try {
        // Manejar strings tipo: [Elemento 1, Elemento 2]
        const fixedStr = str
          .replace(/^\[/, '')    // Quitar [ inicial
          .replace(/\]$/, '')    // Quitar ] final
          .split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/) // Split por comas no dentro de comillas
          .map(item => item.trim().replace(/^"(.*)"$/, '$1'));
          
        return fixedStr.filter(item => item !== '');
      } catch (e) {
        return [];
      }
    },

    applyHighlights(paragraphs, highlights) {
      return paragraphs.map(p => {
        let description = p.description;
        
        highlights.forEach(phrase => {
          const escaped = this.escapeRegExp(phrase);
          const regex = new RegExp(`(${escaped})`, 'gi');
          description = description.replace(regex, '<span class="highlight-word">$1</span>');
        });
        
        return {...p, description};
      });
    },

    async getText() {
      try {
        const response = await axios.get(
          "student/activity/text/" + this.activityId
        );
        const { text, paragraph } = response.data;
        this.textData = text;
        this.originalTextParagraphs = [...paragraph]; // Guardar originales

        const rawHighlights = this.reply.highLight || '[]';
        this.highLights = this.parseHighlightsString(rawHighlights);

        // Aplicar resaltados a highlightedTextParagraphs
        this.highlightedTextParagraphs = paragraph.map(p => {
          let description = p.description;
          this.highLights.forEach(phrase => {
            const cleanPhrase = phrase.replace(/<\/?[^>]+(>|$)/g, "");
            const escaped = this.escapeRegExp(cleanPhrase);
            const regex = new RegExp(`(${escaped})`, 'gi');
            description = description.replace(regex, '<span class="highlight-word">$1</span>');
          });
          return { ...p, description };
        });


      } catch (e) {
        console.log(e);
      }
    },
    getImage(id) {
      if (this.minigameInfo != null) {
        const image = this.minigameInfo[id];
        return image ? image : null;
      }
      return null;
    },
    getBorderClass(paragraphId) {
      const studentImage = this.minigameInfo[paragraphId];
      const correctImage = this.originalTextParagraphs.find(p => p.id === paragraphId).image;
      return studentImage === correctImage ? 'correct-answer' : 'incorrect-answer';
    },
    getTimeAnswer(timeAnswer) {
      try {
        // Convertir el JSON a un array de objetos

        // Verificar si timeAnswer es un array y no está vacío
        if (!Array.isArray(timeAnswer) || timeAnswer.length === 0) {
          return 0; // Devolver 0 si no hay tiempos para sumar
        }

        // Usar reduce para sumar todos los tiempos
        const total = timeAnswer.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.time;
        }, 0);

        return total; // Devolver la suma total de los tiempos
      } catch (error) {
        console.error('Error al analizar el JSON:', error);
        return 0; // Devolver 0 en caso de error
      }
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
  },
  computed: {
    activities() {
      return this.$store.getters['student/getStudentActivitiesReply']
    },
    timeFormatted() {
      const minutes = Math.floor(this.intervalTime / 60);
      const seconds = (this.intervalTime % 60).toFixed(1);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    timeFormattedRead() {
      const minutes = Math.floor(this.timeread / 60);
      const seconds = (this.timeread % 60).toFixed(1);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    timeFormattedAnswer() {
      const minutes = Math.floor(this.time / 60);
      const seconds = (this.time % 60).toFixed(1);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
  },
  async created() {
    this.loading = true;

    this.activityId = Number(this.$route.params.id);

    await axios
    .get("student/reply/" + this.activityId)  
    .then((response) => {
      const { reply, minigame } = response.data;
      this.reply = reply;
      this.savedWords = JSON.parse(reply.saveWord);
      this.questionsFromText = JSON.parse(reply.question);
      this.order = JSON.parse(reply.order);
      this.questionAnswers = JSON.parse(reply.answer);
      const timeAnswerJSON = JSON.parse(reply.timeAnswer);
      const timeA = this.getTimeAnswer(timeAnswerJSON);
      this.timeAnswer = timeAnswerJSON;
      this.timeread = parseFloat((reply.time - timeA).toFixed(2));
      this.time = parseFloat(timeA.toFixed(2));
      this.order = JSON.parse(reply.order);
      this.minigameInfo = JSON.parse(reply.minigame_info);
      this.minigame = minigame; // Asigna el valor del minigame desde la respuesta
    })

    await this.getText();

    this.intervalTime = this.reply.time;
    this.loading = false;
  },
};
</script>

