<template>
  <v-btn
    class="d-flex text-wrap text-break"
    color="primary"
    @click="downloadTemplate"
  >
    Plantilla
  </v-btn>
</template>

<script>
import { defineComponent } from "vue";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export default defineComponent({
  name: "DownloadExcelTemplate",
  setup() {
    const downloadTemplate = () => {
      // Crear un libro de trabajo y una hoja de cálculo
      const wb = XLSX.utils.book_new();

      // Definir los encabezados
      const headers = [
        "Nombres del estudiante",
        "Apellido Paterno",
        "Apellido Materno",
        "Email",
      ];

      // Crear la hoja con los encabezados
      const ws = XLSX.utils.aoa_to_sheet([headers]);

      // Ajustar el ancho de las columnas
      const colWidths = headers.map((header) => ({ wch: header.length + 5 }));
      ws["!cols"] = colWidths;

      // Añadir la hoja al libro
      XLSX.utils.book_append_sheet(wb, ws, "Estudiantes");

      // Generar el archivo Excel
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      // Crear un Blob con el contenido del archivo
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      // Descargar el archivo
      saveAs(blob, "plantilla_estudiantes.xlsx");
    };

    return {
      downloadTemplate,
    };
  },
});
</script>
