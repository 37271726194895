<template>
  <v-dialog persistent v-model="dialog" max-width="95%">
    <v-container fluid v-if="pollActivity">
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between primary white--text">
          <div class="d-flex justify-center align-center">
            Diagnóstico Hábitos Lectores {{ pollActivity.id }}
          </div>
          <div class="d-flex align-center">
            <v-chip :color="getStatusClass(pollActivity)" class="mr-2 ">
              {{ new Date(`${pollActivity.limit}T23:59:00-03:00`) >= new Date() ? 'Activo' : 'Terminado' }}
            </v-chip>
            <v-btn  class="mt-2 ml-3" color="red"  @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-row class="ma-4">
          
          <v-col cols="12" md="6" class="pr-4 outlined">
            <div class="d-flex justify-space-between mt-1 mx-4 ">
              <div class="statistic-card">
                <h2>{{ formatTime(timeSpentAverage) }}</h2>
                <p>Tiempo promedio</p>
              </div>
              <div class="statistic-card">
                <h2>{{ replies.length }}</h2>
                <p>Estudiantes respondieron</p>
              </div>
              <div class="statistic-card">
                <h2>{{ completionStatus?.completed.count }}</h2>
                <p>Completaron</p>
              </div>
              <div class="statistic-card">
                <h2>{{ completionStatus?.remaining.count }}</h2>
                <p>Pendientes</p>
              </div>
            </div>
            <v-tabs v-model="sectionTab" grow>
              <v-tab v-for="(section, index) in sections" :key="index">
                {{ section.title }}
              </v-tab>
            </v-tabs>

            <v-tabs-items touchless v-model="sectionTab">
              <v-tab-item v-for="(section, index) in sections" :key="index">
                <v-card class="mt-4">
                  <div class="scrollable-content">
                    <v-card v-for="(question, qIndex) in sections[sectionTab]?.questions" :key="qIndex" class="mb-4">
                      <v-card-title style="background-color: rgba(63, 81, 181, 0.8);" class="white--text">
                        Pregunta {{ qIndex + 1 }}: {{ question.text }}
                        <v-chip class=" caption d-flex justify-end align-center ma-2" small>Tipo: {{ questionTypeMap[question.type] }}</v-chip>
                      </v-card-title>
                      
                      <v-card-text>

                        <!-- Renderizado para preguntas Matriz -->
                        <template v-if="question.type === 'matrix'" >
                          <div class="matrix-container">
                            <v-table class="matrix-table mt-4">
                              <thead>
                                <tr>
                                  <th class="subquestion-header">Subpregunta</th>
                                  <th 
                                    v-for="option in question.options" 
                                    :key="option.id"
                                    class="text-center option-header"
                                  >
                                    {{ option.text }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr 
                                  v-for="sub in question.sub_questions" 
                                  :key="sub.id"
                                >
                                  <td class="subquestion-cell">{{ sub.text }}</td>
                                  <td 
                                    v-for="option in question.options" 
                                    :key="option.id"
                                    class="text-center"
                                  >
                                    <v-btn 
                                      text 
                                      @click="openMatrixModal(question.id, sub.id, option.id)"
                                      class="matrix-cell"
                                    >
                                      <div class="d-flex flex-column align-center">
                                        <span>{{ getMatrixCount(question.id, sub.id, option.id) }}</span>
                                        <v-progress-linear
                                          :value="getMatrixPercentage(question.id, sub.id, option.id)"
                                          color="primary"
                                          height="10"
                                          rounded
                                        ></v-progress-linear>
                                      </div>
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </v-table>
                          </div>
                        </template>

                        <!-- Renderizado para otros tipos de preguntas -->
                        <template v-else-if="question.type !== 'open'">
                          <v-divider class="mb-4"></v-divider>
                          <div class="d-flex flex-column">
                            <div v-for="(alternative, j) in question.options" :key="j">
                              <div class="d-flex justify-space-between">
                                <div class="d-flex statistic-card">
                                  {{ j + 1 }})
                                  <h4 class="ml-2 text-left">
                                    {{ alternative.text }}
                                  </h4>
                                </div>
                                <div class="statistic-card justify-center align-content-center">
                                  <v-btn @click="openModal('alternative', question.id, alternative.id)">
                                    <h4 class="text-left">
                                      {{ getAnswerCount(question.id, alternative.id) }} Respuestas
                                    </h4>
                                  </v-btn>
                                </div>
                                <div class="statistic-card justify-center align-content-center">
                                  <v-progress-linear
                                    :value="getAnswerPercentage(question.id, alternative.id)"
                                    color="primary"
                                    height="20"
                                    rounded
                                    striped
                                  >
                                    {{ getAnswerPercentage(question.id, alternative.id) }}%
                                  </v-progress-linear>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>

                        <!-- Preguntas abiertas -->
                        <template v-else>
                          <v-btn block @click="openModal('development', question.id, null)" class="statistic-card mt-4">
                            Ver respuestas de desarrollo ({{ getOpenResponsesCount(question.id) }})
                          </v-btn>
                        </template>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>

          <v-col cols="12" md="6" class="outlined">
            <div class="d-flex justify-space-between align-center">
              <v-tabs v-model="resultsTab" grow>
              <v-tab>Resultados</v-tab>
              <v-tab>Finalizado</v-tab>
              <div class="d-flex justify-center align-center">
                <EditPollActivity :pollActivity="pollActivity" @poll-activity-updated="loadData" />
              </div>
            </v-tabs>
            <info-button
                class="mx-6"
                title="Información sobre el dashboard de diagnóstico"
                message="
                <ul>
                  <li><strong>Estructura general:</strong> El dashboard se divide en dos secciones principales. A la izquierda se muestran las preguntas del diagnóstico con sus estadísticas y a la derecha los resultados detallados.</li>
                  
                  <li><strong>Sección izquierda:</strong>
                    <ul>
                      <li>Tarjetas con métricas clave: tiempo promedio, respuestas recibidas, completados y pendientes</li>
                      <li>Detalle de cada pregunta con porcentajes de respuestas</li>
                      <li>Botones para ver quien respondio cada alternativa o ver respuestas de desarollo</li>
                    </ul>
                  </li>

                  <li><strong>Sección derecha:</strong>
                    <ul>
                      <li>Pestaña de Resultados: Tabla con todas las respuestas de estudiantes</li>
                      <li>Pestaña de Gráficos: Visualización de dona con progreso de completado</li>
                      <li>Botón de edición del diagnóstico</li>
                    </ul>
                  </li>

                  <li><strong>Funcionalidades clave:</strong>
                    <ul>
                      <li>Click graficos para ver a detalle los estudiantes específicos</li>
                      <li>Edición de parámetros globales del diagnóstico</li>
                      <li>Visualización de tiempos y diagnósticos individuales</li>
                    </ul>
                  </li>
                </ul>
                "
              ></info-button>
            </div>

            <v-tabs-items touchless v-model="resultsTab">
              <v-tab-item>
                <v-card class="flex-grow-1">
                  <v-card-title class="d-flex justify-space-between align-center">
                    <span>Respuesta de los estudiantes</span>
                  </v-card-title>
                  <ReplyHabitosTable
                    class="pa-2"
                    v-if="replies.length > 0"
                    :replies="replies"
                    :poll-activity="pollActivity"
                    :questions="questions"
                  />
                  <v-alert v-else type="info">
                    No hay respuestas disponibles aún
                  </v-alert>
                </v-card>

              </v-tab-item>
              
              <v-tab-item>
                <v-card class="flex-grow-1">
                  <v-card-title class="d-flex justify-space-between align-center">
                    <span>Gráfico de Término</span>
                  </v-card-title>
                  <DoughnutChart v-show="chartDonutData" :chartData="chartDonutData" :options="chartDonutOptions" @click.native="handleDoughnutClick"></DoughnutChart>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <MatrixResponsesModal 
      v-if="showMatrixModal"
      :showModal.sync="showMatrixModal"
      :questionId="selectedQuestionId"
      :subQuestionId="selectedSubQuestionId"
      :optionId="selectedOptionId"
      :replies="replies"
      :questions="questions"
    />

    <StudentModal :isPollActivity="true" :show.sync="showModal" :students="modalStudents" :title="modalTitle" />
    <ModalResponses 
      :isPollActivity="true"
      :showModal.sync="showAlternativeModal"
      :questionId="selectedQuestionId"
      :alternativeId="selectedAlternativeId"
      modalType="alternative"
      :replies="replies"
    />  
    <ModalResponses :isPollActivity="true" v-if="showDevelopmentModal" :showModal.sync="showDevelopmentModal" 
    :questionId="selectedQuestionId" :alternativeId="selectedAlternativeId" modalType="development" :replies="replies" />
  </v-dialog>
</template>

<script>
import axios from 'axios';
import DoughnutChart from '@/components/UI/DoughnutChart.vue';
import StudentModal from '@/components//UI/StudentModal.vue';
import EditPollActivity from '@/components/teacher/lesson/polls/forms/EditPollActivity.vue';
import ModalResponses from '@/components/UI/ModalResponses.vue';
import ReplyHabitosTable from './ReplyHabitosTable.vue';
import InfoButton from '@/components/UI/InfoButton.vue';
import MatrixResponsesModal from '@/components/UI/MatrixResponsesModal.vue';
import Swal from 'sweetalert2';

export default {
  props: ['pollActivity', 'dialog'],
  components: { DoughnutChart,ReplyHabitosTable,MatrixResponsesModal, ModalResponses, EditPollActivity, StudentModal, InfoButton },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  data() {
    return {
      questionTypeMap: {
        single_choice: 'Opción única',
        multiple_choice: 'Opción múltiple',
        matrix: 'Matriz',
        open: 'Desarrollo'
      },
      showMatrixModal: false,
      selectedSubQuestionId: null,
      selectedOptionId: null,
      sectionTab : 0,
      resultsTab:0,
      timeSpentAverage: null,
      chartDonutData: null,
      chartDonutOptions: { responsive: true },
      replies: [],
      text: null,
      timeSpent: [],
      modalStudents: null,
      modalTitle: "",
      showModal: false,
      selectedQuestionId: null,
      selectedAlternativeId: null,
      sections: [],
      questions: [],
      limit: null,
      description: null,
      completionStatus: null,
      answers: [],
      showDevelopmentModal: false,
      showAlternativeModal: false,
    };
  },
  computed: {
    totalPoints() {
      return this.pollActivity?.total_points || 0; // Ajustar según tu modelo de datos
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue === true) {
        this.loadData();
      } else {
        this.resetData();
      }
    },
    pollActivity: {
      handler(newValue) {
        if (newValue) {
          // Mantener sincronizadas las preguntas
          this.text = newValue.text || null;
          this.description = newValue.description || null;
          this.limit = newValue.limit || null;
          this.answers = newValue.answers || [];
        }
      },
      immediate: true,
    },
  },
  methods: {
    getMatrixCount(questionId, subQuestionId, optionId) {
      return this.replies.filter(reply => {
        const answer = reply.answer[questionId]?.answerId;
        return answer && answer[subQuestionId] === optionId;
      }).length;
    },

    getMatrixPercentage(questionId, subQuestionId, optionId) {
      const total = this.replies.length;
      return total > 0 
        ? (this.getMatrixCount(questionId, subQuestionId, optionId) / total) * 100
        : 0;
    },

    openMatrixModal(questionId, subQuestionId, optionId) {
      this.selectedQuestionId = questionId;
      this.selectedSubQuestionId = subQuestionId;
      this.selectedOptionId = optionId;
      this.showMatrixModal = true;
    },
    getQuestionType(type) {
      const types = {
        1: 'Elección de Respuestas Globales',
        2: 'Sí o No',
        3: 'Desarrollo'
      };
      return types[type] || 'Desconocido';
    },
    getOpenResponsesCount(questionId) {
      return this.replies.filter(reply => {
        const answer = reply.answer[questionId];
        return answer?.answerText?.trim().length > 0;
      }).length;
    },
    
    getAlternativeColor(altId, correctAnswer) {
      return altId === correctAnswer ? 'success' : 'primary';
    },
    resetData() {
      this.timeSpentAverage = null;
      this.chartDonutData = null;
      this.replies = [];
      this.timeSpent = [];
      this.sections = [];
      this.questions = [];
      this.limit = null;
      this.description = null;
      this.completionStatus = null;
      this.answers = [];
      this.showDevelopmentModal = false;
      this.showAlternativeModal = false;
      this.text = null;
    },
    async loadData() {
      if (!this.pollActivity) return;

      try {
        // 1. Obtener las preguntas desde el endpoint correcto
        const questionsResponse = await axios.get('/questionHabitos');
        this.questions = questionsResponse.data.questionsBySection[0];
        
        // 2. Organizar en secciones
        this.sections = this.organizeQuestionsIntoSections(this.questions);

        await Promise.all([
          this.getReplies(),
          this.getActivityCompletionStatus(),
        ]);
        
      } catch (error) {
        console.error("Error loading data:", error);
        Swal.fire('Error', 'No se pudieron cargar las preguntas', 'error');
      }
    },
    organizeQuestionsIntoSections(questions) {
      const sectionsMap = new Map();
      
      questions.forEach(question => {
        const sectionTitle = question.section || 'Sección Predeterminada';
        
        if (!sectionsMap.has(sectionTitle)) {
          sectionsMap.set(sectionTitle, {
            title: sectionTitle,
            questions: []
          });
        }
        
        sectionsMap.get(sectionTitle).questions.push(question);
      });
      
      return Array.from(sectionsMap.values());
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = (seconds % 60).toFixed(1);
      return minutes > 0 ? `${minutes}m ${remainingSeconds}s` : `${remainingSeconds}s`;
    },
    renderCharts() {
      if (this.chartDonutData) {
        this.getDonutChart();
      }
    },
    getStatusClass(item) {
      return new Date(`${item.limit}T23:59:00-03:00`) >= new Date() ? 'blue' : 'red';
    },
    getAnswerCount(questionId, answerId) {
      return this.replies.reduce((count, reply) => {
        const answer = reply.answer[questionId];
        if (!answer) return count;
        
        if (Array.isArray(answer.answerId)) {
          return count + (answer.answerId.includes(answerId) ? 1 : 0);
        }
        return count + (answer.answerId === answerId ? 1 : 0);
      }, 0);
    },
    getAnswerPercentage(questionId, answerId) {
      const totalReplies = this.replies.length;
      const answerReplies = this.getAnswerCount(questionId, answerId);
      return totalReplies > 0 ? ((answerReplies / totalReplies) * 100).toFixed(1) : 0;
    },
    openModal(type, questionId, alternativeId) {
      this.selectedAlternativeId = alternativeId;
      this.selectedQuestionId = questionId;
      
      console.log(questionId)
      console.log(alternativeId)

      if (type === 'alternative') {
        this.showAlternativeModal = true;
      } else if (type === 'development') {
        this.showDevelopmentModal = true;
      }
    },

    handleDoughnutClick(event, array) {
      if (array.length > 0) {
        const index = array[0]._index;
        const label = this.chartDonutData.labels[index];

        let students = [];
        if (label === 'Terminado') {
          students = this.completionStatus.completed.students;
          this.modalTitle = 'Estudiantes que han terminado el diagnóstico';
        } else {
          students = this.completionStatus.remaining.students;
          this.modalTitle = 'Estudiantes que aún no han terminado el diagnóstico';
        }
        this.modalStudents = students.map(student => {
          const reply = this.replies.find(reply => reply.user_id === student.id);
          const time = reply ? this.formatTime(reply.time) : 0;
          const points = reply ? reply.points : 0;
          return {
            id: student.id,
            name: student.name + ' ' + student.lastName,
            time: time,
            points: points  
          };
        });

        this.showModal = true;
      }
    },
    getDonutChart() {
      this.chartDonutData = {
        labels: ['Terminado', 'Faltantes'],
        datasets: [
          {
            data: [this.completionStatus.completed.count, this.completionStatus.remaining.count],
            backgroundColor: ['#36A2EB', '#FF6384'],
            borderColor: ['#36A2EB', '#FF6384']
          }
        ]
      };
      this.chartDonutOptions = {
        maintainAspectRatio: false,
        onClick: this.handleDoughnutClick
      };
    },
    async getReplies() {
      await axios.get(`teacher/poll_activity/replies/${this.pollActivity.id}`)
        .then(response => {
          if (response.data.ok) {
            this.replies = response.data.replies.map(reply => ({
              ...reply,
              answer: JSON.parse(reply.answer)
            }));

            // Usar las preguntas ya cargadas en lugar de this.pollActivity.questions
            this.timeSpent = this.replies.map(reply => reply.time);
            this.timeSpentAverage = this.replies.length > 0 
              ? (this.timeSpent.reduce((a, b) => a + b, 0) / this.replies.length)
              : 0;
          }
        })
        .catch(error => console.error("Error fetching replies:", error));
    },
    async getActivityCompletionStatus() {
      await axios.get(`teacher/poll_activity/getPollActivityCompletionStatus/${this.pollActivity.id}`)
        .then(response => {
          this.completionStatus = response.data;
          this.getDonutChart();
          this.renderCharts();
        })
        .catch(error => {
          console.error("Error fetching completion status:", error);
        });
    },
    closeDialog() {
      this.resetData();
      this.$emit('close-dialog');
    }
  },
  created() {
    this.text = this.pollActivity && this.pollActivity.text ? this.pollActivity.text : null;
  }
};
</script>

  