<template>
  <div>

    <v-tabs v-model="tab">
      <v-tab href="#tab-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Asignatura
            </v-btn>
          </template>
          <span>Detalles asignatura.</span>
        </v-tooltip>
      </v-tab>
      <v-tab href="#tab-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Listado
            </v-btn>
          </template>
          <span>Muestra todos los alumnos inscritos en la asignatura.</span>
        </v-tooltip>
      </v-tab>
      <v-tab href="#tab-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Diagnósticos
            </v-btn>
          </template>
          <span>Muestra todos las diagnósticos parte de la asignatura.</span>
        </v-tooltip>
      </v-tab>
      <v-tab href="#tab-4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>
              Actividades
            </v-btn>
          </template>
          <span>Muestra todos las actividades realizadas.</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>

    <v-tabs-items touchless v-model="tab">
      <v-tab-item value="tab-1">
        <v-row fluid class="mt-8 justify-center mb-4" style="flex-wrap: wrap-reverse;">
          <v-col cols="12" md="8" lg="7" class="mb-4 mb-md-0">
          
            <v-card class="h-100" outlined >
              <lesson-info/> 
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <v-card  class="h-100 d-flex flex-column justify-center " outlined style=" height: 100%; border: 0.2px solid #333;">
              
            <v-card-actions >
                <v-col >
                  <v-row class="py-5 d-flex justify-center align-center">
                    <assignment-form :lessonId="this.$route.params.id" />
                  </v-row>
                <v-row class="py-5 d-flex justify-center align-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" class=" responsive-button my-4" height="80px" width="90%" color="primary" @click="showShareModal = true"  @change="showShareModal = $event" >Compartir Asignatura</v-btn>
                      </template>   
                      <span>Compartir la asignatura con otros profesores dentro de la aplicación.</span>
                    </v-tooltip>
                  </v-row>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      </v-tab-item>

      <v-tab-item  value="tab-2">
        <v-row class="d-flex justify-center pb-8 mt-8">
          <v-card outlined style="border: 0.2px solid #333;" width="90%" >
            <current-students ></current-students>
          </v-card>
        </v-row>
          
        
      </v-tab-item>

      <v-tab-item   value="tab-3">
        <v-row class="d-flex justify-center px-8 mt-8">
          <v-card  style="border: 0.2px solid #333;" width="90%" >
            <lesson-polls-tabla></lesson-polls-tabla>
          </v-card>
            
        </v-row>
      </v-tab-item>

      <v-tab-item value="tab-4">
        <v-row class="d-flex justify-center align-center mt-8">
          <lesson-activities-table></lesson-activities-table>
        </v-row>
        </v-tab-item>
    </v-tabs-items>

    <lesson-share :showShareModal="showShareModal" @change="showShareModal = $event" ></lesson-share>

  </div>
</template>

<script>
import CurrentStudents from "@/components/teacher/lesson/students/CurrentStudents";
import AssignmentForm from "@/components/teacher/lesson/forms/AssignmentForm";
import LessonInfo from "@/components/teacher/lesson/LessonInfo";
import LessonShare from '@/components/teacher/lesson/forms/LessonShare';
import LessonActivitiesTable from '@/components/teacher/lesson/activitys/LessonActivitiesTable.vue';
import LessonPollsTabla from "@/components/teacher/lesson/polls/LessonPollsTabla.vue";
export default {
  components: { LessonInfo,LessonPollsTabla, CurrentStudents, AssignmentForm, LessonShare, LessonActivitiesTable },
  data() {
    return {
      lessonId: null,
      tab: null,
      showShareModal: false,
    }
  },
}
</script>
