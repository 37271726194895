<template>
  <v-dialog v-model="show" max-width="50%">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text class="scrollable-content">
        <v-list>
          <v-list-item v-for="student in students" :key="student.id">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>mdi-account</v-icon> {{ student.name }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="!isPollActivity">
                .- Nota : {{ student.pointsPercentage }}%  Puntaje: {{ student.points ? student.points : 0 }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                .- : Tiempo {{ student.time ? student.time : 0 }} seg  Puntaje: {{ student.points ? student.points : 0 }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    students: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ""
    },
    isPollActivity: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    }
  },
  created() {
    console.log("isPoll:", this.isPollActivity);
  }
};
</script>
