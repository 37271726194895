<template>
    <v-dialog persistent max-width="95%" v-model="dialog">
      <v-container fluid v-if="pollActivity">
        <v-card max-height="auto">
          <v-card-title class="d-flex align-center justify-space-between primary white--text">
            <div class="d-flex">
              Diagnóstico {{ diagnosticTitle }} {{ pollActivity.id }}
            </div>
            <div class="d-flex justify-center align-center">
              <v-chip :color="getStatusClass(pollActivity)" class="mt-2 ">
                {{ new Date(`${pollActivity.limit}T23:59:00-03:00`) >= new Date() ? 'Activo' : 'Terminado' }}
              </v-chip>
              <v-btn class="mt-2 ml-3" color="red" @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card-title>
  
          <v-row class="d-flex ma-4">
            <v-col cols="12" md="6" class="d-flex flex-column outlined">
              <div class="d-flex justify-space-between mt-1 mx-4">
                <div class="statistic-card">
                  <h2>{{TotalPoints}} pts</h2>
                  <h3>Puntaje Total</h3>
                </div>
                <div class="statistic-card">
                  <h2>{{ averageScore }}</h2>
                  <h3>Puntaje Promedio</h3>
                </div>
                <div class="statistic-card" v-if="isInteramericana">
                  <h2>{{ (averageTotalTime )}}</h2>
                  <h3>Tiempo Promedio</h3>
                </div>
                <div class="statistic-card">
                  <v-card-text >
                    <p><strong>Fecha Límite:</strong> {{ (limit) }}</p>
                  </v-card-text>
                </div>
              </div>
             
              <v-tabs 
                  v-model="sectionTab" 
                  grow
                  slider-color="deep-purple"
                >
                  <v-tab 
                    v-for="(section, index) in sections" 
                    :key="index"
                    :aria-label="`${section.title}`"
                  >
                    {{ sectionTitle(section) }}
                  </v-tab>
                </v-tabs>
              <v-tabs-items touchless v-model="sectionTab">
                <v-tab-item v-for="(section, index) in sections" :key="index">
                  <v-card>
                    <div class="d-flex justify-center align-center pa-2">
                      <span class="body-1 font-weight-medium primary--text mr-2">
                        📊 Puntaje promedio por sección: 
                        <span class="ml-1">{{ sectionAverages[section.title] }} </span>
                      </span>
                      <v-divider vertical class="mx-3"></v-divider>
                      <span class="body-1 font-weight-medium primary--text">
                        {{ ((sectionAverages[section.title] / section.questions.length * 100).toFixed(2)) }}%
                      </span>
                    </div>
                    <div  class="scrollable-content d-flex ml-3 mt-1 flex-column border">
                      <div v-for="(question, questionIndex) in section.questions" :key="question.id">
                        <v-card class="mt-2">
                          <v-form>
                            <v-card-title style="background-color: rgba(63, 81, 181, 0.8);" class="white--text book-paragraph "> 
                              <p>{{ `Pregunta ${questionIndex + 1}: ${question.text}` }}</p>
                                <v-img v-if="question.id ==='2_10'" :src="imagen3"></v-img>
                                <p v-if="question.id ==='2_10'">De acuerdo con el gráfico y la información que proporciona el texto, ¿cuál(es) de los
                                enunciados que siguen describe(n) de manera correcta los resultados obtenidos en el
                                ensayo?</p>
                                <v-chip v-if="isInteramericana && section.hasTexts" small class="ml-2">
                                  Texto: {{ getTextTitle(question) }}
                                </v-chip>
                            </v-card-title>
                            <v-container v-if="isInteramericana" class="time-metrics pa-2">
                              <div class="d-flex align-center">
                                <v-icon small class="mr-1">mdi-clock-outline</v-icon>
                                <span class="caption">
                                  Tiempo promedio: {{ averageTime(question.id) }} 
                                  ({{ answerCounts[question.id]?.responseCount || 0 }} respuestas)
                                </span>
                              </div>
                            </v-container>
                            <v-container class="pa-2" fluid>
                              <div class="mt-1" v-if="question.affirmations">
                                <ul>
                                  <li class="book-paragraph" v-for="(affirmation) in question.affirmations" :key="affirmation.id">
                                    <strong>{{ affirmation.id }}.</strong> {{ affirmation.text }}
                                  </li>
                                </ul>
                              </div>
                              <v-container
                                fluid
                              >
                                <div class="d-flex flex-column">
                                  <div
                                    v-for="(alternative, i) in question.options"
                                    :key="i"
                                  >
                                    <div class="d-flex justify-space-between">
                                      <div
                                        class="d-flex statistic-card-question"
                                        :style="{
                                          backgroundColor:
                                            alternative.id === question.correctAnswer
                                              ? '#ccffcc'
                                              : '#f5f5f5',
                                        }"
                                      >
                                        {{ i }})
                                        <h4 class="ml-2 text-left">
                                          {{ alternative.text }}
                                        </h4>
                                      </div>
                                      <div
                                        class="statistic-card-question justify-center align-content-center"
                                        :style="{
                                          backgroundColor:
                                            alternative.id === question.correctAnswer
                                              ? '#ccffcc'
                                              : '#f5f5f5',
                                        }"
                                      >
                                        <v-btn
                                          @click="
                                            openModal(
                                              question.id,
                                              alternative.id
                                            )
                                          "
                                        >
                                          <h4 class="text-left">
                                            {{
                                              getAnswerCount(
                                                question.id,
                                                alternative.id
                                              )
                                            }}
                                            Respuestas
                                          </h4>
                                        </v-btn>
                                      </div>
                                      <div
                                        class="statistic-card-question justify-center align-content-center"
                                        :style="{
                                          backgroundColor:
                                          alternative.id === question.correctAnswer
                                              ? '#ccffcc'
                                              : '#f5f5f5',
                                        }"
                                      >
                                        <v-progress-linear
                                          :value="
                                            getAnswerPercentage(
                                              question.id,
                                              alternative.id
                                            )
                                          "
                                          :color="
                                            getColorBarAlternative(question.correctAnswer,
                                              alternative.id)
                                          "
                                          height="20"
                                          rounded
                                          striped
                                        >
                                          {{
                                            getAnswerPercentage(
                                              question.id,
                                              alternative.id
                                            )
                                          }}%
                                        </v-progress-linear>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </v-container>
                            </v-container>
                          </v-form>
                        </v-card>
                      </div>
                    </div>
                    
                  </v-card>

                </v-tab-item>
              </v-tabs-items>

            </v-col>
  
            <v-col cols="12" md="6" class="outlined">
              <div  class="d-flex justify-space-between align-center">
                <v-tabs v-model="tab" @change="handleTabChange">
                  <v-tab href="#tab-1">Puntajes</v-tab>
                  <v-tab href="#tab-2">Finalizado</v-tab>
                  <v-tab href="#tab-3">Resumen</v-tab>
                  <div class="d-flex justify-center align-center">
                    <v-btn
                      @click="generateExcelDetailed"
                      class="ml-2 mr-4"
                      :disabled="replies.length === 0"
                      fab
                      dark
                      small
                      color="success"
                    >
                      <v-icon dark>mdi-microsoft-excel</v-icon>
                    </v-btn>
                        <EditPollActivity :pollActivity="pollActivity" @poll-activity-updated="loadData" />
                      
                  </div>
                </v-tabs>
                <info-button
                  class="mr-4"
                  title="Información del Dashboard de Diagnóstico"
                  message="
                  <ul>
                    <li><strong>Estructura general:</strong>
                      <ul>
                        <li>Dividido en dos secciones principales: Análisis de preguntas a la izquierda y Resultados generales a la derecha</li></ul>
                    </li>

                    <li><strong>Sección izquierda</strong>
                      <ul>
                        <li>Métricas clave: Puntaje total, promedio y tiempo promedio de respuesta (Interamericana)</li>
                        <li>Navegación por secciones/preguntas del diagnóstico</li>
                        <li>Detalle de cada pregunta con:
                          <ul>
                            <li>Opciones de respuesta con resaltado de correctas</li>
                            <li>Porcentaje de selección por alternativa</li>
                            <li>Tiempos promedio de respuesta (cuando aplica)</li>
                            <li>Acceso a respuestas individuales</li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li><strong>Sección derecha </strong>
                      <ul>
                        <li><strong>Pestaña de puntajes:</strong> Distribución de resultados en porcentajes</li>
                        <li><strong>Progreso de completado:</strong> Visualización de estudiantes que finalizaron</li>
                        <li><strong>Resumen ejecutivo:</strong> Tabla con resultados detallados por estudiante</li>
                        <li>Herramientas adicionales:
                          <ul>
                            <li>Exportación de datos a Excel</li>
                            <li>Edición de parámetros del diagnóstico</li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li><strong>Interactividad:</strong>
                      <ul>
                        <li>Click en gráficos ver a más detalle los estudiantes específicos</li>
                        <li>Hover en barras de progreso para ver porcentajes</li>
                      </ul>
                    </li>
                    
                  </ul>
                  "
                ></info-button>
              </div>
              <v-tabs-items touchless v-model="tab">
              <v-tab-item value="tab-1">
                <v-card>
                  <v-card-title
                    >Puntajes en porcentaje de la actividad
                    </v-card-title
                  >
                  <v-card-actions class="d-flex justify-center align-center">
                    <BarChart
                      v-show="tab === 'tab-1'"
                      :chartBarData="chartBarData"
                      :chartBarOptions="chartBarOptions"
                      @click="handleBarClick"
                    ></BarChart>
                    
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card>
                  <v-card-title>Gráfico de Término
                  </v-card-title>
                    <v-card-actions class="d-flex justify-center align-center">
                      <DoughnutChart v-show="tab === 'tab-2'" :chartData="chartDonutData" :options="chartDonutOptions" @click.native="handleDoughnutClick" />
                    </v-card-actions>
                  </v-card>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-card>
                  <v-card-title>Diagnósticos realizados
                  </v-card-title>
                    <v-card-actions class="d-flex justify-center align-center">
                      <StudentTable 
                          :replies="replies" 
                          :total-points="TotalPoints"
                          :diagnostic-is="diagnosticIs"
                          :poll-activity-id="pollActivity.id"
                          :sectionScoresByStudent="sectionScoresByStudent"
                          :sections="sections"
                        />
                    </v-card-actions>
                  </v-card>
              </v-tab-item>
            </v-tabs-items>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <StudentModal
        :show.sync="showModal"
        :students="modalStudents"
        :title="modalTitle"
      />
      <ModalResponses
        v-if="showAlternativeModal"
        :showModal.sync="showAlternativeModal"
        :questionId="selectedQuestionId"
        :alternativeId="selectedAlternativeId"
        modalType="alternative"
        :replies="replies"
        :isPollActivity="true"
      />
    </v-dialog>
  </template>
  
  <script>
  import DoughnutChart from '@/components/UI/DoughnutChart.vue';
  import BarChart from "@/components/UI/BarCharts.vue";
  import axios from 'axios';
  import imagen1 from "@/assets/images/LectumA_1.png";
  import imagen2 from "@/assets/images/LectumA_2.png";
  import imagen3 from "@/assets/images/LectumA_3.png";
  import ModalResponses from "@/components/UI/ModalResponses.vue";
  import StudentModal from "@/components/UI/StudentModal.vue";
  import * as XLSX from "xlsx";
  import StudentTable from "./ReplyDiagnosticTable.vue"
  import EditPollActivity from './forms/EditPollActivity.vue';
  import InfoButton from '@/components/UI/InfoButton.vue';
  export default {
    props: ['pollActivity', 'dialog', 'diagnosticIs'],
    components: { DoughnutChart, BarChart, ModalResponses, StudentModal, StudentTable,EditPollActivity, InfoButton},
    data() {
      return {
        sectionTab: 0,
        sections: [],
        averageScore: 0,     
        chartDonutData: null,
        imagen1: imagen1,
        imagen2: imagen2,
        imagen3: imagen3,
        chartDonutOptions: { responsive: true },
        chartBarData: null,
        chartBarOptions: { responsive: true },
        replies: [],
        limit: null,
        description: null,
        selectedAlternativeId : null,
        selectedQuestionId : null,
        showAlternativeModal: false,
        answerCounts : {}, 
        completionStatus: null,
        tab: 'tab-1',
        showModal: false,
        modalStudents: null,
        sectionScoresByStudent: {},
        modalTitle: "",
      };
    },
    computed :{
      TotalPoints() {
        return this.isInteramericana ? 125 : 50;
      },
      sectionAverages() {
        const averages = {};
        const totalStudents = this.replies.length;

        this.sections.forEach(section => {
          const total = this.replies.reduce((sum, reply) => {
            return sum + (this.sectionScoresByStudent[reply.id][section.title] || 0);
          }, 0);
          
          averages[section.title] = totalStudents > 0 
            ? (total / totalStudents).toFixed(2)
            : 0;
        });
        console.log(averages)
        return averages;
      },
      mappedCompletionStatus() {
        const completedStudents = this.completionStatus.completed.students.map(
          (student) => {
            const reply = this.replies.find(
              (reply) => reply.student_id === student.id
            );
            const points = reply ? reply.points : 0;
            const pointsPercentage = ((points / this.TotalPoints) * 100).toFixed(
              2
            );
            return {
              ...student,
              points,
              pointsPercentage,
            };
          }
        );

        const remainingStudents = this.completionStatus.remaining.students.map(
          (student) => {
            const reply = this.replies.find(
              (reply) => reply.student_id === student.id
            );
            const points = reply ? reply.points : 0;
            const pointsPercentage = ((points / this.TotalPoints) * 100).toFixed(
              2
            );
            return {
              ...student,
              points,
              pointsPercentage,
            };
          }
        );

        return {
          completed: {
            count: this.completionStatus.completed.count,
            students: completedStudents,
          },
          remaining: {
            count: this.completionStatus.remaining.count,
            students: remainingStudents,
          },
        };
      },
      averageTotalTime() {
        if (!this.isInteramericana) return 'N/A';
        
        const totalTimes = this.replies.map(reply => {
          const answers = JSON.parse(reply.answer);
          return Object.values(answers).reduce((sum, a) => sum + (a.time || 0), 0);
        }).filter(t => t > 0);

        if (totalTimes.length === 0) return '0s';
        
        const avg = totalTimes.reduce((a, b) => a + b, 0) / totalTimes.length;
        return this.formatTime(avg) ;
      },
      isInteramericana() {
      
        return this.diagnosticIs === 'Interamericana';
      },
      
      diagnosticTitle() {
        return {
          'A': 'Lectum A',
          'B': 'Lectum B',
          'Interamericana': 'Interamericana'
        }[this.diagnosticIs];
      },
    }
    ,
    watch: {
      dialog(newVal) {
        if (newVal) this.loadData();
      },
    },
    methods: {
      sectionTitle(section) {
        if (this.isInteramericana) {
          return section.title;
        }
        return `${section.text?.title} (${section.questions.length} preguntas)`;
      },
      async loadData() {

        this.sections = [];
        this.averageScore = 0;
        this.chartDonutData = null;
        this.chartDonutOptions = { responsive: true };
        this.chartBarData = null;
        this.chartBarOptions = { responsive: true };
        this.replies = [];
        this.limit = null;
        this.description = null;
        this.selectedAlternativeId = null;
        this.selectedQuestionId = null;
        this.showAlternativeModal = false;
        this.completionStatus = null;
        this.answerCounts = {};
        this.tab = "tab-1";
        
        try {
          
          const repliesResponse = await axios.get(`teacher/poll_activity/replies/${this.pollActivity.id}`);
          const completionResponse = await axios.get(`teacher/poll_activity/getPollActivityCompletionStatus/${this.pollActivity.id}`);
          this.completionStatus = completionResponse.data;

          if(this.diagnosticIs === "A"){
            const response = await axios.get("lectum");
            const questionsLectum = await axios.get("questionLectum");
            this.questionsBySection = questionsLectum.data.questionsBySection;
            this.texts = response.data.texts;

            // Crear secciones con textos asociados y preguntas
            this.sections = this.texts.map((text, i) => {
              const questions = questionsLectum.data.questionsBySection[i] || []; // Función para obtener preguntas

              return {
                title: `Texto ${i + 1}`,
                text: text,
                questions: questions, // Asigna preguntas a cada sección
              };
            });
          }else if (this.diagnosticIs === "B"){
            const response = await axios.get("lectumB");
            const questionsLectum = await axios.get("questionLectumB");
            this.questionsBySection = questionsLectum.data.questionsBySection;
            this.texts = response.data.texts;

            // Crear secciones con textos asociados y preguntas
            this.sections = this.texts.map((text, i) => {
              const questions = questionsLectum.data.questionsBySection[i] || []; // Función para obtener preguntas

              return {
                title: `Texto ${i + 1}`,
                text: text,
                questions: questions, // Asigna preguntas a cada sección
              };
            });
          }else if((this.diagnosticIs === "Interamericana")){
            const [questionsRes, textsRes] = await Promise.all([
              axios.get("/questionInteramericana"),
              axios.get("/interamericana")
            ]);

            this.questionsBySection = questionsRes.data.questionsBySection;
            this.texts = textsRes.data.texts;
            
            this.sections = [
              {
                title: "Parte I - Vocabulario",
                index: 1,
                questions: this.questionsBySection[1] || [],
                hasTexts: false
              },
              {
                title: "Parte II - Velocidad",
                index: 3,
                questions: this.questionsBySection[3] || [],
                hasTexts: false
              },
              {
                title: "Parte III - Comprensión",
                index: 5,
                questions: this.questionsBySection[5] || [],
                texts: this.texts,
                hasTexts: true
              }
            ];
          }

          this.replies = repliesResponse.data.replies;
          this.sectionScoresByStudent = {};
          this.replies.forEach(reply => {
            this.sectionScoresByStudent[reply.id] = this.calculateSectionScores(reply);
          });
          console.log(this.sectionScoresByStudent)
          this.description = this.pollActivity.description;
          this.limit = this.pollActivity.limit;
          this.averageScore = this.calculateAverageScore(this.replies);
          this.answerCounts = this.countAnswers();
          this.getBarChart();
        } catch (error) {
          console.error("Error loading data", error);
        }
      },
      formatTime(seconds) {
        if (!seconds || seconds < 0) return 'N/A';

        const totalSeconds = Math.floor(seconds); // Redondear correctamente
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;

        return minutes > 0
        ? `${minutes}m${remainingSeconds}s`
        : `${remainingSeconds}s`;
      },
      generateExcelDetailed() {
        // Crear un nuevo libro de Excel
        const workbook = XLSX.utils.book_new();

        // ========== Hoja 1: Por Preguntas ==========
        const questionsSheetData = [['Pregunta']];
        
        // Encabezados dinámicos para estudiantes
        this.replies.forEach(reply => {
          questionsSheetData[0].push(
            `${reply.student.name} ${reply.student.lastName} - Correcta`,
            `${reply.student.name} ${reply.student.lastName} - Opción`
          );
        });

        // Procesar preguntas
        this.sections.forEach(section => {
          section.questions.forEach(question => {
            const row = [question.text];
            
            this.replies.forEach(reply => {
              const answers = JSON.parse(reply.answer);
              let answer;
              
              // Diferenciar estructura de respuestas
              if (this.isInteramericana) {
                answer = Object.values(answers).find(a => a.questionId === question.id);
              } else {
                answer = Object.values(answers).find(a => a.questionId === question.id);
              }

              const isCorrect = answer?.answerId === question.correctAnswer ? 1 : 0;
              const selectedOption = answer?.answerId || 'Sin respuesta';
              
              row.push(isCorrect, selectedOption);
            });
            
            questionsSheetData.push(row);
          });
        });

        // ========== Hoja 2: Por Estudiantes ==========
        const studentsSheetData = [['Estudiante']];
        
        // Encabezados dinámicos para preguntas
        this.sections.forEach(section => {
          section.questions.forEach(question => {
            studentsSheetData[0].push(
              `${question.id} - Correcta`,
              `${question.id} - Opción`
            );
          });
        });

        // Procesar estudiantes
        this.replies.forEach(reply => {
          const row = [`${reply.student.name} ${reply.student.lastName}`];
          const answers = JSON.parse(reply.answer);
          
          this.sections.forEach(section => {
            section.questions.forEach(question => {
              let answer;
              
              // Manejar diferencia estructural
              if (this.isInteramericana) {
                answer = Object.values(answers).find(a => a.questionId === question.id);
              } else {
                answer = Object.values(answers).find(a => a.questionId === question.id);
              }

              const isCorrect = answer?.answerId === question.correctAnswer ? 1 : 0;
              const selectedOption = answer?.answerId || 'Sin respuesta';
              
              row.push(isCorrect, selectedOption);
            });
          });
          
          studentsSheetData.push(row);
        });


        // ========== Hoja 3: Puntajes por Sección ==========
        const sectionSheetData = [['Estudiante', 'Puntaje Total']];
        
        // Configurar encabezados según diagnóstico
        if (this.isInteramericana) {
          sectionSheetData[0].push('Vocabulario', 'Velocidad', 'Comprensión');
        } else {
          this.sections.forEach((section) => {
            sectionSheetData[0].push(`${section.title}`);
          });
        }

        // Procesar cada estudiante
        this.replies.forEach(reply => {
          const studentName = `${reply.student.name} ${reply.student.lastName}`;
          const sectionScores = this.sectionScoresByStudent[reply.id];
          
          const row = [studentName, sectionScores['Total']];
          
          if (this.isInteramericana) {
            row.push(
              sectionScores['Parte I - Vocabulario'] || 0,
              sectionScores['Parte II - Velocidad'] || 0,
              sectionScores['Parte III - Comprensión'] || 0
            );
          } else {
            this.sections.forEach(section => {
              row.push(sectionScores[section.title] || 0);
            });
          }
          
          sectionSheetData.push(row);
        });

        // Crear y añadir hoja

        // Ajustar anchos de columnas


        // ========== Crear hojas ==========
        const sectionSheet = XLSX.utils.aoa_to_sheet(sectionSheetData);
        const questionsSheet = XLSX.utils.aoa_to_sheet(questionsSheetData);
        const studentsSheet = XLSX.utils.aoa_to_sheet(studentsSheetData);
        
        // Añadir hojas al libro
        XLSX.utils.book_append_sheet(workbook, sectionSheet, 'Puntajes por Sección');
        XLSX.utils.book_append_sheet(workbook, questionsSheet, 'Por Preguntas');
        XLSX.utils.book_append_sheet(workbook, studentsSheet, 'Por Estudiantes');

        // ========== Ajustes de formato ==========
        // Ancho de columnas
        sectionSheet['!cols'] = [
          { wch: 25 }, 
          { wch: 15 },
          ...Array(sectionSheetData[0].length - 2).fill({ wch: 15 })
        ];
        questionsSheet['!cols'] = [{ wch: 50 }, ...Array(questionsSheetData[0].length - 1).fill({ wch: 15 })];
        studentsSheet['!cols'] = [{ wch: 25 }, ...Array(studentsSheetData[0].length - 1).fill({ wch: 15 })];

        // ========== Descargar archivo ==========
        XLSX.writeFile(workbook, `Resultados_${this.diagnosticTitle}_${new Date().toISOString().split('T')[0]}.xlsx`);
      },
      calculateSectionScores(reply) {
          const answers = JSON.parse(reply.answer);
          const sectionScores = {};
          let total = 0; // Variable para acumular el total

          this.sections.forEach(section => {
              let score = 0;
              
              section.questions.forEach(question => {
                  const answer = Object.values(answers).find(a => 
                      a.questionId === question.id
                  );
                  
                  if (answer && answer.answerId === question.correctAnswer) {
                      score += 1;
                      total += 1; // Sumamos al total general
                  }
              });
              
              sectionScores[section.title] = score;
          });
          sectionScores['Total'] = total; // Agregamos el total calculado
          return sectionScores;
      },
      averageTime(questionId) {
        if (!this.isInteramericana) return 'N/A';
        
        const questionData = this.answerCounts[questionId];
        if (!questionData || questionData.responseCount === 0) return '0s';
        
        const avg = questionData.totalTime / questionData.responseCount;
        return this.formatTime(avg);
      },
      getTextTitle(question) {
        if (!this.isInteramericana) return '';
        const text = this.texts.find(t => 
          t.description===question.description
        );
        return text?.title || '';
      },
      calculateAverageScore(replies) {
        const totalScore = replies.reduce((sum, reply) => sum + reply.points, 0);
        return (totalScore / replies.length).toFixed(2);
      },
      openModal(questionId, alternativeId) {
        this.selectedAlternativeId = alternativeId;
        this.selectedQuestionId = questionId;
        this.showAlternativeModal = true;
      },
      getAnswerPercentage(questionId, alternativeId) {
        const totalReplies = this.replies.length;
        const count = this.getAnswerCount(questionId, alternativeId);
        if (totalReplies > 0 && count > 0) {
          return ((count / totalReplies) * 100).toFixed(2); // Formateamos a dos decimales
        }
        return "0.00";
      },
      async handleTabChange(tab) {
        // Desactivar la visibilidad de los gráficos mientras se cargan los datos
        this.chartBarData = null;
        this.chartDonutData = null;
        // Cargar los datos según la pestaña seleccionada
        if (tab === "tab-1") {
          await this.getBarChart();
          this.renderCharts();
        }
        if (tab === "tab-2") {
          await this.getDonutChart();
          this.renderCharts();
        }

        // Renderizar los gráficos después de cargar los datos
      },
      getColorBarAlternative(question_id, alternative_id) {
        if (question_id != alternative_id) {
          return "red";
        } else {
          return "blue";
        }
      },
      getAnswerCount(questionId, alternativeId) {
        if (
          this.answerCounts[questionId] &&
          this.answerCounts[questionId].options &&
          this.answerCounts[questionId].options[alternativeId]
        ) {
          return this.answerCounts[questionId].options[alternativeId];
        }
        return 0;
      },
      countAnswers() {
        const counts = {};
        
        this.replies.forEach(reply => {
          try {
            const answers = JSON.parse(reply.answer);
            
            // Para Interamericana: answers es un objeto de objetos
            if (this.isInteramericana) {
              Object.values(answers).forEach(answer => {
                if (!counts[answer.questionId]) {
                  counts[answer.questionId] = {
                    options: {},
                    totalTime: 0,
                    responseCount: 0
                  };
                }

                if (answer.answerId) {
                  counts[answer.questionId].options[answer.answerId] = 
                    (counts[answer.questionId].options[answer.answerId] || 0) + 1;
                }

                if (answer.time) {
                  counts[answer.questionId].totalTime += answer.time;
                  counts[answer.questionId].responseCount++;
                }
              });
            } 
            // Para Lectum: answers es un array de objetos
            else {
              Object.values(answers).forEach(answer =>{
                if (!counts[answer.questionId]) {
                  counts[answer.questionId] = {
                    options: {},
                    totalTime: 0,
                    responseCount: 0
                  };
                }
                if (answer.answerId) {
                  counts[answer.questionId].options[answer.answerId] = 
                    (counts[answer.questionId].options[answer.answerId] || 0) + 1;
                }
              });
            }
          } catch (error) {
            console.error("Error parsing answer:", error);
          }
        });
        return counts;
      },
    renderCharts() {
      // Renderizar el gráfico de barras
      if (this.chartBarData) {
        this.getBarChart();
      }

      // Renderizar el gráfico de dona
      if (this.chartDonutData) {
        this.getDonutChart();
      }
    },
    getBarChart() {
      const percentages = {};

      // Recorremos las respuestas para calcular el porcentaje de cada una y contar la cantidad de actividades por porcentaje
      this.replies.forEach((reply) => {
        const percentage = (reply.points / this.TotalPoints) * 100;
        let range;

        if (percentage === 100) {
          range = 100; // Agrupamos el 100% dentro de su propio rango
        } else {
          range = Math.floor(percentage / 10) * 10;
        }

        if (percentages[range]) {
          percentages[range].push(reply);
        } else {
          percentages[range] = [reply];
        }
      });

      // Creamos un array para las etiquetas del gráfico y los datos
      const labels = [];
      const data = [];


      for (let i = 0; i <= 100; i += 10) {
        if (i === 100) {
          labels.push("100%");
          data.push(percentages[i] ? percentages[i].length : 0);
        } else {
          labels.push(`${i}-${i + 10}%`);
          data.push(percentages[i] ? percentages[i].length : 0);
        }
      }

      // Ahora asignamos los datos al gráfico
      this.chartBarData = {
        labels: labels,
        datasets: [
          {
            label: "Notas %", // Título para la leyenda
            backgroundColor: "#f87979",
            data: data, // Usamos la cantidad de actividades como datos
          },
        ],
      };

      this.chartBarOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1, // Pasos de 1 en el eje y
              },
            },
          ],
        },
        onClick: this.handleBarClick,
      };
    },
    getDonutChart() {
        this.chartDonutData = {
          labels: ['Terminado', 'Faltantes'],
          datasets: [
            {
              data: [this.completionStatus.completed.count, this.completionStatus.remaining.count],
              backgroundColor: ["#36A2EB", "#FF6384"],
              borderColor: ["#36A2EB", "#FF6384"],
            },
          ],
        };
        this.chartDonutOptions = {
          maintainAspectRatio: false,
          onClick: this.handleDoughnutClick,
        };
      },
        getStatusClass(item) {
        return new Date(`${item.limit}T23:59:00-03:00`) >= new Date() ? 'blue' : 'red';
        },
      closeDialog() {
        this.$emit('close-dialog');
      },
    handleBarClick(event, array) {
        if (array.length > 0) {
          const index = array[0]._index;
          const rangeLabel = this.chartBarData.labels[index];
          const rangeStart = parseInt(rangeLabel.split("-")[0]);
          const rangeEnd =
            rangeLabel === "100%" ? 100 : parseInt(rangeLabel.split("-")[1]);

          this.modalStudents = this.replies
            .filter((reply) => {
              const percentage = (reply.points / this.TotalPoints) * 100;
              return percentage >= rangeStart && percentage <= rangeEnd;
            })
            .map((reply) => ({
              id: reply.studentId,
              name: reply.student.name + " " + reply.student.lastName,
              pointsPercentage: ((reply.points / this.TotalPoints) * 100).toFixed(
                2
              ),
              points: reply.points,
            }));

          this.modalTitle = `Estudiantes con puntajes entre ${rangeStart}% y ${rangeEnd}%`;
          this.showModal = true;
        }
      },
      handleDoughnutClick(event, array) {
        if (array.length > 0) {
          const index = array[0]._index;
          const label = this.chartDonutData.labels[index];

          let students = [];
          if (label === "Terminado") {
            students = this.mappedCompletionStatus.completed.students;
            this.modalTitle = "Estudiantes que han terminado la actividad";
          } else {
            students = this.mappedCompletionStatus.remaining.students;
            this.modalTitle = "Estudiantes que aún no han terminado la actividad";
          }
          this.modalStudents = students.map((student) => {
            const reply = this.replies.find(
              (reply) => reply.student.id === student.id
            );
            const points = reply ? reply.points : 0;
            const pointsPercentage = ((points / this.TotalPoints) * 100).toFixed(
              2
            );
            return {
              id: student.id,
              name: student.name + " " + student.lastName,
              points,
              pointsPercentage,
            };
          });

          this.showModal = true;
        }
      },
    },
  };
  </script>
