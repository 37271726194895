<template>
  <v-card class="pb-4"  style="border: 0.2px solid #333;" width="90%">
    <v-card-title class="primary white--text">
      Actividades parte de la asignatura
    </v-card-title>

    <v-text-field
      class="mx-4"
      v-model="search"
      append-icon="mdi-magnify"
      label="Buscar Actividad"
      single-line
    ></v-text-field>

    <v-data-table
      height="350px"
      fixed-header
      :search="search"
      :headers="headers"
      :items="activities"
      item-key="id"
      class="m-3 elevation-1"
    >
      <template v-slot:no-data>
        <p>No hay actividades.</p>
      </template>
      <template v-slot:item.text="{ item }">
        {{ item.text.title }}
      </template>
      <template v-slot:item.state="{ item }">
        <v-chip dark :color="getStatusClass(item)" class="text-white">
          {{ new Date(`${item.date}T23:59:00-03:00`) >= new Date() ? 'Activo' : 'Terminado' }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="openActivityData(item)" color="primary" outlined small>
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.editar="{ item }">
        <EditActivity :activity="item" @activity-updated="handleActivityUpdated"></EditActivity>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn @click="confirmDelete(item)" color="red" outlined small>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <ActivityData
      :activity="this.selectedActivity"
      @close-dialog="closeDialogHandler"
      :dialog="isDialogOpen"
      @change="isDialogOpen = $event"
    />
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import ActivityData from './ActivityData.vue';
import EditActivity from './forms/EditActivity.vue';
import axios from 'axios';

export default {
  components: {
    ActivityData,
    EditActivity
  },
  data: () => ({
    color: 'success',
    snackbar: null,
    loading: false,
    search: '',
    headers: [
      {
        text: 'Id',
        align: 'center',
        value: 'id'
      },
      { text: 'Texto', sortable: false, value: 'text' },
      { text: 'Fecha de termino', value: 'date' },
      { text: 'Estado', value: 'state', sortable: false },
      { text: 'Descripcion', value: 'description', cellClass: 'truncate-cell' },
      {
        text: 'Tiempo limite(Minutos)',
        value: 'limitTime',
        align: 'center',
        template: (value) => (value === null ? '?' : value)
      },
      { text: 'Editar', value: 'editar', sortable: false },
      { text: 'Ver datos', value: 'actions', sortable: false },
      { text: 'Eliminar', value: 'delete', sortable: false }
    ],
    selectedActivity: null,
    isDialogOpen: false
  }),

  computed: {
    activities() {
      return this.$store.getters['teacher/getActivitiesInLesson'];
    }
  },

  created() {
    this.getActivities();
  },

  methods: {
    closeDialogHandler() {
      this.isDialogOpen = false;
    },
    async getActivities() {
      let id = this.$route.params.id;
      await this.$store.dispatch('teacher/activitiesInLesson', id);
    },
    async openActivityData(activity) {
      this.selectedActivity = null;
      this.selectedActivity = activity;
      this.isDialogOpen = true;
    },
    getStatusClass(item) {
      console.log( new Date(`${item.date}T23:59:00-03:00`))
      console.log( new Date())

      return new Date(`${item.date}T23:59:00-03:00`) >= new Date() ? 'blue' : 'red';
    },
    handleActivityUpdated() {
      this.getActivities();
    },
    async confirmDelete(activity) {
      const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: `Eliminar la actividad: ${activity.text.title}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        this.deleteActivity(activity.id);
      }
    },
    async deleteActivity(id) {
      try {
        await axios.delete(`/teacher/activity/${id}`);
        Swal.fire('Eliminado!', 'La actividad ha sido eliminada.', 'success');
        this.getActivities(); // Actualizar la lista de actividades después de la eliminación
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrió un error al eliminar la actividad.'
        });
      }
    }
  }
}
</script>