<template>
    <v-dialog v-model="showModal" max-width="1200" persistent>
      <v-card v-if="reply">
        <v-card-title class="primary white--text">
          Respuestas de {{ reply.student.name }} {{ reply.student.lastName }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
  
        
        <!-- Para Interamericana: Mostrar tiempo total -->
        <v-card-text v-if="isInteramericana" class="mt-3 text-h6">
          Tiempo total: {{ formatTotalTime(reply.total_time) }}  
        </v-card-text>
  
        <v-tabs v-model="currentTab" grow>
          <v-tab v-for="(section, index) in sections" :key="index">
            {{ sectionTitle(section) }}
          </v-tab>
        </v-tabs>
  
        <v-tabs-items touchless v-model="currentTab">
          <v-tab-item v-for="(section, index) in sections" :key="index">

            <div class="d-flex justify-center pa-2">
                <span class=" font-weight-bold primary--text">Puntaje obtenido por sección:  {{ sectionScores[section.title] }}/{{ section.questions.length }}</span>
                
            </div>
            <v-card flat>
              <!-- Texto y contenido relacionado -->
              <v-card v-if="section.text" class="ma-4">
                <v-card-title class="primary white--text">
                  {{ section.text.title }}
                </v-card-title>
                <v-card-text>
                  <div   class="paragraph-block text-justify ma-2 mt-4" v-for="(paragraph, pIndex) in section.text.paragraph" :key="pIndex">
                    <div v-if="paragraph.subTitle" class="text-h6 blue--text">
                      {{ paragraph.subTitle }}
                    </div>
                    {{ paragraph.description }}
                    <div v-if="paragraph.id === 108 || paragraph.id === 424" class="image-container">
                      <v-img class="contained-image"  :src="imagen1"></v-img>

                    </div>
                    <div v-if="paragraph.id === 110 || paragraph.id === 426" class="image-container">
                      <v-img class="contained-image" :src="imagen2"></v-img>

                    </div>
                  </div>
                </v-card-text>
              </v-card>
  
              <!-- Preguntas y respuestas -->
              <div v-for="(question, qIndex) in section.questions" :key="question.id" class="ma-4">
                <v-card :class="answerClass(question.id)">
                  <v-card-title :class="answerClass(question.id)">
                    Pregunta {{ qIndex + 1 }}: {{ question.text }}
                    <div v-if="question.id === '2_10'" class="image-container">
                      <v-img  class="contained-image mt-4"  :src="imagen3" ></v-img>
                    </div>
                  </v-card-title>
  
                  <v-card-text>
                    <!-- Afirmaciones si existen -->
                    <ul v-if="question.affirmations" class="my-3">
                      <li v-for="affirmation in question.affirmations" :key="affirmation.id">
                        <strong>{{ affirmation.id }}.</strong> {{ affirmation.text }}
                      </li>
                    </ul>
  
                    <!-- Respuesta del estudiante -->
                    <div class="student-answer">
                      <v-icon :color="answerIconColor(question.id)" left>
                        {{ answerIcon(question.id) }}
                      </v-icon>
                      <strong>Respuesta seleccionada:</strong> 
                      {{ selectedAnswerText(question.id) }}
                      
                      <!-- Tiempo por pregunta para Interamericana -->
                      <span v-if="isInteramericana" class="ml-4">
                        Tiempo: {{ questionTime(question.id) }}
                      </span>
                    </div>
  
                    <!-- Respuesta correcta -->
                    <div class="correct-answer mt-2">
                      <v-icon color="success" left>mdi-check-circle</v-icon>
                      <strong>Respuesta correcta:</strong> 
                      {{ correctAnswerText(question.id) }}
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import imagen1 from "@/assets/images/LectumA_1.png";
  import imagen2 from "@/assets/images/LectumA_2.png";
  import imagen3 from "@/assets/images/LectumA_3.png";
  
  export default {
    props: {
        modalOpen: Boolean,
        reply: Object,  // Este prop contiene todos los datos necesarios
        idActividad: Number,
        diagnosticType: String,
        studentSectionScores: {  // <- Nuevo prop
          type: Object,
          default: () => ({})
        },
        sections:Object
    },
    data() {
        return {
        showModal: false,
        currentTab: 0,
        imagen1,
        imagen2,
        imagen3,
        isLoading: false,
        cachedStructures: null
        };
    },
    computed: {
        sectionScores() {
          return this.studentSectionScores || {};
        },
        isInteramericana() {
        return this.diagnosticType === 'Interamericana';
        },
        filteredSections() {
          return Object.entries(this.sectionScores)
            .filter(([section]) => section !== 'Total')
            .map(([section, score]) => ({ section, score }));
        },
        processedReply() {
          return {
              ...this.reply,
              answer: !this.isInteramericana ? Object.values(JSON.parse(this.reply.answer)) : JSON.parse(this.reply.answer),
              total_time: this.reply.total_time || 0
          };
        }
    },
    watch: {
      modalOpen(val) {
        this.showModal = val;
        if (val && !this.cachedStructures) {
          this.loadStructure();
        } else if (val) {
          this.processAnswers();
        }
      }
    },
    methods: {
        async loadStructure() {
          if (this.isLoading) return;
          this.isLoading = true;
          
          try {
            
            this.cachedStructures = this.sections;
          } finally {
            this.isLoading = false;
          }
        },
        getMaxSectionScore(sectionTitle) {
          const section = this.sections.find(s => this.sectionTitle(s) === sectionTitle);
          return section ? section.questions.length : 0;
        },
        getSectionQuestionsCount(sectionTitle) {
          const section = this.sections.find(s => this.sectionTitle(s) === sectionTitle);
          return section ? section.questions.length : 0;
        },
        closeModal() {
          this.showModal = false;
          this.$emit('closed'); // <- Añadir esto
        },
            processAnswers() {
              if (!this.cachedStructures) return;
              
              this.sections = this.cachedStructures.map(section => ({
                ...section,
                questions: section.questions.map(question => {
                  const answer = this.processedReply.answer.find(a => 
                    a.questionId === question.id
                  );
                  
                  return {
                    ...question,
                    studentAnswer: answer?.answerId,
                    timeSpent: answer?.time || 0,
                    isCorrect: answer?.answerId === question.correctAnswer
                  };
                })
              }));
            },

        // Actualizar métodos para usar datos locales
        answerClass(questionId) {
            const question = this.findQuestion(questionId);
            return question.isCorrect ? 'correct-answer' : 'incorrect-answer';
        },

        selectedAnswerText(questionId) {
            const question = this.findQuestion(questionId);
            return question.options.find(opt => opt.id === question.studentAnswer)?.text || 'Sin respuesta';
        },

        correctAnswerText(questionId) {
            const question = this.findQuestion(questionId);
            return question.options.find(opt => opt.id === question.correctAnswer)?.text;
        }
   ,
      questionTime(questionId) {
        const question = this.findQuestion(questionId);
        return this.formatTime(question.timeSpent);
      },
  
      formatTime(seconds) {
        if (!seconds || seconds < 0) return 'N/A';

        const totalSeconds = Math.floor(seconds); // Redondear correctamente
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;

        return minutes > 0
        ? `${minutes}m${remainingSeconds}s`
        : `${remainingSeconds}s`;
      },
  
      formatTotalTime(seconds) {
        return this.formatTime(seconds);
      },
  
      answerIcon(questionId) {
        return this.answerClass(questionId) === 'correct-answer' 
          ? 'mdi-check-circle' 
          : 'mdi-alert-circle';
      },
  
      answerIconColor(questionId) {
        return this.answerClass(questionId) === 'correct-answer' 
          ? 'success' 
          : 'error';
      },
  
      sectionTitle(section) {
        return this.isInteramericana 
          ? section.title 
          : `Texto: ${section.text?.title}`;
      },
  
      findQuestion(questionId) {
        for (const section of this.sections) {
          const question = section.questions.find(q => q.id === questionId);
          if (question) return question;
        }
        return null;
      }
    }
  };
  </script>
  
