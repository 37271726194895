<template>
  <v-dialog v-model="this.dialog" persistent >
    <v-container fluid>
      <v-card max-height="auto">
        <v-card-title
          class="d-flex align-center justify-space-between primary white--text"
        >
          Actividad {{ this.activity.id }} del Texto {{ this.text.title }}
          <div class="d-flex mr-4">
            <v-chip :color="getStatusClass(this.activity)" class="mt-2 text--white">
              {{
                new Date(`${this.activity.date}T23:59:00-03:00`) >= new Date()
                  ? "Activo"
                  : "Terminado"
              }}
            </v-chip>
            <v-btn class="mt-2 ml-3" color="red" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-row class="d-flex ma-4">
          <v-col cols="12" md="6" class="d-flex flex-column outlined">
            <div class="d-flex mt-1">
              <div class="statistic-card">
                <h2>{{ this.TotalPoints }} pts</h2>
                <h3>Puntaje Total</h3>
              </div>
              <div class="statistic-card">
                <h2>{{ this.PointAverage }} pts</h2>
                <h3>Puntaje promedio</h3>
              </div>
              <div class="statistic-card">
                <h2>
                  {{ formatTime(this.TimeAverage) }}
                </h2>
                <h3>T.Total promedio</h3>
              </div>
              <div class="statistic-card">
                <h2>
                  {{ formatTime(this.timeReadAverage) }}
                </h2>
                <h3>T.Lectura promedio</h3>
              </div>
              <div class="statistic-card">
                <h2>
                  {{ formatTime(this.timeAnswerAverage) }}
                </h2>
                <h3>T.Respuesta promedio</h3>
              </div>
            </div>
            <div class="mt-2">
              <h3 v-if="replies.length > 1" class="ml-2">
                Respuestas: {{ replies.length }} estudiantes contestaron
              </h3>
              <h3 v-if="replies.length === 1" class="ml-2">
                Respuestas: 1 estudiante contesto
              </h3>
              <h3 v-if="replies.length < 1" class="ml-2">
                Respuestas: Ningun estudiante contesto
              </h3>
              <div
                class="scrollable-content d-flex ml-3 mt-1 flex-column border"
              >
                <div  v-for="(data, i) in questions.questions" :key="i">
                  <div style="background-color: rgba(63, 81, 181, 0.8);" class="white--text book-paragraph pa-3">
                    <h3  class="text-justify m-3">
                      .-{{ data.question.title }} ({{ data.question.points }}pts)
                    </h3>
                    <h4 class="text-justify">
                      Tiempo promedio de respuesta
                      {{ formatTime(questionTimeAvarage[data.question.id]) }}
                    </h4>
                    <h4
                      class="ml-3 text-justify"
                      v-html="data.question.description"
                    ></h4>
                  </div>
                  
                  <v-container fluid v-if="data.question.type === 0">
                    <div class="ma-4" color="teal">
                      <v-btn
                        @click="openModal('development', data.question.id, 0)"
                        >Ver respuestas de desarrollo</v-btn
                      >
                    </div>
                  </v-container>
                  <v-container
                    fluid
                    v-if="data.question.type === 1 || data.question.type === 2"
                  >
                    <div class="d-flex flex-column">
                      <div
                        v-for="(alternative, i) in data.question.answer"
                        :key="i"
                      >
                        <div class="d-flex justify-space-between">
                          <div
                            class="d-flex statistic-card"
                            :style="{
                              backgroundColor:
                                alternative.isCorrect === 1
                                  ? '#ccffcc'
                                  : '#f5f5f5',
                            }"
                          >
                            {{ i }})
                            <h4 class="ml-2 text-left">
                              {{ alternative.description }}
                            </h4>
                          </div>
                          <div
                            class="statistic-card justify-center align-content-center"
                            :style="{
                              backgroundColor:
                                alternative.isCorrect === 1
                                  ? '#ccffcc'
                                  : '#f5f5f5',
                            }"
                          >
                            <v-btn
                              @click="
                                openModal(
                                  'alternative',
                                  data.question.id,
                                  alternative.id
                                )
                              "
                            >
                              <h4 class="text-left">
                                {{
                                  getAnswerCount(
                                    data.question.id,
                                    alternative.id
                                  )
                                }}
                                Respuestas
                              </h4>
                            </v-btn>
                          </div>
                          <div
                            class="statistic-card justify-center align-content-center"
                            :style="{
                              backgroundColor:
                                alternative.isCorrect === 1
                                  ? '#ccffcc'
                                  : '#f5f5f5',
                            }"
                          >
                            <v-progress-linear
                              :value="
                                getAnswerPercentage(
                                  data.question.id,
                                  alternative.id
                                )
                              "
                              :color="
                                getColorBarAlternative(alternative.isCorrect)
                              "
                              height="20"
                              rounded
                              striped
                            >
                              {{
                                getAnswerPercentage(
                                  data.question.id,
                                  alternative.id
                                )
                              }}%
                            </v-progress-linear>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-container>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="outlined">
            <div class="d-flex justify-space-between">
              <v-tabs v-model="tab" @change="handleTabChange">
                <v-tab href="#tab-1">Respuestas</v-tab>
                <v-tab href="#tab-2">Puntajes</v-tab>
                <v-tab href="#tab-3">Finalizado</v-tab>
                <div class="ml-5 d-flex justify-center align-center">
                  <EditActivity :activity="activity"></EditActivity>
                </div>
              </v-tabs>
              <info-button
                class="mr-4"
                title="Información sobre los datos de una actividad"
                message="
              <ul>
                <li><strong>Introducción:</strong> Aquí se muestran todos los datos de una actividad. A la izquierda se presentan los datos promedio obtenidos en la actividad y un breve resumen que incluye las preguntas y las respuestas de los estudiantes, mostrando el porcentaje de respuestas en el caso de alternativas. A la derecha, se encuentran tres secciones con las respuestas de los estudiantes, los puntajes obtenidos y las personas que terminaron la actividad.</li>
                <li><strong>Apartado de título:</strong> Se muestra el nombre del texto asociado, el estado de la actividad (activa o terminada) y el botón para salir.</li>
                <li><strong>Respuestas:</strong> En esta sección, puede ver las respuestas completas de los estudiantes, incluyendo tiempos de lectura y respuesta, y las puntuaciones obtenidas.</li>
                <li><strong>Puntajes:</strong> Muestra un gráfico de barras con los puntajes en porcentaje de la actividad. Puede hacer clic en las barras para ver detalles de los estudiantes que obtuvieron esos puntajes.</li>
                <li><strong>Finalizado:</strong> Muestra un gráfico de dona indicando qué estudiantes han completado la actividad y cuáles no. Puede hacer clic en el gráfico para ver los detalles de los estudiantes.</li>
                <li><strong>Edición de Actividad:</strong> Puede editar los detalles de la actividad, como la fecha límite, y añadir o modificar preguntas.</li>
                <li><strong>Exportar a Excel:</strong> Puede descargar un archivo Excel con todos los datos de la actividad, incluyendo respuestas de los estudiantes, tiempos y puntuaciones.</li>
                <li><strong>Aplazamiento de Actividades:</strong> Puede aplazar la fecha límite de la actividad para estudiantes específicos, proporcionando una nueva fecha y una razón para el aplazamiento.</li>
              </ul>
              "
              ></info-button>
            </div>
            <v-tabs-items touchless v-model="tab">
              <v-tab-item value="tab-1">
                <v-card class="flex-grow-1">
                  <v-card-title
                    class="d-flex align-center justify-space-between"
                  >
                    <span>Estudiantes</span>
                    <div class="d-flex align-items-center">
                      <Activity_postponed
                        :activity="activity"
                        :student="student"
                      ></Activity_postponed>
                      <v-btn
                        @click="generateExcelDetailed"
                        class="ml-4"
                        :disabled="replies.length === 0"
                        fab
                        dark
                        small
                        color="success"
                      >
                        <v-icon dark>mdi-microsoft-excel</v-icon>
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-card-actions>
                    <ReplyTable
                      :reply="this.replies"
                      :minigame="minigameinfo"
                      :totalPoints="TotalPoints"
                      :timesR="timeRead"
                      :timesA="timeAnswer"
                    ></ReplyTable>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card>
                  <v-card-title
                    >Puntajes en porcentaje de la actividad</v-card-title
                  >
                  <v-card-actions class="d-flex justify-center align-center">
                    <BarChart
                      v-show="tab === 'tab-2'"
                      :chartBarData="chartBarData"
                      :chartBarOptions="chartBarOptions"
                      @click="handleBarClick"
                    ></BarChart>
                    <div v-if="selectedRange.length > 0">
                      <h3>Detalles de Estudiantes</h3>
                      <ul>
                        <li v-for="student in selectedRange" :key="student.id">
                          {{ student.name }} - {{ student.pointsPercentage }}%
                        </li>
                      </ul>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-card>
                  <v-card-title>Gráfico de Término</v-card-title>
                  <DoughnutChart
                    v-show="tab === 'tab-3'"
                    :chartData="chartDonutData"
                    :options="chartDonutOptions"
                    @click.native="handleDoughnutClick"
                  ></DoughnutChart>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <StudentModal
      :show.sync="showModal"
      :students="modalStudents"
      :title="modalTitle"
    />
    <ModalResponses
      v-if="showAlternativeModal"
      :showModal.sync="showAlternativeModal"
      :questionId="selectedQuestionId"
      :alternativeId="selectedAlternativeId"
      modalType="alternative"
      :replies="replies"
    />
    <ModalResponses
      v-if="showDevelopmentModal"
      :showModal.sync="showDevelopmentModal"
      :questionId="selectedQuestionId"
      modalType="development"
      :replies="replies"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import DoughnutChart from "@/components/UI/DoughnutChart.vue";
import ReplyTable from "./ReplyTable.vue";
import BarChart from "@/components/UI/BarCharts.vue";
import Activity_postponed from "./forms/Activity_postponed.vue";
import * as XLSX from "xlsx";
import ModalResponses from "@/components/UI/ModalResponses.vue";
import EditActivity from "./forms/EditActivity.vue";
import StudentModal from "@/components/UI/StudentModal.vue";
import InfoButton from "@/components/UI/InfoButton.vue";

export default {
  props: ["activity", "dialog"],
  components: {
    DoughnutChart,
    ReplyTable,
    BarChart,
    Activity_postponed,
    ModalResponses,
    EditActivity,
    StudentModal,
    InfoButton,
  },
  model: {
    prop: "dialog",
    event: "change",
  },
  watch: {
    dialog(newValue) {
      this.$emit("change", newValue);
      if (newValue === true) {
        this.loadData();
      }
    },
  },
  data: () => ({
    slowestStudents: {},
    completionStatus: {},
    bestAndWorstPerformingStudents: {},
    TimeAverage: null,
    PointAverage: null,
    TotalPoints: 0,
    questions: {},
    answerCounts: {},
    loading: false,
    loadingbar: false,
    tab: "tab-1",
    chartDonutData: null,
    chartDonutOptions: {
      responsive: true,
    },
    chartBarData: null,
    chartBarOptions: {
      responsive: true,
    },
    selectedRange: [],
    replies: [],
    text: {},
    questionTimeAvarage: [],
    student: {},
    timeReadAverage: [],
    timeAnswerAverage: [],
    timeRead: [],
    timeAnswer: [],
    selectedAlternative: null,
    selectedQuestionId: null,
    showAlternativeModal: false,
    showDevelopmentModal: false,
    minigameinfo: null,
    modalStudents: null,
    modalTitle: "",
    showModal: false,
  }),

  computed: {
    mappedCompletionStatus() {
      const completedStudents = this.completionStatus.completed.students.map(
        (student) => {
          const reply = this.replies.find(
            (reply) => reply.student_id === student.id
          );
          const points = reply ? reply.points : 0;
          const pointsPercentage = ((points / this.TotalPoints) * 100).toFixed(
            2
          );
          return {
            ...student,
            points,
            pointsPercentage,
          };
        }
      );

      const remainingStudents = this.completionStatus.remaining.students.map(
        (student) => {
          const reply = this.replies.find(
            (reply) => reply.student_id === student.id
          );
          const points = reply ? reply.points : 0;
          const pointsPercentage = ((points / this.TotalPoints) * 100).toFixed(
            2
          );
          return {
            ...student,
            points,
            pointsPercentage,
          };
        }
      );

      return {
        completed: {
          count: this.completionStatus.completed.count,
          students: completedStudents,
        },
        remaining: {
          count: this.completionStatus.remaining.count,
          students: remainingStudents,
        },
      };
    },
  },

  methods: {
    async loadData() {
      this.slowestStudents = {};
      this.completionStatus = {};
      this.bestAndWorstPerformingStudents = {};
      this.TimeAverage = null;
      this.PointAverage = null;
      this.TotalPoints = 0;
      this.questions = {};
      this.answerCounts = {};
      this.loading = false;
      this.loadingbar = false;
      this.chartDonutData = null;
      this.chartBarData = null;
      this.tab = "tab-1";
      await Promise.all([
        this.getReply(),
        this.getText(),
        this.getActivityCompletionStatus(),
        this.getActivityQuestions(),
      ]);
    },
    getColorBarAlternative(isCorrect) {
      if (isCorrect === 0) {
        return "red";
      } else {
        return "blue";
      }
    },
    async handleTabChange(tab) {
      // Desactivar la visibilidad de los gráficos mientras se cargan los datos
      this.chartBarData = null;
      this.chartDonutData = null;

      // Cargar los datos según la pestaña seleccionada
      if (tab === "tab-2") {
        await this.getBarChart();
      }
      if (tab === "tab-3") {
        await this.getDonutChart();
      }

      // Renderizar los gráficos después de cargar los datos
      this.renderCharts();
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = (seconds % 60).toFixed(1);
      return minutes > 0
        ? `${minutes}m${remainingSeconds}s`
        : `${remainingSeconds}s`;
    },
    renderCharts() {
      // Renderizar el gráfico de barras
      if (this.chartBarData) {
        this.getBarChart();
      }

      // Renderizar el gráfico de dona
      if (this.chartDonutData) {
        this.getDonutChart();
      }
    },
    getStatusClass(item) {
      return new Date(`${item.date}T23:59:00-03:00`) >= new Date() ? 'blue' : 'red';
    },
    getIcon(alternative) {
      if (alternative.isCorrect === 1) {
        return "mdi-check-circle";
      }
      if (alternative.isCorrect === 0) {
        return "mdi-close-circle";
      }
    },
    getTimeforQuestion(timeAnswer, questionId) {
      // Convertir la cadena JSON a un array de objetos
      let answerArray;
      try {
        answerArray = JSON.parse(timeAnswer);
      } catch (error) {
        return "Error al analizar la respuesta JSON";
      }

      const reply = answerArray.find((reply) => reply.id === questionId);

      // Si no se encontró una respuesta para la pregunta, devolver un valor predeterminado o null
      if (!reply) {
        return "No se encontró tiempo para esta pregunta";
      }

      // Devolver el tiempo
      return reply.time;
    },
    getAnswerforQuestion(answer, questionId) {
      // Verificar si answer es una cadena JSON
      if (typeof answer !== "string") {
        return "No se encontró respuesta para esta pregunta";
      }

      // Convertir la cadena JSON a un array de objetos
      let answerArray;
      try {
        answerArray = JSON.parse(answer);
      } catch (error) {
        return "Error al analizar la respuesta JSON";
      }

      // Filtrar las respuestas del estudiante para encontrar la respuesta a la pregunta específica
      const reply = answerArray.find(
        (reply) => reply.idQuestion === questionId
      );

      // Si no se encontró una respuesta para la pregunta, devolver un valor predeterminado o null
      if (!reply) {
        return "No se encontró respuesta para esta pregunta";
      }

      // Si la pregunta es de desarrollo (type === 0), devolver la respuesta directamente
      if (reply.type === 0) {
        return reply.answer;
      }

      // Si la pregunta es de selección múltiple type === 1, devolver la descripción de la alternativa seleccionada
      if (reply.type === 1) {
        const selectedAlternative = reply.answer.description;
        return selectedAlternative;
      } else {
        const alternatives = reply.answer
          .map((alternative) => alternative.description)
          .join(", ");
        return alternatives;
      }
    },
    getTimeAverageQuestion() {
      // Objeto para almacenar sumas y recuentos de tiempos para cada pregunta
      let questionSumTimes = {};

      // Iterar sobre todas las respuestas y agregar los tiempos a las sumas correspondientes
      let answerArray;

      this.replies.forEach((aux) => {
        answerArray = JSON.parse(aux.timeAnswer);

        answerArray.forEach((response) => {
          if (!(response.id in questionSumTimes)) {
            questionSumTimes[response.id] = 0;
          }
          questionSumTimes[response.id] += response.time;
        });
      });

      // Calcular el tiempo promedio para cada pregunta
      let averageTimes = {};
      for (let questionId in questionSumTimes) {
        const average =
          this.replies.length !== 0
            ? questionSumTimes[questionId] / this.replies.length
            : 0;
        averageTimes[questionId] = parseFloat(average.toFixed(3));
      }

      this.questionTimeAvarage = averageTimes;
    },
    async getActivityCompletionStatus() {
      await axios
        .get("teacher/activity/getActivityCompletionStatus/" + this.activity.id)
        .then((response) => {
          this.completionStatus = response.data;

          const allStudents = response.data.remaining.students;

          // Rellenar el array de estudiantes
          this.student = allStudents.map((student) => ({
            id: student.id,
            name: student.name,
            lastName: student.lastName,
            secondLastName: student.secondLastName,
          }));

          this.renderCharts();
        });
    },
    getDonutChart() {
      this.chartDonutData = {
        labels: ["Terminado", "Faltantes"],
        datasets: [
          {
            data: [
              this.completionStatus.completed.count,
              this.completionStatus.remaining.count,
            ],
            backgroundColor: ["#36A2EB", "#FF6384"],
            borderColor: ["#36A2EB", "#FF6384"],
          },
        ],
      };
      this.chartDonutOptions = {
        maintainAspectRatio: false,
        onClick: this.handleDoughnutClick,
      };
    },
    generateExcelDetailed() {
      const wb = XLSX.utils.book_new();
      if (!this.activity) {
        console.error("Activity data is not defined.");
        return;
      }

      var ws = XLSX.utils.aoa_to_sheet([[]]);

      // Establecer anchos de columna
      ws["!cols"] = [
        { wch: 20 }, // A
        { wch: 20 }, // B
        { wch: 20 }, // C
        { wch: 20 }, // D
        { wch: 20 }, // E
        { wch: 20 }, // F
        { wch: 20 }, // G
      ];

      const boldFontStyle = {
        font: { bold: true, size: 14 }, // Establecer negrita y tamaño de fuente
        alignment: { horizontal: "center" }, // Alinear texto al centro
      };
      XLSX.utils.sheet_add_aoa(ws, [["Texto: ", this.text.title]], {
        origin: "B3",
        style: boldFontStyle,
      });

      // Hoja con los detalles de la actividad
      const activitySheet = [
        ["Descripcion :", this.activity.description],
        ["Fecha De termino :", this.activity.date],
      ];
      // Hoja con los puntos totales y el promedio de puntos
      const pointsSheet = [
        ["Puntos Totales :", this.TotalPoints],
        ["Promedio de Puntos :", this.PointAverage],
      ];

      XLSX.utils.sheet_add_aoa(ws, activitySheet, { origin: "A5" });
      XLSX.utils.sheet_add_aoa(ws, pointsSheet, { origin: "D5" });

      var currentRow = 8; // Iniciar en la fila 7 para las preguntas
      this.questions.questions.forEach((question) => {
        const questionSheet = [
          ["Pregunta:", question.question.title],
          [
            "Puntos:",
            question.question.points,
            "Tiempo promedio de respuesta",
            this.questionTimeAvarage[question.question.id] + "seg",
          ],
        ];

        // Agregar la matriz de datos a la hoja de cálculo comenzando desde la fila actual
        XLSX.utils.sheet_add_aoa(ws, questionSheet, {
          origin: "A" + currentRow,
        });
        currentRow += 2; // Incrementar la fila para la siguiente sección

        if (question.question.type === 1 || question.question.type === 2) {
          const alternativesSheet = [["Alternativas"]];
          question.question.answer.forEach((alternative, index) => {
            alternativesSheet.push([
              alternative.isCorrect === 1 ? "Correcta" : "Incorrecta",
              index + 1 + ") " + alternative.description,
            ]);
          });

          // Agregar la matriz de datos a la hoja de cálculo comenzando desde la fila actual
          XLSX.utils.sheet_add_aoa(ws, alternativesSheet, {
            origin: "A" + currentRow,
          });
          currentRow += alternativesSheet.length; // Incrementar la fila para la siguiente sección
        }

        const studentData = [];

        // Iterar sobre las respuestas de los estudiantes
        this.replies.forEach((reply) => {
          // Crear un objeto para almacenar los datos del estudiante
          const studentObj = {
            Estudiante: reply.student.name + " " + reply.student.lastName,
            Puntos: this.getPointforQuestion(
              reply.answer,
              question.question.id
            ),
            Tiempo:
              this.getTimeforQuestion(reply.timeAnswer, question.question.id) +
              "seg",
            Respuesta: this.getAnswerforQuestion(
              reply.answer,
              question.question.id
            ),
          };
          // Agregar el objeto al array de datos del estudiante
          studentData.push(studentObj);
        });

        // Agregar el array de objetos al final del proceso
        XLSX.utils.sheet_add_json(ws, studentData, {
          origin: "A" + currentRow,
          header: ["Estudiante", "Puntos", "Tiempo", "Respuesta"],
          style: {
            header: {
              fill: { fgColor: { rgb: "0000FF" } },
              font: { color: { rgb: "FFFFFF" }, bold: true },
            },
          },
        });
        currentRow += studentData.length + 1;
      });

      XLSX.utils.book_append_sheet(wb, ws, "Actividad");

      var wp = XLSX.utils.aoa_to_sheet([[]]);

      // Establecer anchos de columna para la hoja de resumen
      wp["!cols"] = [
        { wch: 30 }, // A
        { wch: 20 }, // B
        { wch: 20 }, // C
        { wch: 15 }, // D
        { wch: 20 }, // E
        { wch: 25 }, // F
        { wch: 25 }, // G
      ];

      const studentDataResumen = [];

      this.replies.forEach((reply) => {
        // Crear un objeto para almacenar los datos del estudiante
        const studentObj = {
          Estudiante: reply.student.name + " " + reply.student.lastName,
          "Puntos Obtenidos pts": reply.points,
          "Puntos Totales pts": this.TotalPoints,
          "Nota %": this.getActivityPercentage(reply.points),
          "Tiempo Total seg": Number(reply.time),
          "Tiempo Respuesta seg": this.getTimeByIdStudent(
            this.timeAnswer,
            reply.studentId
          ),
          "Tiempo Lectura seg": this.getTimeByIdStudent(
            this.timeRead,
            reply.studentId
          ),
        };
        // Agregar el objeto al array de datos del estudiante
        studentDataResumen.push(studentObj);
      });

      XLSX.utils.sheet_add_json(wp, studentDataResumen);

      XLSX.utils.book_append_sheet(wb, wp, "Resumen");

      XLSX.writeFile(wb, "detalles_actividad.xlsx");
    },
    getBarChart() {
      const percentages = {};

      // Recorremos las respuestas para calcular el porcentaje de cada una y contar la cantidad de actividades por porcentaje
      this.replies.forEach((reply) => {
        const percentage = (reply.points / this.TotalPoints) * 100;
        let range;

        if (percentage === 100) {
          range = 100; // Agrupamos el 100% dentro de su propio rango
        } else {
          range = Math.floor(percentage / 10) * 10;
        }

        if (percentages[range]) {
          percentages[range].push(reply);
        } else {
          percentages[range] = [reply];
        }
      });

      // Creamos un array para las etiquetas del gráfico y los datos
      const labels = [];
      const data = [];

      for (let i = 0; i <= 100; i += 10) {
        if (i === 100) {
          labels.push("100%");
          data.push(percentages[i] ? percentages[i].length : 0);
        } else {
          labels.push(`${i}-${i + 10}%`);
          data.push(percentages[i] ? percentages[i].length : 0);
        }
      }

      // Ahora asignamos los datos al gráfico
      this.chartBarData = {
        labels: labels,
        datasets: [
          {
            label: "Notas %", // Título para la leyenda
            backgroundColor: "#f87979",
            data: data, // Usamos la cantidad de actividades como datos
          },
        ],
      };

      this.chartBarOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1, // Pasos de 1 en el eje y
              },
            },
          ],
        },
        onClick: this.handleBarClick,
      };
    },

    handleBarClick(event, array) {
      if (array.length > 0) {
        const index = array[0]._index;
        const rangeLabel = this.chartBarData.labels[index];
        const rangeStart = parseInt(rangeLabel.split("-")[0]);
        const rangeEnd =
          rangeLabel === "100%" ? 100 : parseInt(rangeLabel.split("-")[1]);

        this.modalStudents = this.replies
          .filter((reply) => {
            const percentage = (reply.points / this.TotalPoints) * 100;
            return percentage >= rangeStart && percentage <= rangeEnd;
          })
          .map((reply) => ({
            id: reply.studentId,
            name: reply.student.name + " " + reply.student.lastName,
            pointsPercentage: ((reply.points / this.TotalPoints) * 100).toFixed(
              2
            ),
            points: reply.points,
          }));

        this.modalTitle = `Estudiantes con puntajes entre ${rangeStart}% y ${rangeEnd}%`;
        this.showModal = true;
      }
    },
    handleDoughnutClick(event, array) {
      if (array.length > 0) {
        const index = array[0]._index;
        const label = this.chartDonutData.labels[index];

        let students = [];
        if (label === "Terminado") {
          students = this.mappedCompletionStatus.completed.students;
          this.modalTitle = "Estudiantes que han terminado la actividad";
        } else {
          students = this.mappedCompletionStatus.remaining.students;
          this.modalTitle = "Estudiantes que aún no han terminado la actividad";
        }
        this.modalStudents = students.map((student) => {
          const reply = this.replies.find(
            (reply) => reply.studentId === student.id
          );
          const points = reply ? reply.points : 0;
          const pointsPercentage = ((points / this.TotalPoints) * 100).toFixed(
            2
          );
          return {
            id: student.id,
            name: student.name + " " + student.lastName,
            points,
            pointsPercentage,
          };
        });

        this.showModal = true;
      }
    },
    getActivityPercentage(points) {
      return (points / this.TotalPoints) * 100;
    },
    getPointforQuestion(answer, questionId) {
      // Parsear la respuesta del estudiante
      const answers = JSON.parse(answer);

      // Buscar la respuesta para la pregunta específica
      const studentAnswer = answers.find(
        (answer) => answer.idQuestion === questionId
      );

      if (!studentAnswer) {
        // Si el estudiante no ha respondido esta pregunta, devolver null o ?
        return null;
      }

      // Dependiendo del tipo de pregunta
      switch (studentAnswer.type) {
        case 0: {
          // Pregunta de desarrollo
          return "N/A";
        }
        case 1: {
          // Pregunta de alternativa
          return studentAnswer.answer.isCorrect === 1
            ? studentAnswer.points
            : 0;
        }
        case 2: {
          const allCorrect = studentAnswer.answer.every(
            (a) => a.isCorrect === 1
          );
          return allCorrect ? studentAnswer.points : 0;
        }
        default: {
          return 0;
        }
      }
    },
    async getReply() {
      await axios
        .get("teacher/activity/getReplyForActivity/" + this.activity.id)
        .then((response) => {
          this.replies = response.data.replies;
          this.answerCounts = this.countAnswers();

          // Array para almacenar los tiempos de lectura y de respuesta de cada estudiante
          let timeRead = [];
          let timeAnswer = [];

          response.data.replies.forEach((r) => {
            try {
              const timeAnswers = JSON.parse(r.timeAnswer);
              let totalAnswerTime = 0;

              timeAnswers.forEach((reply) => {
                totalAnswerTime += reply.time;
              });

              // Calcular el promedio de tiempo de respuesta y tiempo de lectura
              let totalReadTime = r.time - totalAnswerTime;
              const answer = totalAnswerTime;
              const read = totalReadTime;

              // Agregar los tiempos al array de tiempo de lectura y de respuesta
              timeRead.push({ idStudent: r.studentId, time: read });
              timeAnswer.push({ idStudent: r.studentId, time: answer });
            } catch (e) {
              console.error("Error parsing JSON:", e);
            }
          });

          // Calcular el promedio total de tiempo de respuesta y tiempo de lectura
          const timeReadAverage =
            timeRead.reduce((acc, cur) => acc + cur.time, 0) /
            response.data.replies.length;
          const timeAnswerAverage =
            timeAnswer.reduce((acc, cur) => acc + cur.time, 0) /
            response.data.replies.length;

          // Asignar los resultados a las variables correspondientes
          this.timeReadAverage = timeReadAverage.toFixed(1);
          this.timeAnswerAverage = timeAnswerAverage.toFixed(1);
          this.timeRead = timeRead;
          this.timeAnswer = timeAnswer;
          this.minigameinfo = this.activity.minigame;
          this.getActivityAverage();
          this.getTimeAverageQuestion();
        })
        .catch((error) => {
          console.error("Error fetching replies:", error);
        });
    },
    getTimeByIdStudent(timeArray, idStudent) {
      // Buscar el objeto en timeArray que tenga el idStudent coincidente
      const foundItem = timeArray.find((item) => item.idStudent === idStudent);

      // Devolver el tiempo si se encuentra, de lo contrario devolver null
      return foundItem ? foundItem.time : null;
    },
    async getText() {
      await axios
        .get("teacher/activity/getTextForActivity/" + this.activity.id)
        .then((response) => {
          this.text = response.data.text;
        });
    },
    getAnswerCount(questionId, alternativeId) {
      if (
        this.answerCounts[questionId] &&
        this.answerCounts[questionId][alternativeId]
      ) {
        return this.answerCounts[questionId][alternativeId];
      }
      return 0;
    },
    getAnswerPercentage(questionId, alternativeId) {
      const totalReplies = this.replies.length;
      const count = this.getAnswerCount(questionId, alternativeId);
      if (totalReplies > 0 && count > 0) {
        if (
          this.questions.questions.find((q) => q.question.id === questionId)
            .question.type === 0
        ) {
          return 0;
        }
        return ((count / totalReplies) * 100).toFixed(2); // Formateamos a dos decimales
      }
      return "0.00";
    },
    countAnswers() {
      let questionAnswerCount = {};

      // Iteramos sobre cada respuesta de cada estudiante
      this.replies.forEach((reply) => {
        // Parseamos el JSON de las respuestas
        let answers = JSON.parse(reply.answer);

        // Iteramos sobre cada respuesta individual de cada pregunta
        answers.forEach((answer) => {
          const questionId = answer.idQuestion;
          let answerIds = [];

          // Verificamos si la respuesta es un array (para múltiples respuestas) o un objeto (para una sola respuesta)
          if (Array.isArray(answer.answer)) {
            // Extraemos todos los IDs de las respuestas para preguntas con múltiples opciones
            answerIds = answer.answer.map((a) => a.id);
          } else if (
            typeof answer.answer === "object" &&
            answer.answer !== null &&
            answer.answer.id
          ) {
            // Extraemos el ID de la respuesta si es un objeto (única opción)
            answerIds = [answer.answer.id];
          }

          // Aumentamos el contador para cada ID de respuesta encontrada
          answerIds.forEach((answerId) => {
            if (!questionAnswerCount[questionId]) {
              questionAnswerCount[questionId] = {};
            }
            if (!questionAnswerCount[questionId][answerId]) {
              questionAnswerCount[questionId][answerId] = 0;
            }
            questionAnswerCount[questionId][answerId]++;
          });
        });
      });

      return questionAnswerCount;
    },
    getActivityAverage() {
      let sumPoint = 0;
      let sumTime = 0;
      this.replies.forEach((reply) => {
        sumPoint += reply.points;
        sumTime += parseFloat(reply.time);
      });
      if (this.replies.length > 0) {
        this.PointAverage = (sumPoint / this.replies.length).toFixed(1);
        this.TimeAverage = (sumTime / this.replies.length).toFixed(1);
      } else {
        this.PointAverage = 0;
        this.TimeAverage = 0;
      }
    },
    async getActivityQuestions() {
      await axios
        .get("teacher/activity/getQuestionForActivity/" + this.activity.id)
        .then((response) => {
          this.questions = response.data;
          let Total = 0;
          response.data.questions.forEach((question) => {
            Total += question.question.points;
          });
          this.TotalPoints = Total;
        });
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    openModal(type, questionId, alternativeId) {
      this.selectedAlternativeId = alternativeId;
      this.selectedQuestionId = questionId;
      if (type === "alternative") {
        this.showAlternativeModal = true;
      } else if (type === "development") {
        this.showDevelopmentModal = true;
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/main.scss"; // Asegúrate de que esta ruta sea correcta.
</style>