<template>
  <div>
    <!-- Tabs -->
    <v-tabs v-model="currentTab" grow color="deep-purple" slider-color="purple">
      <v-tab v-for="(section, index) in sections" :key="index" >
        <v-icon left>mdi-book-open</v-icon>
        Texto {{ index + 1 }}
      </v-tab>
    </v-tabs>
          
    <!-- Timer flotante -->
    <v-btn class="v-btn--floating-timer" fab dark color="indigo">
        <v-icon>mdi-timer</v-icon>
        <span>{{ formattedTime }}</span>
      </v-btn>
    <!-- Tab Items -->
    <v-tabs-items touchless v-model="currentTab">
      <v-tab-item v-for="(section, index) in sections" :key="index" >
        <div v-show="index === currentTab">
          <!-- Texto -->
          <div class="mb-3">
            <v-card class="mt-4 pa-4">
              <v-card-title class="d-flex justify-center align-center">
                <v-icon large color="primary" class="mr-2">mdi-text</v-icon>
                <p class="text-title"><strong>{{ section.text.title }}</strong></p>
              </v-card-title>
              <v-card-subtitle>
                <p>{{ section.text.author }}</p>
                <p>{{ section.text.publicationYear }}</p>
              </v-card-subtitle>
              <!-- Controles de Tamaño e Interlineado --> 
              <div class="d-flex justify-space-between align-center">
                <v-select
                  v-model="fontSize"
                  :items="fontSizes"
                  label="Tamaño del texto"
                  class="ma-2"
                  dense
                  outlined
                ></v-select>
                <v-select
                  v-model="lineHeight"
                  :items="lineHeights"
                  label="Interlineado"
                  class="ma-2"
                  dense
                  outlined
                ></v-select>
              </div>

              <!-- Párrafos -->
              <v-card-text :style="{ fontSize: fontSize + 'px', lineHeight: lineHeight }">
                <div v-for="(paragraph, index) in section.text.paragraph" :key="index" class="book-paragraph">
                  <div v-if="paragraph.subTitle" :key="index" class="book-paragraph text-h5" style="color: #00bfff;">
                    {{ paragraph.subTitle }}
                  </div>
                  {{ paragraph.description }}
                  <div  v-if="paragraph.id === 108" class="image-container" style="max-width: 600px;">
                    <v-img class="contained-image"   :src="imagen1"></v-img>
                  </div>
                  <div  v-if="paragraph.id === 110"  class="image-container" style="max-width: 700px;">
                    <v-img class="contained-image"  :src="imagen2"></v-img>
                  </div>
                  <div   v-if="paragraph.id === 424" class="image-container" style="max-width: 600px;">
                    <v-img class="contained-image" :src="imagen1"></v-img>
                  </div>
                  <div v-if="paragraph.id === 426"  class="image-container" style="max-width: 700px;">
                    <v-img class="contained-image"  :src="imagen2"></v-img>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div>
            
            <v-card-title class="d-flex justify-center align-center">
              <p > Responda todas las preguntas con respecto al texto leído.</p>
            </v-card-title>

            <div v-for="(question, questionIndex) in section.questions" :key="question.id" class="pa-4">
              <v-card class="mt-2">
                <v-form>
                  <v-card-title class="white--text book-paragraph primary"> 
                    
                    <p><v-icon left color="white">mdi-comment-question</v-icon> {{ `Pregunta ${questionIndex + 1}: ${question.text}` }}</p>
                    <div  class="image-container" v-if="question.id ==='2_10'" >
                        <v-img class="contained-image" style="max-width: 700px;" :src="imagen3"></v-img>
                    </div>
                      
                    <p v-if="question.id ==='2_10'">De acuerdo con el gráfico y la información que proporciona el texto, ¿cuál(es) de los
                      enunciados que siguen describe(n) de manera correcta los resultados obtenidos en el
                      ensayo?</p>
                      <v-spacer></v-spacer>
                      <v-chip :color="getQuestionStatusColor(question.id)"
                              dark>
                        {{ getQuestionStatus(question.id) }}
                      </v-chip>
                  </v-card-title>
                  
                  <v-container class="pa-4" fluid>
                  <!-- Verificar si la pregunta tiene afirmaciones múltiples -->
                    <div class="mt-3" v-if="question.affirmations">
                      <ul>
                        <li class="book-paragraph" v-for="(affirmation) in question.affirmations" :key="affirmation.id">
                          <strong>{{ affirmation.id }}.</strong> {{ affirmation.text }}
                        </li>
                      </ul>
                    </div>
                    <v-radio-group v-model="studentReply[question.id].answerId" 
                      @change="handleAnswerChange(question.id)"
                    >
                      <v-radio
                        v-for="option in question.options"
                        :key="option.id"
                        :label="option.text"
                        :value="option.id"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-form>
              </v-card>
            </div>

            <!-- Botón para terminar sección -->
            <v-card-actions class="d-flex justify-center">
              <v-btn  v-if="index < sections.length - 1" 
                      @click="confirmFinishSection" 
                      class="white--text custom-btn" 
                      color="deep-purple accent-4" 
                      :disabled="hasUnansweredQuestions(currentTab)">
                Terminar Sección
              </v-btn>
              <v-btn v-else @click="confirmFinishDiagnostic" 
                    class="white--text custom-btn" 
                    color="deep-purple accent-4" 
                    :disabled="hasUnansweredQuestions(currentTab)">
                Terminar Diagnóstico
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import imagen1 from "@/assets/images/LectumA_1.png";
import imagen2 from "@/assets/images/LectumA_2.png";
import imagen3 from "@/assets/images/LectumA_3.png";
export default {
  computed : {
    formattedTime() {
      const minutes = Math.floor(this.timeRemaining / 60);
      console.log(this.timeRemaining)
      const seconds = this.timeRemaining % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  },
  data() {
    return {
      currentTab: 0,
      studentReply: {},
      finishReading: false,
      sections: [],
      fontSize: 16,
      imagen1: imagen1,
      imagen2: imagen2,
      imagen3: imagen3,
      startTime: null,
      timeRemaining: 90 * 60, // 90 min * 60 seg/min
      timerInterval: null,
      lineHeight: 1.5,
      fontSizes: [12, 14, 16, 18, 20],
      lineHeights: [1.2, 1.5, 1.8, 2],
      points: 0,
      questionsBySection: {},
    };
  },
  methods: {
    confirmFinishDiagnostic() {
      Swal.fire({
        title: '¿Estás seguro de terminar el diagnóstico?',
        text: "No podrás revisar las preguntas después.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, terminar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.timeExpired();
        }
      });
    },
    confirmFinishSection() {
      Swal.fire({
        title: '¿Deseas terminar esta sección?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.nextSection();
        }
      });
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
          this.saveToLocalStorage();
        } else {
          clearInterval(this.timerInterval);
          this.timeExpired();
        }
      }, 1000);
    },
    timeExpired() {
      // Agrupar respuestas incorrectas por Texto
      const incorrectBySection = {};
  
      this.sections.forEach(section => {
        const incorrect = section.questions
          .filter(question => {
            const reply = this.studentReply[question.id];
            return reply && reply.answerId !== question.correctAnswer;
          })
          .map(question => ({ question: question.text }));

        incorrectBySection[section.text.title] = {
          incorrect: incorrect,
          totalQuestions: section.questions.length,
          correct: section.questions.length - incorrect.length
        };
      });

      // Mostrar las respuestas incorrectas al usuario

      Swal.fire({
        title: this.timeRemaining > 0 ? "Finalizado - Resultados Finales " : "Tiempo agotado - Resultados Finales ",
        html: this.formatIncorrectAnswers(incorrectBySection),
        icon: "info",
        confirmButtonText: "Aceptar",
        allowOutsideClick: false, // Evita cerrar haciendo clic fuera
        allowEscapeKey: false ,
        width: '70%',
        height: '60vh',
        customClass: {
          popup: 'swal-custom-popup'
        }
      }).then(() => {
        this.submit(true); // Enviar con bandera para omitir confirmación
      });
    },

    formatIncorrectAnswers(incorrectBySection) {
      let html = '<div style=" max-height: 500px; margin: 0 auto; overflow-y: auto; padding-right: 8px;">';
      let totalIncorrect = 0;
      let totalCorrect = 0;
      let totalQuestions = 0;

      Object.entries(incorrectBySection).forEach(([sectionName, sectionData]) => {
        totalIncorrect += sectionData.incorrect.length;
        totalCorrect += sectionData.correct;
        totalQuestions += sectionData.totalQuestions;

        html += `
          <div style="margin-bottom: 1.5rem;">
            <!-- Encabezado con estadísticas -->
            <div style="background: #3f51b5; color: white; padding: 12px; border-radius: 4px; 
                  display: flex; justify-content: space-between; align-items: center;">
              <div style="display: flex; align-items: center; gap: 8px;">
                <h3 style="margin: 0; font-size: 1.1rem;">${sectionName}</h3>
                <span style="font-size: 0.9em; opacity: 0.8;">▼</span>
              </div>
              <div style="display: flex; gap: 12px;">
                <div style="background: #4CAF50; padding: 4px 12px; border-radius: 4px; 
                      display: flex; align-items: center; gap: 4px;">
                  <span>${sectionData.correct}</span>
                  <span style="font-size: 0.9em;">✓</span>
                </div>
                <div style="background: #ff5252; padding: 4px 12px; border-radius: 4px;
                      display: flex; align-items: center; gap: 4px;">
                  <span>${sectionData.incorrect.length}</span>
                  <span style="font-size: 0.9em;">✗</span>
                </div>
              </div>
            </div>

            <!-- Preguntas incorrectas -->
            <div style="margin-top: 8px; border-left: 3px solid #eee; padding-left: 12px;">
              <div style="color: #ff5252; font-weight: 500; font-size: 0.9em; margin-bottom: 8px;">
                Se muestran las preguntas respondidas incorrectamente:
              </div>
              ${sectionData.incorrect.map((item, index) => `
                <div style="background: #f8f9fa; padding: 12px; margin-bottom: 8px; border-radius: 4px;
                      box-shadow: 0 2px 4px rgba(0,0,0,0.05);">
  
                  <div class="book-paragraph" style="color: #000000; font-size: 0.95em; margin-top: 4px;">
                    Pregunta ${index + 1} : ${item.question}
                  </div>
                </div>
              `).join('')}
            </div>
          </div>
        `;
      });

      // Resumen final
      html += `
        <div style="background: #f8f9fa; padding: 1rem; margin-top: 1.5rem; 
              border-radius: 4px; box-shadow: 0 2px 4px rgba(0,0,0,0.05);">
          <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 1rem; text-align: center;">
            <div>
              <div style="font-size: 1.2rem; color: #4CAF50; font-weight: bold;">${totalCorrect}</div>
              <div style="font-size: 0.9em; color: #666;">Correctas totales</div>
            </div>
            <div>
              <div style="font-size: 1.2rem; color: #ff5252; font-weight: bold;">${totalIncorrect}</div>
              <div style="font-size: 0.9em; color: #666;">Incorrectas totales</div>
            </div>
          </div>
          <div style="text-align: center; margin-top: 1rem; color: #444; font-size: 0.95em;">
            Puntaje final: ${totalCorrect}/${totalQuestions} 
            <span style="color: #999;">(${Math.round((totalCorrect/totalQuestions)*100)}%)</span>
          </div>
        </div>
      `;

      return html + '</div>';
    },
    stopTimer() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
    },
    getQuestionStatus(questionId) {
      return this.studentReply[questionId]?.answerId ? 'Respondida' : 'Pendiente'
    },
    getQuestionStatusColor(questionId) {
      return this.studentReply[questionId]?.answerId ? 'green' : 'orange'
    },
    clearLocalStorage() {
      localStorage.removeItem(`pollActivity_Lectum_${this.$route.params.id}`);
      this.studentReply = {}; // Opcional: Resetea también el objeto studentReply en Vue
    },
    handleAnswerChange(questionID) {
      // Verificar si ya existe una entrada para esta pregunta en studentReply y actualizamos su valor
      if (!this.studentReply[questionID]) {
        this.$set(this.studentReply, questionID, { 
          questionId: questionID, answerId: null 
        });
      }
      
      // Guardar los cambios en localStorage
      this.saveToLocalStorage();
    }, 
    hasUnansweredQuestions(currentTab) {

      if (currentTab < 0 || currentTab >= this.sections.length) {
        return true; // Devuelve true porque no hay sección válida
      }

      // Obtén las preguntas de la sección actual
      const questions = this.sections[currentTab]?.questions || []; // Usa el operador de encadenamiento opcional

      // Verifica si alguna pregunta no ha sido respondida
      return questions.some(question => {
        // Verifica si la respuesta a la pregunta es undefined o nula
        return !this.studentReply[question.id] || !this.studentReply[question.id].answerId;
      });
    },
    saveToLocalStorage() {
      const data = {
        studentReply: this.studentReply,
        currentTab: this.currentTab,
        time: this.timeRemaining
      };
      localStorage.setItem(`pollActivity_Lectum_${this.$route.params.id}`, JSON.stringify(data));
    },
    loadFromLocalStorage() {
      const data = localStorage.getItem(`pollActivity_Lectum_${this.$route.params.id}`);
      if (data) {
        const parsedData = JSON.parse(data);
        this.studentReply = parsedData.studentReply || {};
        this.currentTab = parsedData.currentTab || 0;
        this.timeRemaining = parsedData.time || 0;
      }
    },
    async submit(fromTimeExpired = false)  {
      this.showError = {};
      let hasError = false;
      // Validar que todas las preguntas tengan respuesta
      this.sections.forEach(section => {
        section.questions.forEach(question => {
          const reply = this.studentReply[question.id];
          if (!reply || reply.answerId === null && this.timeRemaining > 0) {
            this.$set(this.showError, question.id, true);
            hasError = true;
          }
        });
      });

      if (hasError) {
        await Swal.fire({
          title: "Debe responder todas las preguntas para enviar el diagnóstico.",
          icon: "warning",
          confirmButtonText: "OK"
        });
        return;
      }

      
      this.points = Object.values(this.studentReply).reduce((acc, reply) => {
        // Inicializar una variable para la pregunta encontrada
        let questionFound = null;

        // Iterar sobre cada sección en questionsBySection
        for (const section of Object.values(this.questionsBySection)) {
          // Buscar la pregunta correspondiente en la sección actual
          questionFound = section.find(q => q.id === reply.questionId);
          if (questionFound) break; // Salir del bucle si se encuentra la pregunta
        }

        // Comparar la respuesta
        const answer = questionFound ? questionFound.correctAnswer === reply.answerId : null; // Verificar respuesta
        return acc + (answer ? 1 : 0); 
      }, 0);


      if (fromTimeExpired) {
        // Enviar directamente sin confirmación
        const id = parseInt(this.$route.params.id);
        await axios.post(`student/poll_reply/`+id, {
          answer: JSON.stringify(this.studentReply),
          points: this.points,
          time: 0
        });
        this.stopTimer();
        this.clearLocalStorage();
        await Swal.fire("¡Completado!", "Respuestas enviadas", "success");
        this.$router.push('/');
        return;
      }

      const result = await Swal.fire({
        title: "¿Desea terminar la evaluación?",
        icon: "warning",
        showCancelButton: this.timeRemaining > 0,
        confirmButtonText: "Sí",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Volver",
        allowOutsideClick: false
      });


      if (result.isConfirmed) {
        const id = parseInt(this.$route.params.id);

        await axios.post(`student/poll_reply/`+id, {
            answer: JSON.stringify(this.studentReply),
            points: this.points,
            time: 0 // Cambiar este valor según sea necesario
        });
        this.stopTimer();
        this.clearLocalStorage();
        await Swal.fire("Completada!", "Las respuestas han sido contestadas", "success");
        this.$router.push('/');
      }
    },
    nextSection() {
      if (this.currentTab < this.sections.length - 1) {
        this.currentTab ++;
      }
    },
  },
  async created() {
    this.loadFromLocalStorage();
    const routePath = this.$route.path; 

    // Paso 2: Dividir la ruta en partes
    const pathParts = routePath.split("/"); // ["", "estudiante", "diagnostico", "LectumX", "ID"]

    // Paso 3: Identificar si es LectumA o LectumB
    this.lectumType = pathParts[3]; 

    try {

      if (this.lectumType === "LectumA") {
        const response = await axios.get("lectum");
        const questionsLectum = await axios.get("questionLectum");
        this.questionsBySection = questionsLectum.data.questionsBySection;

        this.texts = response.data.texts;

        // Crear secciones con textos asociados y preguntas
        this.sections = this.texts.map((text, i) => {
          const questions = questionsLectum.data.questionsBySection[i] || []; // Función para obtener preguntas

          return {
            title: `Texto ${i + 1}`,
            text: text,
            questions: questions, // Asigna preguntas a cada sección
          };
        });

      }else if (this.lectumType === "LectumB") {
        const response = await axios.get("lectumB");
        const questionsLectum = await axios.get("questionLectumB");
        this.questionsBySection = questionsLectum.data.questionsBySection;

        this.texts = response.data.texts;

        // Crear secciones con textos asociados y preguntas
        this.sections = this.texts.map((text, i) => {
          const questions = questionsLectum.data.questionsBySection[i] || []; // Función para obtener preguntas

          return {
            title: `Texto ${i + 1}`,
            text: text,
            questions: questions, // Asigna preguntas a cada sección
          };
        });

      }else{
        console.error("Tipo de Lectum no válido");
        return;
      }
     
      // Inicializar respuestas si no existen
      this.sections.forEach(section => {
        section.questions.forEach(question => {
          if (!this.studentReply[question.id]) {
            this.$set(this.studentReply, question.id, { 
              questionId: question.id, answerId: null 
            });
          }
        });
      });

      this.startTime = Date.now();
      this.startTimer();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

