<template>
  <v-card class="pb-4" >
    <v-card-title class="d-flex flex-wrap justify-center justify-md-space-between primary white--text" style="row-gap: 12px;">
      <div class="text-center text-md-left mb-0 mr-md-3" style="flex: 1 1 auto;">
        Diagnósticos parte de la asignatura
      </div>
      <div class="flex-shrink-0">
        <poll-activity-form 
          @poll-activity-created="handlePollUpdated" 
          class="primary responsive-button" 
          :lessonId="$route.params.id"
        />
      </div>
    </v-card-title>

    <v-text-field
      class="pa-3"
      v-model="search"
      append-icon="mdi-magnify"
      label="Buscar Diagnóstico"
      dense
      single-line
    ></v-text-field>

    <v-data-table
      height="350px"
      fixed-header
      :search="search"
      :headers="headers"
      :items="polls"
      item-key="id"
      class="m-3 elevation-1"
    >
      <template v-slot:no-data>
        <p>No hay diagnósticos.</p>
      </template>
      <template v-slot:item.state="{ item }">
        <v-chip dark :color="getStatusClass(item)" class="text-white">
          {{ new Date(`${item.limit}T23:59:00-03:00`) >= new Date() ? 'Activo' : 'Terminado' }}
        </v-chip>
      </template>
      <template v-slot:item.editar="{ item }">
        <EditPollActivity :pollActivity="item" @poll-activity-updated="handlePollUpdated"></EditPollActivity>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="openPollData(item)" color="primary" outlined small>
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn @click="confirmDelete(item)" color="red" outlined small>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <poll-activity-details
      :dialog="isDialogOpen"
      :poll-activity="selectedPoll"
      @close-dialog="closeDialogHandler"
    />
    <poll-activity-details-lectum
      :dialog="isDialogOpenLectum"
      :diagnosticIs="diagnosticIs"
      :poll-activity="selectedPoll"
      @close-dialog="closeDialogHandlerLectum"
    />
    <poll-activity-details-habitos
      :dialog="isDialogOpenHabitos"
      :poll-activity="selectedPoll"
      @close-dialog="closeDialogHandlerHabitos"
    />
  </v-card>
</template>

<script>
import PollActivityForm from './forms/PollActivityForm.vue';
import PollActivityDetails from './PollActivityDetails.vue';
import PollActivityDetailsLectum from './PollActivityDetailsLectum.vue';
import PollActivityDetailsHabitos from './PollActivityDetailsHabitos.vue';
import EditPollActivity from './forms/EditPollActivity.vue';
import axios from 'axios';
import Swal from "sweetalert2";

export default {
  components: { PollActivityForm,PollActivityDetailsHabitos, PollActivityDetails, PollActivityDetailsLectum, EditPollActivity },
  data: () => ({
    color: 'success',
    snackbar: null,
    loading: false,
    search: '',
    headers: [
      {
        text: 'Id',
        align: 'center',
        value: 'id'
      },
      { text: 'Nombre', value: 'poll.title' },
      { text: 'Fecha de fin', value: 'limit' },
      { text: 'Estado', value: 'state', sortable: false },
      { text: 'Descripción', value: 'description', cellClass: 'truncate-cell' },
      { text: 'Editar', value: 'editar', sortable: false },
      { text: 'Ver datos', value: 'actions', sortable: false },
      { text: 'Eliminar', value: 'delete', sortable: false }
    ],
    selectedPoll: null,
    isDialogOpen: false,
    isDialogOpenLectum: false,
    isDialogOpenHabitos: false,
    diagnosticIs: "",
  }),

  computed: {
    polls() {
      return this.$store.getters['teacher/getPollsActivityInLesson'];
    }
  },

  created() {
    this.getPolls();
  },

  methods: {
    closeDialogHandler() {
      this.selectedPoll = null;
      this.isDialogOpen = false;
    },
    closeDialogHandlerLectum() {
      this.selectedPoll = null;
      this.isDialogOpenLectum = false;
      this.diagnosticIs = "";
    },
    closeDialogHandlerHabitos(){
      this.selectedPoll = null;
      this.isDialogOpenHabitos = false;
    },
    async getPolls() {
      let id = this.$route.params.id;
      await this.$store.dispatch('teacher/pollActivitiesInLesson', id);
    },
    async confirmDelete(poll) {
      const result = await Swal.fire({
        title: '¿Está seguro de que desea eliminar esta actividad de diagnóstico?',
        text: "¡Esta acción no se puede deshacer!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        this.deletePoll(poll);
      }
    },
    async deletePoll(poll) {
      try {
        await axios.delete(`teacher/poll_activity/${poll.id}`);
        Swal.fire(
          'Eliminado',
          'El diagnóstico ha sido eliminado.',
          'success'
        );
        this.handlePollUpdated();
      } catch (error) {
        Swal.fire(
          'Error',
          'Hubo un problema al eliminar el diagnóstico.',
          'error'
        );
      }
    },
    async openPollData(poll) {
      this.selectedPoll = null;
      const response = await axios.get(`teacher/poll_activity/${poll.id}`);
      this.selectedPoll = response.data.pollActivity;
      if(poll.poll.id === 12 ) {
        this.diagnosticIs = "A";
        this.isDialogOpenLectum = true;
        return;
      }
      if (poll.poll.id === 13) {
        this.diagnosticIs = "B";
        this.isDialogOpenLectum = true;
        return;
      }
      if (poll.poll.id === 14) {
        this.diagnosticIs = "Interamericana";
        this.isDialogOpenLectum = true;
        return;
      }
      if (poll.poll.id === 15) {
        this.isDialogOpenHabitos = true;
        return;
      }
      this.isDialogOpen = true;
    },
    getStatusClass(item) {
      return new Date(`${item.limit}T23:59:00-03:00`) >= new Date() ? 'blue' : 'red';
    },
    handlePollUpdated() {
      this.getPolls();
    }
  }
}
</script>