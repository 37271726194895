<template>
  <div>
    <div class="rounded-lg text-center " >
      <div class="rounded-lg"  style="background-color: rgba(63, 81, 181, 0.8); color: white;" >
        <h1 class="ml-4 pt-2 ">{{ poll.title }}</h1>
      </div>
      <h3 v-if="poll.description" class="  mx-4 py-2">Descripción: {{ poll.description }}</h3>
    </div>
    
    <v-tabs v-model="currentTab"   grow color="deep-purple">
      <v-tab v-if="!finishReading && text">
        <v-icon left>mdi-book</v-icon>
        Texto
      </v-tab>
      <v-tab v-else v-for="(section, index) in sections" :key="index">
        <v-icon left>mdi-format-list-checks</v-icon>
        {{ section.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items touchless v-model="currentTab">
      <!-- Tab del texto -->
      <v-tab-item v-if="!finishReading && text">
        <v-card flat class="mt-4 pa-4">
          <v-card-title class="d-flex justify-center align-center">
            <p class="text-title"><strong>{{ text.title }}</strong></p>
          </v-card-title>
          <v-card-subtitle>
            <p>{{ text.author }}</p>
            <p>{{ text.publicationYear }}</p>
          </v-card-subtitle>
          <!-- Controles de Tamaño e Interlineado --> 
          <div class="d-flex justify-space-between align-center">
            <v-select
              v-model="fontSize"
              :items="fontSizes"
              label="Tamaño del texto"
              class="ma-2"
              dense
              outlined
            ></v-select>
            <v-select
              v-model="lineHeight"
              :items="lineHeights"
              label="Interlineado"
              class="ma-2"
              dense
              outlined
            ></v-select>
          </div>

          <v-card-text :style="{ fontSize: fontSize + 'px', lineHeight: lineHeight }"  >
            <div class="book-paragraph" v-for="(paragraph, index) in text.paragraphs" :key="index">
              {{ paragraph.description }}
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-center" >
            <v-btn @click="finishReadingText" text color="deep-purple accent-4">Terminar lectura</v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>

      <v-tab-item v-else v-for="(section, sIdx) in sections" :key="sIdx">
        <v-card flat>
          <v-card  class="mb-6">
            <v-divider></v-divider>
            <div v-for="(question, questionIndex) in section.questions" :key="questionIndex" class="pa-4">
              <v-card class="mt-4 pa-2">
                  <v-card-title class="white--text book-paragraph primary"> 
                    <p><v-icon left color="white">mdi-comment-question</v-icon> {{ `Pregunta ${questionIndex + 1}: ${question.description}` }}</p>
                  </v-card-title>
                  
                    <!-- Mostrar diferentes tipos de preguntas -->
                  <v-container v-if="question.type === 1"  class="d-flex justify-center">
                    <v-radio-group v-model="studentReply[question.id].answerId" row   @change="handleAnswerChange(question.id, question.type)">
                        <v-radio v-for="(option, i) in getSectionAnswers(section.title)" :key="i"
                                  :value="option.id"
                                  color="success"
                                  >
                          <template v-slot:label>
                              <span>
                                <div class="px-2">{{ option.description }}</div>
                              </span>
                          </template>
                        </v-radio>
                    </v-radio-group>
                  </v-container>
                  <v-container v-else-if="question.type === 2" class="d-flex justify-center">
                    <v-radio-group  v-model="studentReply[question.id].answerId" row   @change="handleAnswerChange(question.id, question.type)">
                        <v-radio  v-for="option in getSectionAnswersYESNO(section.title,question)" 
                                  :key="option.id"
                                  :value="option.id"
                                  color="success"
                                  >
                          <template v-slot:label>
                              <span>
                                <div class="px-2">{{ option.description }}</div>
                              </span>
                          </template>
                        </v-radio>
                    </v-radio-group>
                  </v-container>
                    <template v-else-if="question.type === 3" >
                      <v-textarea class="px-3" v-model="studentReply[question.id].answerText" @input="handleAnswerChange(question.id, question.type)" auto-grow filled color="deep-purple" rows="3">
                      </v-textarea>
                    </template>
                  
                <v-card-actions  v-if="showError[question.id]">
                  <v-alert type="error" class="mt-2">Debe responder todas las preguntas de esta sección.</v-alert>
                </v-card-actions>
              </v-card>
            </div>
          </v-card>
          <v-card-actions class="d-flex justify-center">
            <v-btn @click="submit"  class="white--text"  text color="deep-purple accent-4">
              Terminar Diagnóstico
            </v-btn>
          </v-card-actions>
        </v-card>
        
      </v-tab-item>
    </v-tabs-items>
      
      
    </div>
</template>

<script>

import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      currentTab:0,
      pollActivity: {},
      studentReply: {},
      answers: [],
      sections: [],
      text: null,
      finishReading: false,
      loading: false,
      points: 0,
      totalPoints: 0,
      startTime: null,
      timeSpent: 0,
      showError: {},
      fontSize: 16,
      lineHeight: 1.5,
      fontSizes: [12, 14, 16, 18, 20],
      lineHeights: [1.2, 1.5, 1.8, 2],
      poll: []
    };
  },
  computed:{
    hasUnansweredQuestions() {
      return this.sections.some(section => 
        section.questions.some(question => {
          const reply = this.studentReply[question.id];
          if (question.type === 3) {
            return !(reply && reply.answerText && reply.answerText.trim() !== '');
          } else {
            return !(reply && reply.answerId !== undefined);
          }
        })
      );
    },
  },

  methods: {
    canSendForm() {
      return this.sections.every(section =>
        section.questions.every(question => {
          const reply = this.studentReply[question.id];
          if (question.type === 3) {
            return reply && reply.answerText && reply.answerText.trim() !== '';
          } else {
            return reply && reply.answerId !== undefined;
          }
        })
      );
    },
    getSectionAnswers(sectionTitle) {
      const targetSection = sectionTitle === "Sin sección" 
        ? null 
        : sectionTitle;
      return this.answers.filter(answer => answer.section === targetSection && answer.description !== 'Sí' && answer.description !== 'No');
    },
    getSectionAnswersYESNO(sectionTitle, question) {
      const targetSection = sectionTitle === "Sin sección" 
        ? null 
        : sectionTitle;
      const answers = this.answers.filter(answer => answer.section === targetSection && (answer.description === 'Sí' || answer.description === 'No'));
      if (answers.length === 0) {
        return [
          { description: 'Sí', id: `yes_${question.id}` },
          { description: 'No', id: `no_${question.id}` }
        ];
      }
      return answers;
    },
    handleAnswerChange(questionId, type) {
      const reply = this.studentReply[questionId] || { questionId: questionId };
      if (type === 3) {
        reply.answerText = this.studentReply[questionId].answerText;
      } else {
        reply.answerId = this.studentReply[questionId].answerId;
      }
      this.$set(this.studentReply, questionId, reply);
      this.saveToLocalStorage();
    },
    finishReadingText() {
      this.finishReading = true;
      this.startTime = Date.now();
      this.saveToLocalStorage();
    },
    saveToLocalStorage() {
      const data = {
        studentReply: this.studentReply,
        finishReading: this.finishReading,
        startTime: this.startTime,
      };
      localStorage.setItem(`pollActivity_${this.$route.params.id}`, JSON.stringify(data));
    },
    loadFromLocalStorage() {
      const data = localStorage.getItem(`pollActivity_${this.$route.params.id}`);
      if (data) {
        const parsedData = JSON.parse(data);
        this.studentReply = parsedData.studentReply || {};
        this.finishReading = parsedData.finishReading || false;
        this.startTime = parsedData.startTime || null;
      }
    },
    
    async submit() {
      this.showError = {};
      let hasError = false;

      this.sections.forEach(section => {
        section.questions.forEach(question => {
          const reply = this.studentReply[question.id];
          if (question.type === 3) {
            if (!reply || !reply.answerText || reply.answerText.trim() === '') {
              this.$set(this.showError, question.id, true);
              hasError = true;
            }
          } else {
            if (!reply || reply.answerId === undefined) {
              this.$set(this.showError, question.id, true);
              hasError = true;
            }
          }
        });
      });

      if (hasError) {
        Swal.fire({
          title: "Debe responder todas las preguntas para enviar el diagnóstico.",
          icon: "warning",
          confirmButtonText: "OK"
        });
        return;
      }

      this.points = Object.values(this.studentReply).reduce((acc, reply) => {
        const question = this.questions.find(q => q.id === reply.questionId);
        if (question) {
          if (question.type === 1) {
            const answer = this.answers.find(a => a.id === reply.answerId && a.section === question.section);
            return acc + (answer ? answer.points : 0);
          } else if (question.type === 2) {
            const answer = this.answers.find(a => a.description === (reply.answerId ? 'Sí' : 'No') && a.section === question.section);
            return acc + (answer ? answer.points : 0);
          }
        }
        return acc;
      }, 0);

      this.timeSpent = Math.round((Date.now() - this.startTime) / 1000);
      let id = this.$route.params.id;

      console.log('Student Reply:', this.studentReply); // Depuración
      console.log('Points:', this.points); // Depuración
      console.log('Time Spent:', this.timeSpent); // Depuración
      
      await Swal.fire({ 
        title: "¿Desea terminar la evaluación?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí!",
        cancelButtonText: "Volver",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.post("student/poll_reply/" + id, {
            answer: JSON.stringify(this.studentReply),
            points: this.points,
            time: this.timeSpent
          });
          await Swal.fire("Completada!", "Las respuestas han sido contestadas", "success");
          localStorage.removeItem(`pollActivity_${id}`);
          this.$router.push('/estudiante/asignaturas');
        }
      });
    },
  },
  async created() {
    this.currentTab=0;
    this.loadFromLocalStorage();
    this.loading = true;
    let id = this.$route.params.id;
    try {
      const response = await axios.get("student/poll_activity/" + id);
      console.log('Response Data:', response.data); // Depuración

      this.answers = response.data.pollActivity.answers;
      this.questions = response.data.pollActivity.questions;
      this.pollActivity = response.data.pollActivity;
      this.text = response.data.text && response.data.text.title ? response.data.text : null;
      this.poll = response.data.poll;

      console.log(response)
      console.log(this.poll)

      this.questions.forEach((question) => {
        // Asignar 'Sin sección' cuando no hay sección definida
        const sectionTitle = question.section?.trim() || "Sin sección"; 
        
        const sectionIndex = this.sections.findIndex(
          section => section.title === sectionTitle
        );
        
        if (sectionIndex === -1) {
          this.sections.push({ 
            title: sectionTitle, 
            questions: [question] 
          });
        } else {
          this.sections[sectionIndex].questions.push(question);
        }
      });

      // Initialize studentReply with the structure based on question type if not loaded from localStorage
      this.sections.forEach(section =>
        section.questions.forEach(question => {
          if (!this.studentReply[question.id]) {
            this.studentReply[question.id] = { questionId: question.id };
            if (question.type === 3) {
              this.studentReply[question.id].answerText = '';
            }
          }
        })
      );

      if (!this.startTime) {
        this.startTime = Date.now();
      }

      console.log(this.text)

      this.loading = false;
      if (!this.text) {
        this.finishReading = true;
      }
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
