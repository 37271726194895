<template>
  <v-dialog v-model="showModal" persistent max-width="800px">
    <v-card class="response-modal">
      <v-card-title class="d-flex align-center primary white--text">
        <h3 class="headline">{{ modalTitle }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('update:showModal', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-card-text class="response-container pa-4">
        <v-list v-if="modalType === 'alternative'" class="response-list">
          <v-list-item
            v-for="response in filteredResponses"
            :key="response.id"
            class="response-item mb-2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                {{ response.student }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-else-if="modalType === 'development'" class="response-list">
          <v-list-item
            v-for="(response, index) in filteredResponses"
            :key="index"
            class="response-item mb-3"
          >
            <v-card outlined class="response-card pa-3">
              <div class="d-flex align-start">
                <v-avatar color="primary" size="40" class="mr-3">
                  <span class="white--text">{{ getInitials(response.student) }}</span>
                </v-avatar>
                
                <div class="response-content">
                  <div class="student-name font-weight-bold mb-1">
                    {{ response.student }}
                  </div>
                  <div class="response-text">
                    {{ isPollActivity ? response.answer : response.answer[0].answer }}
                  </div>
                </div>
              </div>
            </v-card>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    questionId: { type: [Number, String], required: true },
    alternativeId: { type: [Number, String] },
    modalType: { type: String, required: true },
    replies: { type: [Array, Object], required: true },
    showModal: { type: Boolean, required: true },
    isPollActivity: { type: Boolean, default: false }
  },
  data() {
    return {
      modalTitle: '',
      filteredResponses: []
    };
  },
  methods: {
    getInitials(name) {
      return name.split(' ').map(n => n[0]).join('').toUpperCase();
    },
    filterResponses() {
      console.log('Filtrando respuestas...', {
        questionId: this.questionId,
        alternativeId: this.alternativeId,
        modalType: this.modalType,
        replies: this.replies,
        isPollActivity: this.isPollActivity
      });

      if (!this.replies || !Array.isArray(this.replies)) {
        console.warn('❗ replies no es un array válido.');
        this.filteredResponses = [];
        return;
      }

      this.filteredResponses = this.replies.map(reply => {
        if (!reply || !reply.student) return null;

        let parsedAnswer;
        try {
          parsedAnswer = typeof reply.answer === 'string' ? JSON.parse(reply.answer) : reply.answer;
        } catch (e) {
          console.error('Error al parsear answer:', e);
          return null;
        }

        parsedAnswer = parsedAnswer ? Object.values(parsedAnswer) : [];
        let filteredAnswer;

        // 🔹 FILTRADO PARA `alternative`
        if (this.modalType === 'alternative') {
          if (this.isPollActivity) {
            filteredAnswer = parsedAnswer.filter(ans => ans.questionId === this.questionId);
            for (const ans of filteredAnswer) {
              if (ans.answerId === this.alternativeId) {
                return { student: `${reply.student.name} ${reply.student.lastName}` };
              }
            }
          } else {
            filteredAnswer = parsedAnswer.filter(ans => ans.idQuestion === this.questionId);
            for (const ans of filteredAnswer) {
              if (ans.type === 1 && ans.answer.id === this.alternativeId) {
                return { student: `${reply.student.name} ${reply.student.lastName}` };
              } else if (ans.type === 2 && ans.answer.some(a => a.id === this.alternativeId)) {
                return { student: `${reply.student.name} ${reply.student.lastName}` };
              }
            }
          }
        }
          

          
        // 🔹 FILTRADO PARA `development`
        else if (this.modalType === 'development') {
          if (this.isPollActivity) {
            filteredAnswer = parsedAnswer.filter(ans => ans.questionId === this.questionId);
            for (const ans of filteredAnswer) {
                      return { 
                          student: `${reply.student.name} ${reply.student.lastName}`, 
                          answer: ans.answerText 
                      };
              }
            
            
          } else {
            filteredAnswer = parsedAnswer.filter(ans => ans.idQuestion === this.questionId);
            if (filteredAnswer.length > 0) {
              return { 
                student: `${reply.student.name} ${reply.student.lastName}`, 
                answer: filteredAnswer// Solo las respuestas
              };
            }
          }
        }

        return null;
      }).filter(Boolean);

      // 🔹 Actualizar título del modal
      if (this.modalType === 'alternative' && this.alternativeId !== undefined) {
        this.modalTitle = `Estudiantes que respondieron la alternativa (${this.filteredResponses.length})`;
      } else if (this.modalType === 'development') {
        this.modalTitle = 'Respuestas de desarrollo';
      }

      console.log('✅ Respuestas filtradas:', this.filteredResponses);
    }
  },
  watch: {
    replies: 'filterResponses',
    questionId: 'filterResponses',
    alternativeId: 'filterResponses',
    modalType: 'filterResponses'
  },
  created() {
    this.filterResponses();
  }
};
</script>