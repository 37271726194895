    <template>
        <div>
        <!-- Tabs -->
        <v-tabs v-model="currentTab" grow :keyboard-control="false">
            <v-tab v-for="(section, index) in sections" :key="index" :disabled="index != currentTab">
                <div v-if="index===0 || index===2 || index===4">
                    Intrucciones 
                </div>
                <div v-if="index===1">
                    Parte I, Vocabulario
                </div>
                <div v-if="index===3">
                    Parte II, Nivel de velocidad
                </div>
                <div v-if="index===5">
                    Parte III, Nivel de comprensión
                </div>
            </v-tab>
        </v-tabs>
    
        <div class="v-btn--floating-timer" >
            <span>{{ formattedTime }}</span>
        </div>
    
        <!-- Contenido de la sección -->
        <v-tabs-items  v-model="currentTab" touchless :key="currentTab">
            <v-tab-item v-for="(section, index) in sections" :key="index">
                <div v-if="index === currentTab">
                    <!-- Sección de instrucciones -->
                    <div v-if="index != 5">
                        <FocusedQuestion 
                            :questions="section.questions" 
                            :studentReply="studentReply"
                            :beforeIndex="currentIndex"
                            @finished="handleFinish"
                            @changeQuestion="changeQuestion"
                            @answered="handleAnswer"
                            :index="index"
                        />
                    </div>
                    <!-- Sección con texto y preguntas -->
                    <div v-if="index === 5">
                        <FocusedQuestion 
                        v-if="section.texts"
                            :questions="section.texts.reduce((acc, text) => [...acc, ...text.questions], [])" 
                            :texts="section.texts"
                            :studentReply="studentReply"
                            :beforeIndex="currentIndex"
                            @changeQuestion="changeQuestion"
                            @answered="handleAnswer"
                            @finished="handleFinish"
                            :index="index"
                        />
                    </div>
                </div>
            </v-tab-item>
        </v-tabs-items>
        </div>
    </template>
        
        <script>
        import axios from "axios";
        import Swal from "sweetalert2";
        import FocusedQuestion from "@/components/UI/FocusQuestion.vue";

        export default {
            data() {
            return {
                showAnswers: false, 
                currentTab: 0,
                studentReply: {},
                questionsBySection: {},
                sections: [],
                timeRemaining: 0, // 90 minutos
                timerInterval: null,
                points:0,
                currentIndex:0,
            };
            },
            computed: {
            formattedTime() {
                const minutes = Math.floor(this.timeRemaining / 60);
                const seconds = this.timeRemaining % 60;
                return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
            },
            },components: {
                FocusedQuestion,
            },
            methods: {
                changeQuestion({ questionId,beforeIndex,time}){
                    if (!this.studentReply[questionId]) {
                        this.$set(this.studentReply, questionId, {
                            questionId: questionId,
                            answerId: null,
                            time: 0
                        });
                    }
                    this.studentReply[questionId].time += time;
                    this.currentIndex=beforeIndex;
                    this.saveToLocalStorage();

                },
                nextSection() {
                    this.stopTimer();

                    if (this.currentTab===5) {
                        this.submit();
                    }else if (this.currentTab < this.sections.length - 1) {
                        this.currentTab++;
                        this.currentIndex=0;

                        const sectionDurations = {
                            1: 10 * 60,  // 10 minutos
                            3: 6 * 60,  // 6 minutos
                            5: 25 * 60   // 25 minutos
                        };
                        
                        this.timeRemaining = sectionDurations[this.currentTab] || 0;
    
                        // Solo iniciar timer si es necesario
                        if (this.timeRemaining > 0) {
                        this.startTimer();
                        }

                    }
                },
                handleAnswer({ questionId, answerId, time, beforeIndex }) {
                    this.currentIndex=beforeIndex;
                    if (!this.studentReply[questionId]) {
                        this.$set(this.studentReply, questionId, {
                            questionId: questionId,
                            answerId: null,
                            time: 0
                        });
                    }
                    // Usar $set para actualizar propiedades anidadas
                    this.$set(this.studentReply[questionId], 'time', 
                        (this.studentReply[questionId].time || 0) + time
                    );
                    this.$set(this.studentReply[questionId], 'answerId', answerId);

                    this.saveToLocalStorage();
                },
                handleFinish() {
                    console.log(this.studentReply)
                    this.nextSection();
                },
                saveToLocalStorage() {
                    const data = {
                    studentReply: this.studentReply,
                    currentTab: this.currentTab,
                    time: this.timeRemaining,
                    currentIndex:this.currentIndex,
                };
                localStorage.setItem(`pollActivity_Interamericana_${this.$route.params.id}`, JSON.stringify(data));
                },
                loadFromLocalStorage() {
                    const data = localStorage.getItem(`pollActivity_Interamericana_${this.$route.params.id}`);
                    if (data) {
                        const parsedData = JSON.parse(data);
                        this.studentReply = parsedData.studentReply || {};
                        this.currentTab = parsedData.currentTab || 0;
                        this.timeRemaining = parsedData.time || 0;
                        this.currentIndex = parsedData.currentIndex || 0;
                    }

                    this.startTimer();

                },
                async submit() {
                    this.showError = {};
                    let hasError = false;

                    // Validar si el tiempo sigue activo
                    const timeExpired = this.timeRemaining <= 0; 

                    console.log(timeExpired)
                    console.log(hasError)

                    this.sections.forEach((section, sectionIndex) => {
                        if (sectionIndex === 5 && !timeExpired) {
                            if (section.texts) {
                                section.texts.forEach(text => {
                                    text.questions.forEach(question => {
                                        if (!this.studentReply[question.id]?.answerId) {
                                            this.$set(this.showError, question.id, true);
                                            hasError = true;
                                        }
                                    });
                                });
                            }
                        }
                    });

                    console.log(hasError)

                    // Si faltan respuestas y el tiempo no ha terminado, mostrar alerta y detener el envío
                    if (hasError) {
                        await Swal.fire({
                            title: "Debe responder todas las preguntas antes de enviar el diagnóstico.",
                            icon: "warning",
                            confirmButtonText: "OK",
                        });
                        return;
                    }

                    // Calcular los puntos
                    this.points = 0;
                    [1, 3, 5].forEach(sectionIndex => { // 👈 Filtramos las secciones relevantes
                        const section = this.sections[sectionIndex];
                        if (section) {
                            if (section.questions) {
                                section.questions.forEach(question => {
                                    const reply = this.studentReply[question.id];
                                    if (reply && reply.answerId === question.correctAnswer) {
                                        this.points += 1;
                                    }
                                });
                            }
                            if (section.texts) {
                                section.texts.forEach(text => {
                                    text.questions.forEach(question => {
                                        const reply = this.studentReply[question.id];
                                        if (reply && reply.answerId === question.correctAnswer) {
                                            this.points += 1;
                                        }
                                    });
                                });
                            }
                        }
                    });


                    // Confirmación para enviar la evaluación
                    const result = await Swal.fire({
                        title: "¿Desea terminar la evaluación?",
                        icon: "warning",
                        showCancelButton: this.timeRemaining > 0,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Sí!",
                        cancelButtonText: "Volver",
                        allowOutsideClick: false, // Evita cerrar haciendo clic fuera
                        allowEscapeKey: false ,
                    });

                    if (result.isConfirmed) {
                        const id = parseInt(this.$route.params.id);

                        // Convertir respuestas en el formato esperado
                        const validReplies = Object.values(this.studentReply);

                        await axios.post(`student/poll_reply/` + id, {
                            answer: JSON.stringify(validReplies),
                            points: this.points,
                            time: this.timeRemaining, 
                        });

                        this.stopTimer();
                        localStorage.removeItem(`pollActivity_Interamericana_${this.$route.params.id}`);
                        this.studentReply = {}; 
                        await Swal.fire("Completada!", "Las respuestas han sido enviadas.", "success");
                        this.$router.push("/");
                    }
                },
                timeExpired() {
                    // Para todas las secciones con tiempo
                    if ([1, 3, 5].includes(this.currentTab)) {
                        const currentSection = this.sections[this.currentTab];
                        
                        // Marcar respuestas como null
                        if (currentSection.questions) {
                            currentSection.questions.forEach(question => {
                                if (!this.studentReply[question.id]?.answerId) {
                                    this.$set(this.studentReply, question.id, {
                                        questionId: question.id,
                                        answerId: null,
                                        time: 0
                                    });
                                }
                            });
                        }
                        
                        // Para la sección de textos (parte 5)
                        if (currentSection.texts) {
                            currentSection.texts.forEach(text => {
                                text.questions.forEach(question => {
                                    if (!this.studentReply[question.id]?.answerId) {
                                        this.$set(this.studentReply, question.id, {
                                            questionId: question.id,
                                            answerId: null,
                                            time: 0
                                        });
                                    }
                                });
                            });
                        }

                        this.saveToLocalStorage();
                        
                        Swal.fire({
                            title: "¡Tiempo agotado!",
                            text: "Se ha terminado el tiempo para esta sección.",
                            icon: "info",
                            allowOutsideClick: false, // Evita cerrar haciendo clic fuera
                            allowEscapeKey: false ,
                            confirmButtonText: "Continuar",
                        }).then(() => {
                            if (this.currentTab===5) {
                                this.submit();    
                            }else{
                                this.nextSection();

                            }
                        });
                    }
                },stopTimer() {
                    if (this.timerInterval) {
                        clearInterval(this.timerInterval);
                        this.timerInterval = null;
                    }
                },
                startTimer() {
                    // Limpiar timer existente
                    this.stopTimer();
                    
                    this.timerInterval = setInterval(() => {
                        if (this.timeRemaining > 0) {
                            this.timeRemaining--;
                            this.saveToLocalStorage();
                            
                            // Notificación cuando queden 30 segundos
                            if (this.timeRemaining === 30) {
                                Swal.fire({
                                    title: "¡Últimos 30 segundos!"  ,
                                    icon: "warning",
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                            }
                        } else {
                            clearInterval(this.timerInterval);
                            this.timeExpired();
                        }   
                    }, 1000);
                },
                async fetchData() {
                    try {
                        const [textsRes, questionsRes] = await Promise.all([
                            axios.get("/interamericana"),
                            axios.get("/questionInteramericana"),
                        ]);

                        const texts = textsRes.data.texts;
                        this.questionsBySection = questionsRes.data.questionsBySection;

                        // Reestructurar secciones
                        this.sections = [
                            { type: 'instructions', index: 0 , questions: this.questionsBySection[0]|| []}, // Sección 0
                            { type: 'questions', index: 1, questions: this.questionsBySection[1] || [] }, // Sección 1
                            { type: 'instructions', index: 2 , questions: this.questionsBySection[2]|| []}, // Sección 2
                            { type: 'questions', index: 3, questions: this.questionsBySection[3] || [] }, // Sección 3
                            { type: 'instructions', index: 4 , questions: this.questionsBySection[4]|| []}, // Sección 4
                            { 
                                type: 'texts', 
                                index: 5,
                                texts: texts.map(text => ({
                                    ...text,
                                    paragraphs: text.paragraph?.map(p => p.description) || [],
                                    questions: this.questionsBySection[5]?.filter(q => q.description === text.description) || []
                                })) 
                            }
                        ];

                        // Inicializar respuestas
                        this.studentReply = {};
                        this.sections.forEach(section => {
                            if (section.questions) {
                                section.questions.forEach(question => {
                                    this.$set(this.studentReply, question.id, { 
                                        questionId: question.id, 
                                        answerId: null,
                                        time: 0 // <- Tiempo por pregunta
                                    });
                                });
                            }
                            if (section.texts) {
                                section.texts.forEach(text => {
                                    text.questions.forEach(question => {
                                        this.$set(this.studentReply, question.id, {
                                            questionId: question.id,
                                            answerId: null,
                                            time: 0 
                                        });
                                    });
                                });
                            }
                        });
                        
                    } catch (error) {
                        console.error("Error al cargar los datos:", error);
                    }
                },
                },
                async created() {
                    await this.fetchData();
                    await this.loadFromLocalStorage();
                },
        };
        </script>
        

        