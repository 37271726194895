<template>
  <v-dialog v-model="modalOpen" persistent max-width="80%">
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between primary white--text">
        Detalles de la Actividad de {{ this.reply.student.name }} {{ this.reply.student.lastName }}
        <v-spacer></v-spacer>
        <v-btn  color="red"  @click="$emit('update:modalOpen', false)">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          <v-tabs v-model="tab">
            <v-tab href="#tab-1">Párrafos</v-tab>
            <v-tab href="#tab-2">Respuestas</v-tab>
            <v-tab href="#tab-3">Palabras Guardadas</v-tab>
          </v-tabs>
          <v-tabs-items touchless v-model="tab">
            <v-tab-item value="tab-1">
              <v-card-title>
                <p class="text-justify">Tiempo de lectura: <strong>{{ this.timeread }} segundos</strong></p>
              </v-card-title>
              <v-card class="mt-4" width="100vw"  v-if="minigameName ===null">
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col class="text--primary" v-for="(paragraph,i) in paragraphs" :key="i" cols="12">
                        <p class="text-justify" v-html="paragraph.description"></p>
                        <v-text-field readonly
                          :prepend-icon="paragraphAnswer[i].answer !== '' ? 'mdi-check-all' : 'mdi-close'"
                          :value="paragraphAnswer[i].answer !== '' ? paragraphAnswer[i].answer : 'Estudiante no realizó pregunta.'" />
                      </v-col>
                      
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
              <v-card class="mt-4" width="100vw" v-if="minigameName==='image_minigame'">
                <v-card-text>
                  <v-container >
                    <v-row class="text--primary" v-for="(paragraph,i) in paragraphs" :key="i">
                      <v-col cols="8">
                        <p class="text-justify" v-html="paragraph.description"></p>
                        <v-text-field readonly
                          :prepend-icon="paragraphAnswer[i].answer !== '' ? 'mdi-check-all' : 'mdi-close'"
                          :value="paragraphAnswer[i].answer !== '' ? paragraphAnswer[i].answer : 'Estudiante no realizó pregunta.'" />

                      </v-col>
                      <v-col cols="4">
                        <div :class="['image-container', getBorderClass(paragraph.id)]">
                          <v-img v-if="getImage(paragraph.id) != null" :src="getImage(paragraph.id)" class="contained-image"></v-img>
                          <v-card v-else class="contained-image d-flex justify-center align-center">
                            <h2>Ninguna imagen</h2>
                          </v-card>
                        </div>

                        </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>

              <v-card class="mt-4" width="100vw"  v-if="minigameName==='unordered_text'">
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="6"><strong>Orden correcto</strong></v-col>
                      <v-col cols="6"><strong>Orden estudiante</strong></v-col>
                    </v-row>
                    <v-row v-for="(paragraph,i) in paragraphs" :key="i">
                      <v-col class="text--primary" cols="6">
                        <p class="text-justify">
                          {{ paragraph.description }}
                        </p>
                      </v-col>
                      <v-col class="text--primary" cols="6">
                        <p class="text-justify" :style="{ color: paragraph.id === order[i] ? 'green' : 'red' }"
                          v-html="paragraphs.find(el => el.id === order[i]).description"></p>
                          <v-text-field readonly
                          :prepend-icon="paragraphAnswer[i].answer !== '' ? 'mdi-check-all' : 'mdi-close'"
                          :value="paragraphAnswer[i].answer !== '' ? paragraphAnswer[i].answer : 'Estudiante no realizó pregunta.'" />
                      </v-col>
                      
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2" >

              <v-card-title>
                <p class="text-justify">Tiempo de respuesta: <strong>{{ this.time }} segundos</strong></p>
              </v-card-title>

              <v-card v-for="(question, index) in questions" :key="index" class="mt-2" width="100vw">
                <v-card-title>
                  {{index + 1}}) {{ question.question.title }} ({{calculateScore(question, index)}}pts)
                </v-card-title>
                <v-card-text>
                  <div class="text--primary">
                    <p class="text-justify" v-if="question.question.description.length>0">
                      {{ question.question.description }}
                    </p>
                    <p class="text-justify">
                     Tiempo que se demoro en la pregunta : {{ getTimeFromId(question.question.id) }} segundos 
                    </p>
                  </div>
                </v-card-text>
                <v-container class="pa-5" fluid v-if="question.question.type === 0">
                  <div disabled class="light-blue lighten-4" color="teal">
                      <p class="pa-5" text-color="primary">
                        Respuesta: {{ questionAnswers[index].answer }}
                      </p>
                  </div>
                </v-container>

                <v-container class="pa-5" fluid v-if="question.question.type === 1">
                  <v-radio-group>
                    <v-radio disabled v-for="(alternative, i) in question.question.answer" :key="i" color="red"
                      :off-icon="getIcon(alternative, questionAnswers[index].answer)">
                      <template v-slot:label>
                        <h4 v-if="questionAnswers[index].answer.id === alternative.id && alternative.isCorrect"
                          class="text-left" style="color: green">
                          {{ alternative.isCorrect ? `${ alternative.description } (Respuesta correcta)` :
                          alternative.description }}
                        </h4>
                        <h4 v-if="questionAnswers[index].answer.id === alternative.id && !alternative.isCorrect"
                          class="text-left" style="color: red">
                          {{ alternative.isCorrect ? `${ alternative.description } (Respuesta correcta)` :
                          alternative.description }}
                        </h4>
                        <h4 v-if="questionAnswers[index].answer.id !== alternative.id" class="text-left"
                          :style="{ color: alternative.isCorrect ? 'green' : '' }">
                          {{ alternative.isCorrect ? `${ alternative.description } (Respuesta correcta)` :
                          alternative.description }}
                        </h4>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-container>

                <v-container class="pa-5" fluid v-if="question.question.type === 2">
                  <v-checkbox disabled v-for="(alternative, i) in question.question.answer" :key="i"
                    :label="alternative.description" :value="alternative"
                    :off-icon="getIconMultiple(alternative, questionAnswers[index])">
                    <template v-slot:label>
                      <h4
                        v-if="questionAnswers[index].answer.some(val => val.id === alternative.id ) && alternative.isCorrect"
                        class="text-left" style="color: green">
                        {{ alternative.description }} (Respuesta Correcta)
                      </h4>
                      <h4
                        v-else-if="questionAnswers[index].answer.some(val => val.id === alternative.id ) && !alternative.isCorrect"
                        class="text-left" style="color: red">
                        {{ alternative.description }}
                      </h4>
                      <h4 v-else-if="questionAnswers[index].answer.find(val => val.id !== alternative.id )" class="text-left"
                        :style="{ color: alternative.isCorrect ? 'green' : '' }">
                        {{ alternative.isCorrect ? `${ alternative.description } (Respuesta correcta)` :
                        alternative.description }}
                      </h4>
                    </template>
                  </v-checkbox>
                </v-container>

              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <v-card elevation="2" class="mt-10" width="100vw">
                <v-card-title>Palabras Guardadas</v-card-title>
                <v-spacer></v-spacer>
                <v-row class="ml-2">
                  <v-col cols="6" v-for="(word, i) in savedWords" :key="i">
                    <v-text-field label="Palabra" :value="word" readonly />
                  </v-col>
                  <p v-if="savedWords.length === 0">Estudiante no guardó palabras.</p>
                </v-row>
              </v-card>
              <v-card class="mt-10" width="100vw">
                <v-card-title>Palabras Destacadas</v-card-title>
                <v-row class="ml-2">
                  
                  <v-col cols="6" v-for="(word, i) in highLight" :key="i">
                    <v-text-field label="Palabra" :value="word" readonly />
                  </v-col>
                  <p v-if="highLight.length === 0">Estudiante no resaltó palabras.</p>
                </v-row>
              </v-card>

            </v-tab-item>

          </v-tabs-items>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from "axios";

export default {
  props: ['minigameName','idActividad','idEstudiante','modalOpen'],
  data() {
    return {
      order: [],
      savedWords: [],
      highLight: [],
      reply: null,
      paragraphAnswer: null,
      questionAnswers: null,
      tab: null,
      paragraphs: [],
      questions: [],
      time: '',
      timeread: '',
      timeAnswer: [],
      minigameInfo: null
    }
  },
  methods: {
    downloadReport() {
      let studentId = this.$route.params.idEstudiante;
      let activityId = this.$route.params.idActividad
      axios.get(`createPDF/${studentId}/${activityId}`)
    },
    getImage(id) {
      if (this.minigameInfo!= null) {
        const image = this.minigameInfo[id];
        console.log(this.minigameInfo[id])
        return image ? image : null;
      }
      return null;
    },
    paragraphHighLights(paragraph) {
      paragraph.replace('Chile', 'xd')
      // <strong style="background-color: yellow"> + word + </strong>

      // this.highLight.forEach(word => paragraph.replaceAll(word, 'iwiwiwiwi'));
      // paragraph.map(paragraph => {
      //   this.highLight.forEach(word => {
      //     if(paragraph)
      //   })
      // })


      return paragraph
    },
    getBorderClass(paragraphId) {
      const studentImage = this.minigameInfo[paragraphId];
      const correctImage = this.paragraphs.find(p => p.id === paragraphId).image;
      return studentImage === correctImage ? 'correct-answer' : 'incorrect-answer';
    },
    getIcon(alternative, answer) {
      if (alternative.id === answer.id && alternative.isCorrect) {
        return 'mdi-check-circle'
      }
      if (alternative.id === answer.id && !alternative.isCorrect) {
        return 'mdi-close-circle'
      }
      if (alternative.id !== answer.id) {
        return 'mdi-checkbox-blank-circle-outline'
      }
    },
    getIconMultiple(alternative, answer) {
      if (answer.answer.some(val => val.id === alternative.id) && alternative.isCorrect) {
        return 'mdi-checkbox-marked';
      }
      if (answer.answer.some(val => val.id === alternative.id) && !alternative.isCorrect) {
        return 'mdi-close-box';
      }
      if (answer.answer.some(val => val.id !== alternative.id)) {
        return 'mdi-checkbox-blank-outline';
      }


    },
    calculateScore(question, index) {
      if (question.question.type === 2) { // Pregunta de selección múltiple
        const correctAnswers = question.question.answer.filter(a => a.isCorrect);
        const studentAnswers = this.questionAnswers[index].answer;
        const allCorrect = correctAnswers.every(correctAnswer => 
          studentAnswers.some(studentAnswer => studentAnswer.id === correctAnswer.id && studentAnswer.isCorrect)
        );

        return allCorrect ? `${question.question.points}/${question.question.points}` : `0/${question.question.points}`;
      } else { // Pregunta de una sola respuesta correcta
        return this.questionAnswers[index].answer.isCorrect ?
          `${question.question.points}/${question.question.points}` : `0/${question.question.points}`;
      }
    },
    getTimeFromId(id) {
      try {
        // Encontrar el objeto en timeAnswer que tenga el id coincidente
        const time = this.timeAnswer.find(val => val.id === id);
        const foundItem = parseFloat(time.time).toFixed(2);
        
        // Verificar si se encontró el objeto y devolver su propiedad time
        return foundItem ? foundItem : '';
      } catch (error) {
        console.error('Error al analizar ', error);
        return ''; // Devolver una cadena vacía en caso de error
      }
    },
    getColorAlternative(answer, alternative, correct) {
      if (!answer && correct) return 'red'
      if (answer.id === alternative && answer.isCorrect) {
        return 'green'
      }
      if (answer.id === alternative && !answer.isCorrect) {
        return 'red'
      }

    },
    getTimeAnswer(timeAnswer) {
      try {
        // Convertir el JSON a un array de objetos

        // Verificar si timeAnswer es un array y no está vacío
        if (!Array.isArray(timeAnswer) || timeAnswer.length === 0) {
          return 0; // Devolver 0 si no hay tiempos para sumar
        }

        // Usar reduce para sumar todos los tiempos
        const total = timeAnswer.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.time;
        }, 0);

        return total; // Devolver la suma total de los tiempos
      } catch (error) {
        console.error('Error al analizar el JSON:', error);
        return 0; // Devolver 0 en caso de error
      }
    }
  },
  async created() {
    let textId;
    let idActivity = this.idActividad;
    let idStudent = this.idEstudiante;

    
    await axios.get('teacher/reply/' + idStudent + '/' + idActivity)
      .then(response => {
        const { reply } = response.data;
        this.reply = reply;
        this.savedWords = JSON.parse(reply.saveWord)
        this.paragraphAnswer = JSON.parse(reply.question);
        this.highLight = JSON.parse(reply.highLight);
        textId = this.paragraphAnswer[0].textId;
        this.questionAnswers = JSON.parse(reply.answer);
        const timeAnswerJSON = JSON.parse(reply.timeAnswer);
        const timeA = this.getTimeAnswer(timeAnswerJSON) ;
        this.timeAnswer = timeAnswerJSON;
        this.timeread = parseFloat((reply.time - timeA).toFixed(2));
        this.time = parseFloat(timeA.toFixed(2));
        this.order = JSON.parse(reply.order);
        this.minigameInfo = JSON.parse(reply.minigame_info);
      })

    await axios.get('teacher/text/' + textId).then(response => {
      const { text } = response.data;
      this.textType = text.type;
      this.paragraphs = text.paragraph;
    });

    await axios.get('teacher/activity/' + idActivity).then(response => {
      const { questions } = response.data;
      this.questions = questions;
    });
    // this.paragraphHighLights(this.paragraphs[0].description)
  }
}
</script>
