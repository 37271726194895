<template>
  <div class="mt-6">
    <v-card v-if="!loading && activities.length > 0">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar actividad" single-line hide-details>
        </v-text-field>
      </v-card-title>
      <v-data-table hide-default-footer disable-pagination :search="search" :headers="headers" :items="activities"
        item-key="id" class="elevation-1">
        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" class="mr-2" @click="goToText(item)">
            mdi-magnify
          </v-icon>
        </template>
        <template v-slot:item.limitTime="{ item }">
          {{ item.limitTime ? item.limitTime : 'Sin límite de tiempo' }}
        </template>
      </v-data-table>
    </v-card>

    <loading-component v-if="loading" />

    <v-row v-if="!loading && activities.length === 0" style="height: 60vh" align="center">
      <v-col>
        <v-img height="60%" width="60%" :src="require('@/assets/images/undraw_empty_street_sfxm.svg')"></v-img>
        <p class="mt-5">Aún no tiene actividades asignadas</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LoadingComponent from "@/components/UI/LoadingComponent";
export default {
  props: ['loading'],
  components: { LoadingComponent },
  data() {
    return {
      search: '',
      headers: [],
    }
  },
  computed: {
    activities() {
      const noReply = this.$store.getters['student/getStudentActivitiesNoReply'];
      const reply = this.$store.getters['student/getStudentActivitiesReply'];
      const mapReply = noReply.filter(el => {
        if (!el.pre) return el;
        const findPre = reply.find(re => re.id === el.pre);
        if (findPre) return el;
      }).map(el => { return { ...el, date: el.date + ' 23:59:59' } });
      return mapReply;
    },
  },
  methods: {
    goToText({ id, limitTime }) {
      const data = {
        limitTime: limitTime * 60,
        timePased: 0
      }
      if (limitTime && !localStorage.getItem('activity_time_' + id)) {
        localStorage.setItem('activity_time_' + id, JSON.stringify(data)); // Cambio en la clave del localStorage
      }
      this.$router.push('/estudiante/textos/' + id);
    }
  },
  created() {
    this.headers.unshift(
      {
        text: 'Descripción',
        align: 'start',
        value: 'description',
        sortable: false,
        cellClass: 'truncate-cell'
      },
      {
        text: 'Fecha límite lectura (AAAA:MM::DD)',
        align: 'start',
        value: 'date',
        sortable: false,
      },
      {
        text: 'Tiempo límite lectura (minutos)',
        value: 'limitTime',
        sortable: false
      },
      {
        text: 'Realizar',
        value: 'actions',
        sortable: false
      },
    )
  }

}
</script>