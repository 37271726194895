<template>
    <v-card width="100%">
      <v-text-field class="px-5" v-model="search" append-icon="mdi-magnify" 
                   label="Buscar estudiante" single-line hide-details></v-text-field>
      <v-data-table height="300px" fixed-header :search="search" 
                  :headers="headers" :items="processedReplies" 
                  item-key="id" class="m-3 elevation-1">
        <template v-slot:no-data>
          <p>Nadie ha realizado la actividad.</p>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="showDetails(item)">
            <v-icon color="primary" outlined>mdi-eye</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.total_time="{ item }">
            {{ formatTime(item.time) }}
        </template>
      </v-data-table>
  
      <HabitosStudentReply
          :modalOpen.sync="showModal"
          :reply="currentReply"
          :pollActivity="pollActivity"
          :questions="Array.isArray(questions) ? questions : Object.values(questions)" 
        />
    </v-card>
  </template>
  
  <script>
  import HabitosStudentReply from './HabitosStudentReply.vue';
  
  export default {
    props: {
      replies: {
        type: Array,
        required: true
      },
      pollActivity: {
        type: Object,
        required: true
      },
      questions: Array
    },
    components: { HabitosStudentReply },
    data() {
      return {
        search: '',
        showModal: false,
        currentReply: null,
        headers: [
          { text: 'Nombre', value: 'student.name', sortable: false },
          { text: 'Apellido', value: 'student.lastName', sortable: false },
          { text: 'Tiempo', value: 'total_time', sortable: false },
          { text: 'Ver datos', value: 'actions', sortable: false }
        ]
      };
    },
    computed: {
      processedReplies() {
        return this.replies.map(reply => ({
          ...reply,
          percentage: this.calculatePercentage(reply.points)
        }));
      }
    },
    methods: {
      calculatePercentage(points) {
        console.log(this.replies)
        const percentage = ((points / this.totalPoints) * 100).toFixed(2);
        return `${percentage}%`;
      },
      formatTime(seconds) {

        const totalSeconds = Math.floor(seconds); // Redondear correctamente
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;

        return minutes > 0
            ? `${minutes}m${remainingSeconds}s`
            : `${remainingSeconds}s`;
        },
      showDetails(item) {
        console.log(this.questions)
        this.currentReply = item;
        console.log(item)
        this.showModal = true;
      }
    }
  };
  </script>