<template>
    <div>
      <!-- Tabs para navegar entre secciones -->
      <v-tabs v-model="currentTab" grow color="deep-purple">
        <v-tab v-for="(section, index) in sections" :key="index">
          <v-icon left>mdi-book-open</v-icon>
          Texto {{ index + 1 }}
        </v-tab>
      </v-tabs>
  
      <!-- Contenido de las secciones -->
      <v-tabs-items touchless v-model="currentTab">
        <v-tab-item v-for="(section, index) in sections" :key="index">
          <div class="pa-4">
            <!-- Texto de la sección -->
            <v-card class="mt-4 pa-2">
              <v-card-title class="primary white--text">
                <v-icon left>mdi-text</v-icon>
                {{ section.text.title }}
              </v-card-title>
              <v-card-title>{{ section.text.author }}, {{ section.text.publicationYear }}</v-card-title>
                
  
              <!-- Contenido del texto -->
              <v-card-text>
                <div v-for="(paragraph, index) in section.text.paragraph" :key="index" class="book-paragraph">
                  <div v-if="paragraph.subTitle" :key="index" class="book-paragraph text-h5" style="color: #00bfff;">
                    {{ paragraph.subTitle }}
                  </div>
                  {{ paragraph.description }}
                  <div  v-if="paragraph.id === 108" class="image-container" style="max-width: 600px;">
                    <v-img class="contained-image"   :src="imagen1"></v-img>
                  </div>
                  <div  v-if="paragraph.id === 110"  class="image-container" style="max-width: 700px;">
                    <v-img class="contained-image"  :src="imagen2"></v-img>
                  </div>
                  <div   v-if="paragraph.id === 424" class="image-container" style="max-width: 600px;">
                    <v-img class="contained-image" :src="imagen1"></v-img>
                  </div>
                  <div v-if="paragraph.id === 426"  class="image-container" style="max-width: 700px;">
                    <v-img class="contained-image"  :src="imagen2"></v-img>
                  </div>
                </div>
              </v-card-text>
            </v-card>
  
            <!-- Preguntas de la sección -->
            <div v-for="(question, questionIndex) in section.questions" 
                 :key="question.id" 
                 class="ma-4">
              <v-card>
                <v-card-title class="white--text book-paragraph primary"> 
                    
                    <p><v-icon left color="white">mdi-comment-question</v-icon> {{ `Pregunta ${questionIndex + 1}: ${question.text}` }}</p>
                    <div  class="image-container" v-if="question.id ==='2_10'" >
                        <v-img class="contained-image" style="max-width: 700px;" :src="imagen3"></v-img>
                    </div>
                        
                    <p v-if="question.id ==='2_10'">De acuerdo con el gráfico y la información que proporciona el texto, ¿cuál(es) de los
                        enunciados que siguen describe(n) de manera correcta los resultados obtenidos en el
                        ensayo?</p>
                    <v-spacer></v-spacer>
                </v-card-title>
  
                <!-- Opciones de respuesta -->
                <div class="px-4 ml-3">
                    <!-- Verificar si la pregunta tiene afirmaciones múltiples -->
                    <div class="mt-3" v-if="question.affirmations">
                        <ul>
                        <li class="book-paragraph" v-for="(affirmation) in question.affirmations" :key="affirmation.id">
                            <strong>{{ affirmation.id }}.</strong> {{ affirmation.text }}
                        </li>
                        </ul>
                    </div>
                    <v-radio-group :value="question.correctAnswer" readonly>
                        <v-radio v-for="option in question.options" 
                                :key="option.id"
                                :value="option.id"
                                color="success"
                                disabled>
                        <template v-slot:label>
                            <span :class="{ 'correct-answer': option.id === question.correctAnswer }">
                            <div class="px-2">{{ option.text }} <v-icon v-if="option.id === question.correctAnswer" 
                                    color="success">
                                mdi-check
                            </v-icon></div>
                            </span>
                        </template>
                        </v-radio>
                  </v-radio-group>
                </div>
              </v-card>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </template>
  
  <script>
  import imagen1 from "@/assets/images/LectumA_1.png";
  import imagen2 from "@/assets/images/LectumA_2.png";
  import imagen3 from "@/assets/images/LectumA_3.png";
  import axios from "axios";
  
  export default {
    data() {
      return {
        currentTab: 0,
        sections: [],
        imagen1,
        imagen2,
        imagen3,
        lectumType: ''
      };
    },
    async created() {
      await this.loadData();
    },
    methods: {
      async loadData() {
        try {
          const type = this.$route.path.includes('LectumA') ? 'Lectum' : 'LectumB';
          
          const [textsRes, questionsRes] = await Promise.all([
            axios.get(type === 'Lectum' ? "lectum" : "lectumB"),
            axios.get(`question${type}`)
          ]);
  
          this.sections = textsRes.data.texts.map((text, index) => ({
            text: {
              ...text,
              paragraph: text.paragraph.map(p => ({
                ...p,
                image: this.getParagraphImage(p.id)
              }))
            },
            questions: questionsRes.data.questionsBySection[index].map(q => ({
              ...q,
              options: q.options.map(option => ({
                ...option,
                isCorrect: option.id === q.correctAnswer
              }))
            }))
          }));
        } catch (error) {
          console.error("Error loading data:", error);
        }
      },
      getParagraphImage(id) {
        const images = {
          108: this.imagen1,
          110: this.imagen2,
          424: this.imagen1,
          426: this.imagen2
        };
        return images[id];
      }
    }
  };
  </script>

