<template>
  <v-container fluid>

    <v-tabs v-model="activeTab">
      <v-tab href="#tab-1">Textos</v-tab>
      <v-tab href="#tab-2">Crear textos</v-tab>
    </v-tabs>

    <v-tabs-items touchless v-model="activeTab">
      <v-tab-item class="mt-5 pa-6" value="tab-1">
          <v-row class="d-flex justify-center" v-if="!loading && this.textsData.length === 0">
            <div class="image-container">
              <v-img class="contained-image" :src="require('@/assets/images/nodata.jpg')"></v-img>
            </div>
          </v-row>
          <v-row class="d-flex justify-center" v-if="!loading && this.textsData.length === 0">
            <v-btn class="text-center responsive-button" color="primary" @click="activeTab='tab-2'">Actualmente no posee Textos Creados, haga click para crear uno</v-btn>
          </v-row>
          <v-row v-if="!loading && this.textsData.length > 0">
            <v-autocomplete
              @change="textDetailsBar"
              :items="textsData"
              item-text="title"
              outlined
              dense
              label="Buscar Texto"
              return-object
            ></v-autocomplete>
          </v-row>
          <v-row class="align-stretch" v-if="!loading && this.textsData.length > 0">
            <v-col class="d-flex justify-center card-grid-container" v-for="(text, index) in showableArray" :key="index" cols="12" sm="6" md="4" lg="3">
              <card-component
                :title="text.title[0].toUpperCase() + text.title.slice(1)"
                :description="text.description[0].toUpperCase() + text.description.slice(1)"
                :image="text.image ? 'text' : 'default'" 
                :customImage="isValidImage(text.image) ? text.image : null"
              >
                <template v-slot:polls>
                  <question-form :text-id="parseInt(text.id)" />
                  <v-btn @click="openShareModal(text.id)"  class="responsive-button mr-2" color="primary">Compartir</v-btn>
                </template>
                <template v-slot:activities>
                  <v-btn @click="textDetails(text.id)"  class="responsive-button mr-2" color="success">Ver Detalles</v-btn>
                  <v-btn 
                    class="responsive-button"
                    @click="confirmDeleteText(text.id)" 
                    color="red" 
                    outlined
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </card-component>
            </v-col>
          </v-row>
          <v-row v-if="loading" justify="center">
            <v-col class="d-flex justify-center align-center">
              <v-progress-circular size="200" :width="3" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
          <v-container fluid class="d-flex justify-center align-center" v-if="!loading && textsData.length > 0">
            <v-btn
              v-for="(index, i) in paginate"
              :key="i"
              @click="current_page = index - 1"
              elevation="3"
              color="primary"
              fab
              style="margin-right: 10px"
            >
              {{ index }}
            </v-btn>
          </v-container>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <text-form></text-form>
      </v-tab-item>
    </v-tabs-items>
    <share-text
      :show-share-modal="showShareModal"
      :text-id="selectedTextId"
      @update:show-share-modal="showShareModal = $event"
    />
  </v-container>

</template>

<script>
import CardComponent from "@/components/UI/CardComponent";
import TextForm from "@/components/teacher/text/forms/TextForm";
import QuestionForm from "@/components/teacher/text/forms/QuestionForm";
import ShareText from  "@/components/teacher/text/forms/ShareText";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    CardComponent,
    TextForm,
    QuestionForm,
    ShareText
  },
  computed: {
    textsData() {
      return this.$store.getters['teacher/getTexts'];
    },
    paginate() {
      return Math.ceil(this.textsData.length / this.items_per_page);
    },
    showableArray() {
      return this.textsData.slice(
        this.current_page * this.items_per_page,
        this.items_per_page + this.current_page * this.items_per_page
      );
    },
  },
  data() {
    return {
      loading: null,
      items_per_page: 8,
      current_page: 0,
      showShareModal: false,
      selectedTextId: null,
      activeTab: "tab-1", // Set the default active tab
    };
  },
  methods: {
    openShareModal(textId) {
      this.selectedTextId = textId;
      this.showShareModal = true;
    },

    textDetailsBar(text) {
      this.$router.push('/profe/textos/' + text.id);
    },
    textDetails(id) {
      this.$router.push('/profe/textos/' + id);
    },
    isValidImage(image) {
      if (!image || typeof image !== 'string') {
        return false;
      }

      const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      const imageExtension = image.split('.').pop().toLowerCase();

      return validExtensions.includes(imageExtension);
    },
    async getTexts() {
      await this.$store.dispatch('teacher/texts');
    },
    async confirmDeleteText(textId) {
      const result = await Swal.fire({
        title: '¿Está seguro de eliminar este texto?',
        text: "¡Esta acción eliminará permanentemente el texto y todas sus actividades relacionadas!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        this.deleteText(textId);
      }
    },
    async deleteText(textId) {
      try {
        const response = await axios.delete(`teacher/text/${textId}`);
        if (response.data.ok) {
          Swal.fire(
            'Eliminado',
            'El texto y sus actividades relacionadas han sido eliminados.',
            'success'
          );
          this.getTexts();
        } else {
          throw new Error(response.data.error || 'Error al eliminar el texto');
        }
      } catch (error) {
        console.error('Error al eliminar texto:', error);
        if (error.response && error.response.status === 403) {
          Swal.fire(
            'No autorizado',
            'No tiene permiso para eliminar este texto.',
            'error'
          );
        } else {
          Swal.fire(
            'Error',
            error.message || 'Hubo un problema al eliminar el texto',
            'error'
          );
        }
      }
    }
  },
  async created() {
    this.loading = true;
    await this.getTexts();
    this.loading = false;
  },
};
</script>
