  <template>
    <v-dialog v-model="dialog" max-width="900px">
      <v-card>
        <div class="sticky-title">
          <v-card-title class="headline primary white--text">Agregar Sección</v-card-title>
          <v-divider class="my-2"></v-divider>
          <v-text-field class="mx-4 text-subtitle-1" v-model="section.title" label="Título de la Sección" required></v-text-field>
        </div>
        <v-card-text>
          <v-divider class="mt-1"></v-divider>
          
          <div style="background-color: rgba(63, 81, 181, 0.8);" class="d-flex justify-space-between align-center">
            <v-card-title style="color: white;">Preguntas</v-card-title>
            <v-btn class="mr-4" color="primary" @click="addQuestion">Agregar Pregunta</v-btn>
          </div>
          

          <div v-for="(question, index) in section.questions" :key="index" class="mb-4">
            <v-divider></v-divider>
            <v-row class="d-flex justify-space-between align-center">
              <v-col cols="9"  class="mx-4">
                <v-text-field v-model="question.description" :label="'Pregunta ' + (index + 1)" required></v-text-field>
                <v-select v-model="question.type" :items="questionTypes" label="Tipo de pregunta" required></v-select>
                <div max-height="40px" v-if="question.type === 2">
                  <v-alert type="info">Ingrese el puntaje en el apartado inferior</v-alert>
                </div>

                <div v-else-if="question.type === 1">
                  <v-alert type="info">Las alternativas se agregan en el apartado inferior</v-alert>
                </div>
              </v-col>
              
              <v-col cols="1" class="d-flex flex-column">
                <v-btn icon @click="moveQuestion(index, -1)" :disabled="index === 0">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn icon @click="moveQuestion(index, 1)" :disabled="index === section.questions.length - 1">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1" >
                <v-btn icon color="red" @click="removeQuestion(index)"><v-icon>mdi-delete</v-icon></v-btn>
              </v-col>
            </v-row>
            
            <v-divider class="my-2"></v-divider>
            
          </div>

          <div   v-if="hasYesNoQuestion">
            <v-card-title style="background-color: rgba(63, 81, 181, 0.8); color: white;" >Puntaje asociado a todas las preguntas de Sí y No</v-card-title>
            <v-text-field class="mx-4" v-model="section.yesPoints" label="Puntos por 'Sí'" type="number" required></v-text-field>
            <v-text-field class="mx-4" v-model="section.noPoints" label="Puntos por 'No'" type="number" required></v-text-field>
          </div>

          <v-divider></v-divider>
          <div style="background-color: rgba(63, 81, 181, 0.8);" class="d-flex justify-space-between align-center">
            <v-card-title style="color: white;" >Alternativas globales de la sección</v-card-title>
            <v-btn class="mr-4" color="primary" @click="addAnswer">Agregar Alternativa</v-btn>
          </div>

          <v-divider></v-divider>

          <div class="ma-4" v-for="(answer, aux) in section.answers" :key="aux">
            <div class="d-flex justify-space-between align-center">
              <v-text-field v-model="answer.description" :label="'Alternativa ' + (aux + 1)" required></v-text-field>
              <v-btn class="ma-3" icon text color="red" @click="removeAnswer(aux)"><v-icon>mdi-delete</v-icon></v-btn>
            </div>
            <v-text-field v-model="answer.points" label="Puntos" type="number" required></v-text-field>
            <v-divider></v-divider>
          </div>

          <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error"  @click="close">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary"  @click="validateAndSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <script>
  export default {
  props: {
    dialog: Boolean,
    section: Object,
  },
  data() {
    return {
      questionTypes: [
        { value: 1, text: 'Alternativas' },
        { value: 2, text: 'Sí o No' },
        { value: 3, text: 'Desarrollo' }
      ],
      yesPoints: 0,
      noPoints: 0,
      errorMessage: ""
    };
  },
  computed: {
    hasYesNoQuestion() {
      return this.section.questions.some(question => question.type === 2);
    }
  },
  methods: {
    addQuestion() {
      const order = this.section.questions.length + 1;
      this.section.questions.push({ description: "", order: order, type: 1, section: this.section.title ? this.section.title : null });
    },
    addAnswer() {
      this.section.answers.push({ description: "", points: 0, section: this.section.title ? this.section.title : null });
    },
    removeAnswer(index) {
      this.section.answers.splice(index, 1);
    },
    removeQuestion(index) {
      this.section.questions.splice(index, 1);
      this.updateOrder();
    },
    moveQuestion(index, direction) {
      const newIndex = index + direction;
      const temp = this.section.questions[index];
      this.section.questions[index] = this.section.questions[newIndex];
      this.section.questions[newIndex] = temp;
      this.section.questions = [...this.section.questions];
      this.updateOrder();
    },
    updateOrder() {
      // Ordenar preguntas por el campo 'order'
      this.section.questions.forEach((question, i) => {
        question.order = i + 1;
      });
    },
    validateAndSave() {
      if (!this.validateSection()) {
        return;
      }
      this.save();
    },
    validateSection() {
      this.errorMessage = "";

      if (!this.section.questions.length) {
        this.errorMessage = "La sección debe tener al menos una pregunta.";
        return false;
      } 
      if (this.section.questions.some(question => !question.description.trim())) {
        this.errorMessage = "Cada pregunta debe tener un título.";
        return false;
      }
      if (this.section.answers.length < 2) {
        this.errorMessage = "Debe haber al menos dos alternativas.";
        return false;
      } 
      if (this.section.answers.some(answer => !answer.description.trim())) {
        this.errorMessage = "Cada alternativa debe tener una descripción.";
        return false;
      }

      if (this.hasYesNoQuestion) {
        if (this.section.yesPoints === 0 || this.section.noPoints === 0) {
          this.errorMessage = "Debe ingresar el puntaje asociado a las preguntas de Sí y No.";
          return false;
        }
      }



      return true;
    },
    save() {
      if (!this.section.title) {
        this.section.title = `Sección ${this.$emit('generateSectionNumber')}`;
      }
      this.updateOrder();
      this.$emit('save', this.section);
    },
    close() {
      this.$emit('close');
    },
  }
  };
  </script>

