import TeacherScreen from "../pages/teacher/TeacherScreen";
import TeacherLessons from "../pages/teacher/lesson/TeacherLessons";
import TeacherTexts from "../pages/teacher/text/TeacherTexts";
import LessonDetail from "../pages/teacher/lesson/LessonDetail";
import TextDetailTeacher from "../pages/teacher/text/TextDetail";
import TeacherPolls from "../pages/teacher/poll/TeacherPolls";
import PollDetail from "../pages/teacher/poll/PollDetail";
import TeacherAdmin from "../pages/teacher/admin/TeacherAdmin.vue";
import PollLectumDetail from "../pages/teacher/poll/PollLectumDetail";
import PollInteramericanaDetail from "../pages/teacher/poll/PollInteramericanaDetail";

import store from "../store";

export const teacherRoute = {
  path: "/profe",
  redirect: "profe/asignaturas",
  component: TeacherScreen,
  async beforeEnter(to, from, next) {
    await store
      .dispatch("userInfo")
      .then(() => {
        if (store.getters.getUserInfo.rol === 0) next("estudiante");
        else if (store.getters.getUserInfo.rol === 1) next();
        else next("login");
      })
      .catch(() => next("login"));
  },
  children: [
    { path: "diagnosticos", component: TeacherPolls },
    { path: "diagnostico/LectumA/:id", component: PollLectumDetail },
    { path: "diagnostico/LectumB/:id", component: PollLectumDetail },
    {
      path: "diagnostico/Interamericana/:id",
      component: PollInteramericanaDetail,
    },
    { path: "asignaturas", component: TeacherLessons },
    { path: "textos", component: TeacherTexts },
    { path: "administracion", component: TeacherAdmin },
    { path: "asignaturas/:id", component: LessonDetail, props: true },
    { path: "textos/:id", component: TextDetailTeacher, props: true },
    { path: "diagnosticos/:id", component: PollDetail, props: true },
  ],
};
