<template>
  <v-dialog persistent v-model="dialog" max-width="80%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ma-2" v-if="bottomStyle" color="primary" v-bind="attrs" v-on="on">
        Crear Pregunta
      </v-btn>
      <v-btn class="ma-2" v-else text block v-bind="attrs" v-on="on">
        Crear Pregunta
      </v-btn>
    </template>

    <v-container fluid>
      <v-row justify="center">
        <v-col justify="center">
          <v-card>
            <v-card-title class="primary white--text">Crear Pregunta</v-card-title>

            <v-form @submit.prevent="submit" ref="form" lazy-validation>
              <v-card-text>
                <v-autocomplete @change="changeType" v-model="form.type" :items="type" item-text="name"
                  prepend-icon="mdi-format-text" label="Tipo de Pregunta" required return-object></v-autocomplete>
                <v-autocomplete v-model="form.difficulty" :items="difficulty" item-text="name"
                  prepend-icon="mdi-numeric-1-circle-outline" label="Dificultad" required return-object>
                </v-autocomplete>
                <v-text-field v-model="form.title" prepend-icon="mdi-format-title" label="Título Pregunta">
                </v-text-field>
                <v-text-field type="number" v-model="form.points" prepend-icon="mdi-counter" label="Puntaje">
                </v-text-field>
                <v-textarea prepend-icon="mdi-content-copy" v-model="form.description" auto-grow filled
                  color="deep-purple" label="Descripción(Opcional)" rows="5"></v-textarea>
                <v-text-field @change="updateAlternatives" type="number" v-model="alternativesNumber" v-if="
                  form.type && (form.type.id !== 0)
                " prepend-icon="mdi-format-title" label="Cantidad de Alternativas"></v-text-field>
                <v-file-input
                    accept="image/*"
                    placeholder="Seleccione una Imagen(Opcional)"
                    prepend-icon="mdi-camera"
                    v-model="form.image"
                    label="Añadir imagen"
                  ></v-file-input>
                <h2 v-if="form.alternatives.length > 0" class="my-3 text-center justify">
                  Seleccione la/s alternativa/s correcta/s haciendo click en
                  el botón a la izquierda.
                </h2>

                <v-row v-for="(_, key) in form.alternatives.length" :key="key">
                  <v-col cols="1" class="mt-4">
                    <v-btn v-if="!form.alternatives[key].isCorrect" @click="changeCorrectValue(key)" color="#B71C1C"
                      dark class="ml-4">
                      <v-icon dark fab> mdi-cancel </v-icon>
                    </v-btn>

                    <v-btn v-else @click="changeCorrectValue(key)" color="#43A047" dark class="ml-4">
                      <v-icon dark fab> mdi-check </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="10">
                    <v-text-field class="mr-10" v-model="form.alternatives[key].description"
                      prepend-icon="mdi-format-cancel" :label="`Alternativa ${key + 1}`">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-btn color="error" @click="cancel"> Cancelar </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text type="submit">
                  Crear Pregunta
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-center ma-2">
      <v-snackbar :color="color" v-model="snackbar">
        {{ text }}.

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    textId: {
      type: Number,
    },
    bottomStyle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      snackbar: false,
      color: "",
      text: "",
      dialog: false,
      type: [
        { id: 0, name: "Desarrollo" },
        { id: 1, name: "Selección Múltiple (una respuesta correcta)" },
        { id: 2, name: "Selección Múltiple (varias respuestas correctas)" },
      ],
      difficulty: [
        { id: 0, name: "Fácil" },
        { id: 1, name: "Intermedio" },
        { id: 2, name: "Difícil" },
      ],
      alternativesNumber: null,
      form: {
        image: null,
        textId: null,
        title: "",
        difficulty: null,
        type: null,
        description: "",
        alternatives: [],
        points: null,
      },
    };
  },
  watch: {
    alternativesNumber(newVal, oldVal) {
      if (newVal !== oldVal && newVal > 0) {
        this.updateAlternatives();
      }
    },
  },
  methods: {
    changeType(type) {
      this.alternativesNumber = null;
      if (type.id === 0) {
        this.form.alternatives = [];
      }
    },
    changeCorrectValue(key) {
      if (this.form.type.id === 1) {
        this.form.alternatives.map((data, id) => {
          if (id === key) return (data.isCorrect = !data.isCorrect);
          if (id !== key) return (data.isCorrect = false);
        });
      }

      if (this.form.type.id === 2) {
        this.form.alternatives.map((data, id) => {
          if (id === key) return (data.isCorrect = !data.isCorrect);
        });
      }
    },
    updateAlternatives() {
      const newAlternatives = [];
      for (let i = 0; i < this.alternativesNumber; i++) {
        newAlternatives.push(this.form.alternatives[i] || { description: "", isCorrect: false });
      }
      this.form.alternatives = newAlternatives;
    },
    setSnackBar(color, text, snack) {
      this.color = color;
      this.text = text;
      this.snackbar = snack;
    },
    async resetForm() {
      this.form = {
        textId: null,
        image: null,
        title: "",
        difficulty: null,
        type: null,
        description: "",
        alternatives: [],
        points: null,
      };
      this.alternativesNumber = null;
    },
    submit() {
      if (
        this.form.title === "" ||
        this.form.difficulty === null ||
        this.form.type === null ||
        this.form.points === null
      ) {
        this.setSnackBar("red", "Faltan Datos", true);
        return;
      }

      if (this.form.type.id === 1 || this.form.type.id === 2) {
        if (this.form.alternatives.some((val) => val.description === "")) {
          this.setSnackBar("red", "Todas las alternativas deben tener descripción", true);
          return;
        }
        if (!this.form.alternatives.some((val) => val.isCorrect === true)) {
          this.setSnackBar("red", "Debe existir al menos una respuesta correcta", true);
          return;
        }
        if (this.form.alternatives.length < 2) {
          this.setSnackBar("red", "Debe haber al menos dos alternativas", true);
          return;
        }
      }

      const formData = new FormData();
      formData.append("textId", this.textId);
      formData.append("title", this.form.title);
      formData.append("difficulty", this.form.difficulty.id);
      formData.append("type", this.form.type.id);
      formData.append("description", this.form.description || "");
      formData.append("points", this.form.points);
      
      if (this.form.image) {
        formData.append("image", this.form.image);
      }

      this.form.alternatives.forEach((alt, index) => {
        formData.append(`alternatives[${index}][description]`, alt.description);
        formData.append(`alternatives[${index}][isCorrect]`, alt.isCorrect ? 1 : 0);
      });

      for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
      
      this.$store.dispatch("teacher/createQuestion", formData)
        .then(() => {
          this.$store.dispatch("teacher/questions", parseInt(this.textId));
          this.setSnackBar("green", "Pregunta creada con éxito", true);
          this.resetForm();
        })
        .catch(() => {
          this.setSnackBar("red", "Error al crear la pregunta", true);
        });
    },
    async cancel() {
      await this.resetForm();
      this.dialog = false;
    },
  },
};
</script>
