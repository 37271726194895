<template>
  <v-card max-width="90vw" class="overflow-y-auto">
    
      <v-card-title class="primary white--text">
        <v-btn class="mr-2" icon @click="closeModal">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        {{ questionType }}
      </v-card-title>

      <v-card-text class="pa-6">
        <v-row>
          <v-col cols="4">
            <v-text-field label="Título" v-model="localQuestion.title"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete 
              v-model="localQuestion.difficulty" 
              :items="difficulty"
              item-text="name"
              item-value="id"
              prepend-icon="mdi-numeric-1-circle-outline"
              label="Dificultad"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Puntaje" type="number" v-model="localQuestion.points"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="px-4">
          <v-file-input
            :loading="loading"
            accept="image/*"
            placeholder="Seleccione una Imagen"
            prepend-icon="mdi-camera"
            v-model="localQuestion.image"
            label="Añadir imagen"
          ></v-file-input>
          <v-btn 
              v-if="localQuestion.image"
              @click="localQuestion.image = null"
              color="error"
              class="ml-2"
          >
              Eliminar Imagen
          </v-btn>
        </v-row>
        <div v-if="localQuestion.image" class="image-container">
          <v-img class="contained-image" height="40%" width="40%" :src="localQuestion.image"></v-img>
        </div>

        <v-row class="scrollable-content" v-for="(alternative, i) in localAnswers" :key="i" justify="start" align="center">
          <v-col cols="1">
            <v-btn
              @click="changeCorrectValue(alternative)"
              :color="alternative.isCorrect ? '#43A047': '#B71C1C'"
              dark
            >
              <v-icon dark fab>
                {{ alternative.isCorrect ? 'mdi-check' : 'mdi-cancel' }}
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <v-text-field
              v-model="alternative.description"
              prepend-icon="mdi-format-cancel"
              :label="`Alternativa ${i + 1}`"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9">
            <v-text-field name="input-7-4" label="Descripción" v-model="question.description"></v-text-field>
          </v-col>
          <v-col cols="3" class="d-flex justify-center align-center ga-2">
            <v-btn class="ma-2 white--text" color="red" @click="closeModal">
              Cancelar
            </v-btn>
            <v-btn class="ma-2 white--text" color="deep-purple accent-4" @click="editQuestion">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
        
      </v-card-text>

    <v-snackbar :color="color" v-model="snackbar">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    questionId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      text: '',
      color: null,
      snackbar: false,
      type: [
        { id: 0, name: "Desarrollo" },
        { id: 1, name: "Selección Múltiple (una respuesta correcta)" },
        { id: 2, name: "Selección Múltiple (varias respuestas correctas)" },
      ],
      difficulty: [
        { id: 0, name: "Fácil" },
        { id: 1, name: "Intermedio" },
        { id: 2, name: "Difícil" },
      ],
      loading: false,
      localQuestion: null,
      localAnswers: null
    };
  },
  watch: {
    questionId: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          await this.loadQuestion();
        }
      }
    }
  },
  computed: {
    question() {
      return this.$store.getters['teacher/getQuestion'];
    },
    answers() {
      return this.$store.getters['teacher/getAnswers'];
    },
    questionType() {
      switch (this.localQuestion?.type) {
        case 0: return 'Desarrollo';
        case 1: return 'Selección Múltiple (una respuesta correcta)';
        case 2: return 'Selección Múltiple (varias respuestas correctas)';
        default: return '';
      }
    }
  },
  methods: {
    createLocalCopies() {
      this.localQuestion = JSON.parse(JSON.stringify(this.question));
      this.localAnswers = JSON.parse(JSON.stringify(this.answers));
    },
    async loadQuestion() {
      await this.$store.dispatch('teacher/question', this.questionId);
      this.createLocalCopies();
    },
    closeModal() {
      this.text = '';
      this.color=  null;
      this.snackbar = false;
      this.loading= false;
      this.$emit('close');
    },
    async editQuestion() {
    try {
        // 1. Actualizar datos de la pregunta (JSON)
        await axios.put(
            `teacher/question/update/${this.questionId}`,
            {
                title: this.localQuestion.title,
                difficulty: parseInt(this.localQuestion.difficulty),
                points: parseInt(this.localQuestion.points),
                description: this.localQuestion.description || '',
                textId: this.localQuestion.textId.toString(),
                answers: this.localAnswers.map(answer => ({
                    id: answer.id.toString(),
                    description: answer.description,
                    isCorrect: answer.isCorrect
                }))
            },
            {
                headers: {
                    'Content-Type': 'application/json' // ✅ Enviar como JSON
                }
            }
        );

        // 2. Si hay imagen, subirla en otra llamada
        if (this.localQuestion.image instanceof File) {
            const formData = new FormData();
            formData.append('image', this.localQuestion.image);
            
            await axios.put(
                `teacher/question/uploadImage/${this.questionId}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
        }

        this.showNotification('green', '¡Pregunta actualizada!');
        
    } catch (error) {
        console.error('Error detallado:', error.response?.data);
        this.showNotification('red', error.response?.data?.error || 'Error al guardar');
    }
},
    showNotification(color, text) {
      this.color = color;
      this.text = text;
      this.snackbar = true;
    },
    changeCorrectValue(alternative) {
      const index = this.localAnswers.findIndex(a => a.id === alternative.id);
      if (index !== -1) {
        // Si es de tipo selección única, deselecciona los demás
        if (this.localQuestion.type === 1) {
          this.localAnswers.forEach((a, i) => {
            this.$set(this.localAnswers, i, { ...a, isCorrect: i === index });
          });
        } else {
          // Si es selección múltiple, solo invierte el estado de la alternativa seleccionada
          this.$set(this.localAnswers, index, {
            ...this.localAnswers[index],
            isCorrect: !this.localAnswers[index].isCorrect
          });
        }
      }
    }

  }
};
</script>
