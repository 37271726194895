<template>
    <v-card width="100%">
      <v-text-field class="px-5" v-model="search" append-icon="mdi-magnify" 
                   label="Buscar estudiante" single-line hide-details></v-text-field>
      <v-data-table height="300px" fixed-header :search="search" 
                  :headers="headers" :items="processedReplies" 
                  item-key="id" class="m-3 elevation-1">
        <template v-slot:no-data>
          <p>Nadie ha realizado la actividad.</p>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="showDetails(item)">
            <v-icon color="primary" outlined>mdi-eye</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.percentage="{ item }">
          {{ calculatePercentage(item.points) }}
        </template>
      </v-data-table>
  
      <NormalStudentReply
          :modalOpen.sync="showModal"
          :reply="currentReply"
          :pollActivity="pollActivity"
          @closed="showModal = false"
        />
    </v-card>
  </template>
  
  <script>
  import NormalStudentReply from './NormalStudentReply.vue';
  
  export default {
    props: {
      replies: {
        type: Array,
        required: true
      },
      totalPoints: {
        type: Number,
        default: 50
      },
      pollActivity: {
        type: Object,
        required: true
      }
    },
    components: { NormalStudentReply },
    data() {
      return {
        search: '',
        showModal: false,
        currentReply: null,
        headers: [
          { text: 'Nombre', value: 'student.name', sortable: false },
          { text: 'Apellido', value: 'student.lastName', sortable: false },
          { text: 'Puntos', value: 'points', sortable: false },
          { text: 'Nota %', value: 'percentage', sortable: false },
          { text: 'Ver datos', value: 'actions', sortable: false }
        ]
      };
    },
    computed: {
      processedReplies() {
        return this.replies.map(reply => ({
          ...reply,
          percentage: this.calculatePercentage(reply.points)
        }));
      }
    },
    methods: {
      calculatePercentage(points) {
        const percentage = ((points / this.totalPoints) * 100).toFixed(2);
        return `${percentage}%`;
      },
      showDetails(item) {
        this.currentReply = item;
        this.showModal = true;
      }
    }
  };
  </script>