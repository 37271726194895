<template>
  <v-container fluid>
    <v-row class="align-stretch " v-if="!loading && activities.length > 0">
      <v-col class="d-flex justify-center card-grid-container" v-for="(activity, i) in activities" :key="i" cols="12" sm="6" md="4" lg="3">
        <card-component :title="`Responder antes del: ${activity.date}`" :description="activity.description"
          image="activity">
          <template v-slot:activities>
                <v-btn class="responsive-button mb-3"  color="primary" @click="goToText(activity.id)">Ver</v-btn>
          </template>
          <template v-slot:polls>
            <v-chip color="warning">
              Tiempo limite: {{ activity.timeLimit ? activity.timeLimit+"min" : 'Sin tiempo'}}
            </v-chip>
          </template>
         
        </card-component>
      </v-col>
    </v-row>

    <loading-component v-if="loading" />

    <v-row v-if="!loading && activities.length === 0" style="height: 60vh" align="center">
      <v-col>
        <v-img height="60%" width="60%" :src="require('@/assets/images/undraw_empty_street_sfxm.svg')"></v-img>
        <p class="mt-5">Aún no tiene actividades completadas.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardComponent from "@/components/UI/CardComponent";
import LoadingComponent from "@/components/UI/LoadingComponent";
export default {
  props: ['loading'],
  components: { LoadingComponent, CardComponent },
  data() {
    return {
    }
  },
  computed: {
    activities() {
      return this.$store.getters['student/getStudentActivitiesReply']
    },
  },
  methods: {
    goToText(id) {
      this.$router.push('/estudiante/finalizada/' + id);
    }
  },

}
</script>