<template>
  <v-container fluid>
    <div v-if="!loading && lessonsData.length === 0">
      <div class="image-container">
        <v-img class="contained-image"  :src="require('@/assets/images/nodata.jpg')"></v-img>
      </div>
      <p class="text-center">Actualmente no posee asignaturas creadas</p>
      <lesson-form class="mt-2 responsive-button" />
    </div> 

    <v-row v-if="!loading && lessonsData.length > 0"  fluid class="mt-8 justify-center"  style="flex-wrap: wrap-reverse;">
      <v-col cols="12" md="8" lg="7" class="mb-4 mb-md-0">
        <v-card class="h-100" outlined style="border: 0.2px solid #333;">
            <v-card-title >
              <v-toolbar flat>
                <v-toolbar-title>Lista de Asignaturas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar asignatura" single-line hide-details>
                </v-text-field>

              </v-toolbar>
            </v-card-title>
            <v-data-table :search="search" :headers="headers" :items="lessonsData" item-key="id" class="elevation-1 v-size-full v-fill-height" height="350px">
              <template v-slot:item.actions="{ item }">
                <v-icon color="primary" class="mr-2" @click="toDetails(item)">
                  mdi-magnify
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="3">
          <v-card  class="h-100 d-flex flex-column justify-center" outlined style=" height: 100%; border: 0.2px solid #333;">
            <v-card-actions >
              <v-col >
                <v-row class="py-5 d-flex justify-center align-center">
                  <lesson-form :bottom-style="true"/>
                </v-row>
                <v-row class="py-5 d-flex justify-center align-center">
                  <DeleteLesson  :lessons="lessonsData"></DeleteLesson>
                </v-row>
                
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      

      <v-row  justify="center">
        <v-col class="d-flex justify-center align-center">
          <v-progress-circular
              v-if="loading"
              size="200"
              :width="3"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

  </v-container>
</template>

<script>

import LessonForm from "@/components/teacher/lesson/forms/LessonForm";
import DeleteLesson from '@/components/teacher/lesson/forms/DeleteLesson.vue';

export default {

  data() {
    return {
      search: '',
      headers: [],
      loading: null,
      items_per_page: 8,
      current_page: 0,
      showModal: false,
    }
  },

  components: { LessonForm, DeleteLesson },
  computed: {
    lessonsData() {
      return this.$store.getters['teacher/getLessons'];
    },
  },

  methods: {
    async getLessons() {
      this.loading = true;
      await this.$store.dispatch('teacher/lessons');
      this.loading = false;

    },
    openModal() {
      this.showModal = true;
    },
    toDetails({id}) {
      this.$router.push('/profe/asignaturas/' + id);
      },
    fetchSubjects() {
      this.getLessons();
    }
  },
  created() {
    this.getLessons();
    this.headers.unshift(
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Descripción',
        align: 'start',
        value: 'description',
        sortable: false,
        cellClass: 'truncate-cell'
      },
      {
        text: 'Año',
        value: 'year',
        sortable: false
      },
      {
        text: 'Periodo',
        value: 'period',
        sortable: false
      },
      {
        text: 'Ver',
        value: 'actions',
        sortable: false
      },


    )
  }
}
</script>
