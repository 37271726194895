<template>
  <v-card>
    <v-card-title class="d-flex flex-column flex-md-row justify-space-between primary white--text">
      <div class="text-center text-md-left mb-2 mb-md-0">Estudiantes parte de la asignatura</div>
      <div>
        <add-students
          @student-saved="getStudents"
          class="my-3 mt-md-6 mx-2 primary responsive-button"
        />
      </div>
    </v-card-title>

    <v-text-field
      class="pa-3"
      v-model="search"
      append-icon="mdi-magnify"
      label="Buscar Estudiantes"
      dense
      single-line
    ></v-text-field>

    <v-data-table
      height="230px"
      fixed-header
      :search="search"
      v-model="selected"
      :headers="headers"
      :items="students"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.delete="{ item }">
        <v-btn
          outlined 
          small
          color="error"
          @click="confirmDelete(item)"
          title="Eliminar estudiante"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <p>No tiene estudiantes en la asignatura.</p>
      </template>
    </v-data-table>

    <v-row class="d-flex flex-column flex-md-row justify-center align-center">
      <v-col cols="12" md="9" class="text-center">
        <v-card-title class=" text-justify text-md-center ">
          Agregar estudiantes a la asignatura y al software por medio de un Excel, 
          creando sus perfiles dentro del sistema automáticamente.
        </v-card-title>
      </v-col>
      <v-col cols="12" md="2" class="d-flex justify-center align-center mt-2">
        <div class="d-flex flex-column flex-md-row align-center gap-2">
          <ExcelTemplateAddStudent class="responsive-button"/>
          <info-button
            class="ml-2"
            title="Información sobre el ingreso de estudiantes por medio de un archivo Excel."
            message="
                  <ul>
                      <li>El archivo Excel debe contener 4 columnas, la primera con los o el nombre del estudiante, despues sus apellidos y por ultimo el email.</li>
                      <li>El archivo Excel debe contener un máximo de 50 estudiantes.</li>
                      <li>El archivo Excel debe tener el formato correcto, sin correos institucionales, de lo contrario no se añadirán los estudiantes.</li>
                      <li>El sistema creará los perfiles de los estudiantes automáticamente.</li>
                      <li>IMPORTANTE: Que cada estudiante revise su correo para ver la contraseña para entrar al sistema, podran cambiarla si asi lo desean.</li>
                  </ul>
              "
          ></info-button>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-4">
        <v-file-input
          :disabled="loading"
          :loading="loading"
          type="file"
          label="Formato excel. Máximo 50 estudiantes por archivo. Formato: primera columna nombre completo del estudiante, segunda columna email"
          @change="addStudents"
          prepend-inner-icon="mdi-file-excel"
          v-model="excel"
          class="responsive-input"
        >
        </v-file-input>
      </v-col>
      
    </v-row>

    <v-divider></v-divider>
  </v-card>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import AddStudents from "@/components/teacher/lesson/students/forms/AddStudents.vue";
import ExcelTemplateAddStudent from "@/components/UI/ExcelTemplateAddStudent.vue";
import InfoButton from "@/components/UI/InfoButton.vue";

export default {
  components: {
    ExcelTemplateAddStudent,
    AddStudents,
    InfoButton,
  },
  data: () => ({
    color: "success",
    text: "",
    snackbar: null,
    loading: false,
    excel: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "Nombre",
        align: "start",
        value: "name",
      },
      { text: "Apellido Paterno", value: "lastName" },
      { text: "Apellido Materno", value: "secondLastName" },
      { text: "E-mail", value: "email" },
      {
        text: "Eliminar",
        value: "delete",
        sortable: false,
        align: "center"
      }
    ],
    errorMessages: [],
  }),

  computed: {
    students() {
      return this.$store.getters["teacher/getStudentsInLesson"];
    },
  },

  created() {
    this.getStudents();
  },

  methods: {
    async confirmDelete(student) {
      const result = await Swal.fire({
        title: '¿Eliminar estudiante?',
        html: `Se eliminarán todas las respuestas de <strong>${student.name} ${student.lastName}</strong> en esta asignatura`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        try {
          await axios.delete(`teacher/lesson/students/${this.$route.params.id}`, {
            data: {
              studentIds: [student.id]
            }
          });
          
          await this.getStudents();
          
          Swal.fire(
            '¡Eliminado!',
            'El estudiante y sus respuestas han sido eliminados.',
            'success'
          );
        } catch (error) {
          Swal.fire(
            'Error',
            'No se pudo eliminar al estudiante: ' + error.response?.data?.error,
            'error'
          );
        }
      }
    },
    async addStudents() {
      if (!this.excel) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Debe seleccionar un archivo Excel.",
        });
        return;
      }

      this.loading = true;
      this.errorMessages = [];
      try {
        const blob = new Blob([this.excel], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const formData = new FormData();
        formData.append("excel", blob);
        formData.append("id", this.$route.params.id);
        const response = await axios.post("teacher/addStudent", formData);

        if (response.data.ok) {
          this.getStudents();
          Swal.fire({
            icon: "success",
            title: "Éxito",
            text: "Estudiantes añadidos correctamente.",
          });
          if (response.data.errors.length) {
            let errorList = "<ul>";
            response.data.errors.forEach((error) => {
              errorList += `<li>Fila: ${error.row} - Email: ${error.email} - Error: ${error.message}</li>`;
            });
            errorList += "</ul>";

            Swal.fire({
              icon: "error",
              title: "Errores al añadir estudiantes",
              html: errorList,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: e.response.data.message,
        });
      } finally {
        this.loading = false;
        this.excel = null;
      }
    },

    async getStudents() {
      let id = this.$route.params.id;
      await this.$store.dispatch("teacher/studentsInLesson", id);
    },
  },
};
</script>
