<template>
  <loading-component v-if="loading" />
  <div v-else>
    <v-card v-for="(question, index) in questions" :key="index" class="ma-4" width="100vw">
      <v-card-title>
        {{index + 1}}) {{ question.question.title }} ({{calculateScore(question, index)}}pts)
      </v-card-title>
      <v-card-text>
        <div class="text--primary">
          <p class="text-justify" v-if="question.question.description.length>0">
            {{ question.question.description }}
          </p>
          <p class="text-justify">
            Tiempo que se demoro en la pregunta : {{ getTimeFromId(question.question.id) }} segundos 
          </p>
        </div>
      </v-card-text>
      <v-container class="pa-5" fluid v-if="question.question.type === 0">
        <div disabled class="light-blue lighten-4" color="teal">
          <p class="pa-5" text-color="primary">
            Respuesta: {{ questionAnswers[index].answer }}
          </p>
        </div>
      </v-container>

      <v-container  class="pa-5" fluid v-if="question.question.type === 1">
        <v-radio-group>
          <v-radio disabled v-for="(alternative, i) in question.question.answer" :key="i" color="red"
            :off-icon="getIcon(alternative, findReply(questionAnswers[index]).answer)">
            <template v-slot:label>
              <h4 v-if="
                alternative.id === findReply(questionAnswers[index]).answer.id &&
                findReply(questionAnswers[index]).answer.isCorrect
              " class="text-left" style="color: green">
                {{
                alternative.isCorrect
                ? `${alternative.description} (Respuesta correcta)`
                : alternative.description
                }}
              </h4>
              <h4 v-if="
                findReply(questionAnswers[index]).answer.id === alternative.id &&
                !findReply(questionAnswers[index]).answer.isCorrect
              " class="text-left" style="color: red">
                {{
                alternative.isCorrect
                ? `${alternative.description} (Respuesta correcta)`
                : alternative.description
                }}
              </h4>
              <h4 v-if="findReply(questionAnswers[index]).answer.id  !== alternative.id" class="text-left"
                :style="{ color: alternative.isCorrect ? 'green' : '' }">
                {{
                alternative.isCorrect
                ? `${alternative.description} (Respuesta correcta)`
                : alternative.description
                }}
              </h4>
            </template>
          </v-radio>
        </v-radio-group>
      </v-container>

      <v-container  class="pa-5" fluid v-if="question.question.type === 2">
        <v-checkbox disabled v-for="(alternative, i) in question.question.answer" :key="i"
          :label="alternative.description" :value="alternative"
          :off-icon="getIconMultiple(alternative, questionAnswers[index])">
          <template v-slot:label>
            <h4 v-if="
              questionAnswers[index].answer.some(
                (val) => val.id === alternative.id
              ) && alternative.isCorrect
            " class="text-left" style="color: green">
              {{ alternative.description }} (Respuesta Correcta)
            </h4>
            <h4 v-else-if="
              questionAnswers[index].answer.some(
                (val) => val.id === alternative.id
              ) && !alternative.isCorrect
            " class="text-left" style="color: red">
              {{ alternative.description }}
            </h4>
            <h4 v-else-if="
              questionAnswers[index].answer.find(
                (val) => val.id !== alternative.id
              )
            " class="text-left" :style="{ color: alternative.isCorrect ? 'green' : '' }">
              {{
              alternative.isCorrect
              ? `${alternative.description} (Respuesta correcta)`
              : alternative.description
              }}
            </h4>
          </template>
        </v-checkbox>
      </v-container>
    </v-card>
    <v-card v-if="questions.length === 0">
      Texto no posee actividad.
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import LoadingComponent from "@/components/UI/LoadingComponent";

export default {
  props: ["timeAnswer"],
  components: { LoadingComponent },
  data() {
    return {
      questionAnswers: null,
      reply: null,
      questions: [],
      time: "",
      loading: null,
    };
  },
  methods: {
    findReply({ idQuestion }) {
      const reply = this.reply.find(el => el.idQuestion === idQuestion);
      return reply;
    },
    getIcon(alternative, answer) {
      if (alternative.id === answer.id && alternative.isCorrect) {
        return "mdi-check-circle";
      }
      if (alternative.id === answer.id && !alternative.isCorrect) {
        return "mdi-close-circle";
      }
      if (alternative.id !== answer.id) {
        return "mdi-checkbox-blank-circle-outline";
      }
    },
    getTimeFromId(id) {
      try {
        // Encontrar el objeto en timeAnswer que tenga el id coincidente
        const time = this.timeAnswer.find(val => val.id === id);
        const foundItem = parseFloat(time.time).toFixed(2);
        
        // Verificar si se encontró el objeto y devolver su propiedad time
        return foundItem ? foundItem : '';
      } catch (error) {
        console.error('Error al analizar ', error);
        return ''; // Devolver una cadena vacía en caso de error
      }
    },
    getIconMultiple(alternative, answer) {
      if (
        answer.answer.some((val) => val.id === alternative.id) &&
        alternative.isCorrect
      ) {
        return "mdi-checkbox-marked";
      }
      if (
        answer.answer.some((val) => val.id === alternative.id) &&
        !alternative.isCorrect
      ) {
        return "mdi-close-box";
      }
      if (answer.answer.some((val) => val.id !== alternative.id)) {
        return "mdi-checkbox-blank-outline";
      }
    },
    getColorAlternative(answer, alternative, correct) {
      if (!answer && correct) return "red";
      if (answer.id === alternative && answer.isCorrect) {
        return "green";
      }
      if (answer.id === alternative && !answer.isCorrect) {
        return "red";
      }
    },
    calculateScore(question, index) {
    if (question.question.type === 2) { // Pregunta de selección múltiple
      const correctAnswers = question.question.answer.filter(a => a.isCorrect);
      const studentAnswers = this.questionAnswers[index].answer;
      const allCorrect = correctAnswers.every(correctAnswer => 
        studentAnswers.some(studentAnswer => studentAnswer.id === correctAnswer.id && studentAnswer.isCorrect)
      );

      return allCorrect ? `${question.question.points}/${question.question.points}` : `0/${question.question.points}`;
    } else { // Pregunta de una sola respuesta correcta
      return this.findReply(this.questionAnswers[index]).answer.isCorrect ?
        `${question.question.points}/${question.question.points}` : `0/${question.question.points}`;
    }
  }
  },
  async created() {
    this.loading = true;
    let parse = JSON.parse(this.questionAnswers);
    let idActivity = this.$route.params.id;
    this.questionAnswers = parse;

    await axios
      .get("student/reply/" + idActivity)
      .then((response) => {
        const { reply } = response.data;
        this.questionAnswers = JSON.parse(reply.answer);
      });

    await axios.get("student/activity/" + idActivity).then((response) => {
      const { questions, reply } = response.data;
      this.reply = JSON.parse(reply);
      this.questions = questions;
    });
    this.loading = false;
  },
};
</script>