<template>
    <v-dialog v-model="dialog" persistent max-width="80%">
    <template v-slot:activator="{ on, attrs }" >
      <v-btn class="pa-3 text-caption" color="primary" v-bind="attrs" v-on="on">
          Aplazar la actividad a un estudiante
      </v-btn>
    </template>
  
    <v-container fluid>
          <v-card>
            <v-card-title class="primary white--text">Aplazar la actividad con fecha actual {{ this.activity.date }}</v-card-title>
            <v-form @submit.prevent="submit" ref="form" lazy-validation>
              <v-card-text>
                <v-autocomplete v-model="form.studentId" :items="student" item-text="name"
                  prepend-icon="mdi-format-title" label="Seleccione un Estudiante" required return-object>
                  <template v-slot:item="{ item }">
                    <span>
                      {{ item.name }} {{ item.lastName }} 
                    </span>
                  </template>
                </v-autocomplete>
                <date-component :date="form.date"  @date="form.date = $event" />
  
                <v-textarea prepend-icon="mdi-format-text-variant" v-model="form.description" auto-grow filled
                  color="deep-purple" label="Descripción" rows="3"></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn color="error" @click="cancel"> Cancelar </v-btn>
                <v-spacer></v-spacer>
                <v-btn  color="primary" type="submit">
                  Aplazar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
    </v-container>
    </v-dialog>
  </template>
  
  <script>
  import DateComponent from "@/components/UI/DateComponent";
  import axios from "axios";
  import Swal from "sweetalert2";
  export default {
    props: ['student','activity'],
    components: { DateComponent },
    computed: {
      isFormValid() {
       
        const isDateValid = new Date(`${this.form.date}T23:59:00-03:00`) >= new Date(`${this.activity.date}T23:59:00-03:00`);
        return isDateValid && this.form.description.trim() !== "" && this.form.studentId !== null;
      },
    },
    data() {
      return {
        dialog: false,
        form: {
          studentId: null,
          date: this.activity.date,
          description: "",
        },
      };
    },
    watch: {
      dialog(newVal) {
        if (newVal) {
          this.form.studentId = null;
          this.form.date = this.activity.date; // Cargar la fecha inicial de la actividad
          this.form.description = "";
        }
      }
    },
    methods: {
      async submit() {
        if (!this.isFormValid) {
          if (this.form.date <= this.activity.date) {
              // Mostrar un mensaje de error indicando que la fecha debe ser posterior a la fecha de la actividad
              await Swal.fire({
                  title: "Error",
                  text: "La fecha seleccionada debe ser posterior a la fecha de la actividad : " + this.activity.date,
                  icon: "error",
              });
              return; // Salir del método submit si la fecha no es válida
          }
          await Swal.fire({
            title: "Error",
            text: "Por favor, complete todos los campos",
            icon: "error",
          });
          return;
        }
        

        await Swal.fire({
            title: "¿Desea aplazar la actividad para el estudiante?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí!",
            cancelButtonText: "Volver",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let formattedDate = new Date(this.form.date).toISOString().split('T')[0];

                console.log()
                
                let data = {
                  activityId: this.activity.id,
                  studentId: this.form.studentId.id,
                  newDeadline: formattedDate,
                  description: this.form.description,
                };
                
                const response = await axios.post("teacher/activity/addPostponement", data);
                if (response.data.ok) {
                    await Swal.fire("Aplazada", "Activdad aplazada con exito", "success");
                    this.dialog = false;
                }else{
                  this.resetForm();
                  await Swal.fire("Error", "No se pudo aplazar la actividad", "error");
                }

            }
        });
      },
      cancel() {
        this.resetForm();
        this.dialog = false;
      },
      resetForm() {
        this.form.studentId = null;
        this.form.date = null;
        this.form.description = "";
    },  
    },
    async created() {
      
    },
  };
  </script>