import axios from "axios";

export default {
  async lessons(context) {
    let response = await axios.get("teacher/lesson");
    if (!response.data.ok) {
      const error = new Error(response.message || "Failed auth");
      throw error;
    }
    await context.commit("setLessons", response.data.lessons);
  },
  async texts(context) {
    let response = await axios.get("teacher/text");
    if (!response.data.ok) {
      const error = new Error(response.message || "Failed auth");
      throw error;
    }

    await context.commit("setTexts", response.data.texts);
  },
  async createLesson(context, data) {
    const lessonData = {
      name: data.name,
      year: data.year,
      period: data.period,
      description: data.description,
      image: "prueba@udec.cl",
    };

    await axios.post("teacher/lesson", lessonData);

    await context.dispatch("lessons");
  },
  async pollActivitiesInLesson({ commit }, lessonId) {
    try {
      // Obtener actividades
      const activitiesResponse = await axios.get(
        `teacher/lesson/${lessonId}/poll-activities`
      );
      const pollActivities = activitiesResponse.data.pollActivities;

      // Obtener encuestas
      const polls = activitiesResponse.data.poll;

      // Relacionar actividades con sus encuestas
      const updatedPollActivities = pollActivities.map((activity) => {
        const pollData = polls.find((poll) => poll.id === activity.poll_id);
        return {
          ...activity,
          poll: pollData || null, // Agrega la encuesta o null si no se encuentra
        };
      });

      // Hacer commit de las actividades actualizadas
      commit("setPollActivities", updatedPollActivities);
    } catch (error) {
      console.error("Error al obtener actividades de diagnósticos:", error);
    }
  },

  async createText(context, formData) {
    try {
      const response = await axios.post("teacher/text", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Server response:", response.data);

      await context.dispatch("texts");
      // await context.dispatch('lessons');

      return response.data;
    } catch (error) {
      console.error(
        "Error in createText:",
        error.response ? error.response.data : error
      );
      throw error;
    }
  },

  async studentsInLesson(context, data) {
    const response = await axios.get("teacher/lesson_student/" + data);
    await context.commit("setStudentInLessons", response.data.students);
  },
  async activitiesInLesson(context, data) {
    const response = await axios.get(
      "teacher/activity/getAllActivitiesForLesson/" + data
    );

    await context.commit("setActivitiesInLesson", response.data.activities);
  },

  async createQuestion(context, data) {
    try {
      console.log(data);
      await axios.post("teacher/question", data);
    } catch (e) {
      console.log(e);
    }
  },
  async questions(context, data) {
    try {
      let response = await axios.get("teacher/question/text/" + data);
      await context.commit("setQuestions", response.data.questions);
    } catch (e) {
      console.log(e);
    }
  },
  async question(context, data) {
    let response = await axios.get("teacher/question/" + data);
    if (!response.data.ok) {
      const error = new Error(response.message || "Failed auth");
      throw error;
    }
    await context.commit("setQuestion", response.data.question);
    await context.commit("setAnswers", response.data.question.answer);
  },
  async lesson(context, data) {
    let response = await axios.get("teacher/lesson/" + data);
    await context.commit("setLesson", response.data.lesson);
  },
  async poll(context) {
    let response = await axios.get("teacher/poll");
    if (!response.data.ok) {
      const error = new Error(response.message || "Failed auth");
      throw error;
    }
    await context.commit("setPoll", response.data.polls);
  },
};
