<template>
  <v-dialog persistent v-model="dialog" max-width="95%">
    <v-container fluid v-if="pollActivity">
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between primary white--text">
          <div class="d-flex justify-center align-center">
            Diagnóstico {{ pollActivity.id }}
            <v-chip class="ml-2" small>{{ text?.title || 'Sin texto asociado' }}</v-chip>
          </div>
          <div class="d-flex align-center">
            <v-chip :color="getStatusClass(pollActivity)" class="mr-2 ">
              {{ new Date(`${pollActivity.limit}T23:59:00-03:00`) >= new Date() ? 'Activo' : 'Terminado' }}
            </v-chip>
            <v-btn  class="mt-2 ml-3" color="red"  @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-row class="ma-4">
          
          <v-col cols="12" md="6" class="pr-4 outlined">
            <div class="d-flex justify-space-between mt-1 mx-4 ">
              <div class="statistic-card">
                <h2>{{ formatTime(timeSpentAverage) }}</h2>
                <p>Tiempo promedio</p>
              </div>
              <div class="statistic-card">
                <h2>{{ replies.length }}</h2>
                <p>Estudiantes respondieron</p>
              </div>
              <div class="statistic-card">
                <h2>{{ completionStatus?.completed.count }}</h2>
                <p>Completaron</p>
              </div>
              <div class="statistic-card">
                <h2>{{ completionStatus?.remaining.count }}</h2>
                <p>Pendientes</p>
              </div>
            </div>
            <v-tabs v-model="sectionTab" grow>
              <v-tab v-for="(section, index) in sections" :key="index">
                {{ section.title }}
              </v-tab>
            </v-tabs>

            <v-tabs-items touchless v-model="sectionTab">
              <v-tab-item v-for="(section, index) in sections" :key="index">
                <v-card class="mt-4">
                  <div class="scrollable-content">
                    <v-card v-for="(question, qIndex) in section.questions" :key="qIndex" class="mb-4">
                      <v-card-title style="background-color: rgba(63, 81, 181, 0.8);" class="white--text book-paragraph " >
                        Pregunta {{ qIndex + 1 }}: {{ question.description }}
                      </v-card-title>
                      
                      <v-card-text>
                        <div class=" caption d-flex justify-space-between align-center mb-2">
                          <v-chip small>Tipo: {{ getQuestionType(question.type) }}</v-chip>
                        </div>

                        <template v-if="question.type !== 3">
                          <v-divider class="mb-4"></v-divider>
                          <div class="d-flex flex-column">
                            <div v-for="(alternative, j) in (question.type === 2 ? getSectionAnswersYESNO(section.title,question): getSectionAnswers(section.title,question))" :key="j">
                              <div class="d-flex justify-space-between">
                                <div class="d-flex statistic-card">
                                  {{ j + 1 }})
                                  <h4 class="ml-2 text-left">
                                    {{ alternative.description }}
                                  </h4>
                                </div>
                                <div class="statistic-card justify-center align-content-center">
                                  <v-btn @click="openModal('alternative', question.id, alternative.id)">
                                    <h4 class="text-left">
                                      {{ getAnswerCount(question.id, alternative.id) }} Respuestas
                                    </h4>
                                  </v-btn>
                                </div>
                                <div class="statistic-card justify-center align-content-center">
                                  <v-progress-linear
                                    :value="getAnswerPercentage(question.id, alternative.id)"
                                    color="primary"
                                    height="20"
                                    rounded
                                    striped
                                  >
                                    {{ getAnswerPercentage(question.id, alternative.id) }}%
                                  </v-progress-linear>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>

                        <template v-else>
                          <v-btn block  @click="openModal('development', question.id, false)" class=" statistic-card mt-4">
                            Ver respuestas de desarrollo
                          </v-btn>
                        </template>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>

          <v-col cols="12" md="6" class="outlined">
            <div class="d-flex justify-space-between align-center">
              <v-tabs v-model="resultsTab" grow>
              <v-tab>Resultados</v-tab>
              <v-tab>Finalizado</v-tab>
              <div class="d-flex justify-center align-center">
                <EditPollActivity :pollActivity="pollActivity" @poll-activity-updated="loadData" />
              </div>
            </v-tabs>
            <info-button
                class="mx-6"
                title="Información sobre el dashboard de diagnóstico"
                message="
                <ul>
                  <li><strong>Estructura general:</strong> El dashboard se divide en dos secciones principales. A la izquierda se muestran las preguntas del diagnóstico con sus estadísticas y a la derecha los resultados detallados.</li>
                  
                  <li><strong>Sección izquierda:</strong>
                    <ul>
                      <li>Tarjetas con métricas clave: tiempo promedio, respuestas recibidas, completados y pendientes</li>
                      <li>Pestañas por secciones del diagnóstico</li>
                      <li>Detalle de cada pregunta con porcentajes de respuestas</li>
                      <li>Botones para ver quien respondio cada alternativa o ver respuestas de desarollo</li>
                    </ul>
                  </li>

                  <li><strong>Sección derecha:</strong>
                    <ul>
                      <li>Pestaña de Resultados: Tabla con todas las respuestas de estudiantes</li>
                      <li>Pestaña de Gráficos: Visualización de dona con progreso de completado</li>
                      <li>Botón de edición del diagnóstico</li>
                    </ul>
                  </li>

                  <li><strong>Funcionalidades clave:</strong>
                    <ul>
                      <li>Click graficos para ver a detalle los estudiantes específicos</li>
                      <li>Edición de parámetros globales del diagnóstico</li>
                      <li>Visualización de tiempos y diagnósticos individuales</li>
                    </ul>
                  </li>
                </ul>
                "
              ></info-button>
            </div>

            <v-tabs-items touchless v-model="resultsTab">
              <v-tab-item>
                <v-card class="flex-grow-1">
                  <v-card-title class="d-flex justify-space-between align-center">
                    <span>Respuesta de los estudiantes</span>
                  </v-card-title>
                  <NormalReplyDiagnosticTable
                    class="pa-2"
                    v-if="replies.length > 0"
                    :replies="replies"
                    :total-points="totalPoints"
                    :poll-activity="pollActivity"
                  />
                  <v-alert v-else type="info">
                    No hay respuestas disponibles aún
                  </v-alert>
                </v-card>

              </v-tab-item>
              
              <v-tab-item>
                <v-card class="flex-grow-1">
                  <v-card-title class="d-flex justify-space-between align-center">
                    <span>Gráfico de Término</span>
                  </v-card-title>
                  <DoughnutChart v-show="chartDonutData" :chartData="chartDonutData" :options="chartDonutOptions" @click.native="handleDoughnutClick"></DoughnutChart>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <StudentModal :isPollActivity="true" :show.sync="showModal" :students="modalStudents" :title="modalTitle" />
    <ModalResponses :isPollActivity="true" v-if="showAlternativeModal" :showModal.sync="showAlternativeModal" :questionId="selectedQuestionId" :alternativeId="selectedAlternativeId" modalType="alternative" :replies="replies" />
    <ModalResponses :isPollActivity="true" v-if="showDevelopmentModal" :showModal.sync="showDevelopmentModal" :questionId="selectedQuestionId" :alternativeId="selectedAlternativeId" modalType="development" :replies="replies" />
  </v-dialog>
</template>

<script>
import axios from 'axios';
import DoughnutChart from '@/components/UI/DoughnutChart.vue';
import StudentModal from '@/components//UI/StudentModal.vue';
import EditPollActivity from '@/components/teacher/lesson/polls/forms/EditPollActivity.vue';
import ModalResponses from '@/components/UI/ModalResponses.vue';
import NormalReplyDiagnosticTable from './NormalReplyDiagnosticTable.vue';
import InfoButton from '@/components/UI/InfoButton.vue';

export default {
  props: ['pollActivity', 'dialog'],
  components: { DoughnutChart,NormalReplyDiagnosticTable, ModalResponses, EditPollActivity, StudentModal, InfoButton },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  data() {
    return {
      sectionTab : 0,
      resultsTab:0,
      timeSpentAverage: null,
      chartDonutData: null,
      chartDonutOptions: { responsive: true },
      replies: [],
      text: null,
      timeSpent: [],
      modalStudents: null,
      modalTitle: "",
      showModal: false,
      selectedQuestionId: null,
      selectedAlternativeId: null,
      sections: [],
      questions: [],
      limit: null,
      description: null,
      completionStatus: null,
      answers: [],
      showDevelopmentModal: false,
      showAlternativeModal: false,
    };
  },
  computed: {
    totalPoints() {
      return this.pollActivity?.total_points || 50; // Ajustar según tu modelo de datos
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue === true) {
        this.loadData();
      } else {
        this.resetData();
      }
    },
    pollActivity: {
      handler(newValue) {
        if (newValue) {
          this.text = newValue.text || null;
          this.questions = newValue.questions || [];
          this.description = newValue.description || null;
          this.limit = newValue.limit || null;
          this.answers = newValue.answers || [];
        } else {
          this.resetData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getQuestionType(type) {
      const types = {
        1: 'Elección de Respuestas Globales',
        2: 'Sí o No',
        3: 'Desarrollo'
      };
      return types[type] || 'Desconocido';
    },
    
    getAlternativeColor(altId, correctAnswer) {
      return altId === correctAnswer ? 'success' : 'primary';
    },
    resetData() {
      this.timeSpentAverage = null;
      this.chartDonutData = null;
      this.replies = [];
      this.timeSpent = [];
      this.sections = [];
      this.questions = [];
      this.limit = null;
      this.description = null;
      this.completionStatus = null;
      this.answers = [];
      this.showDevelopmentModal = false;
      this.showAlternativeModal = false;
      this.text = null;
    },
    async loadData() {
      if (!this.pollActivity) return;
      
      await Promise.all([
        this.getReplies(),
        this.getActivityCompletionStatus(),
      ]);
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = (seconds % 60).toFixed(1);
      return minutes > 0 ? `${minutes}m ${remainingSeconds}s` : `${remainingSeconds}s`;
    },
    getSectionAnswers(sectionTitle) {
      return this.answers.filter(answer => answer.section === sectionTitle && answer.description !== 'Sí' && answer.description !== 'No');
    },
    getSectionAnswersYESNO(sectionTitle, question) {
      const answers = this.answers.filter(answer => answer.section === sectionTitle && (answer.description === 'Sí' || answer.description === 'No'));
      if (answers.length === 0) {
        return [
          { description: 'Sí', id: `yes_${question.id}` },
          { description: 'No', id: `no_${question.id}` }
        ];
      }
      return answers;
    },
    renderCharts() {
      if (this.chartDonutData) {
        this.getDonutChart();
      }
    },
    getStatusClass(item) {
      return new Date(`${item.limit}T23:59:00-03:00`) >= new Date() ? 'blue' : 'red';
    },
    getAnswerCount(questionId, answerId) {
      return this.replies.reduce((count, reply) => {
        const answers = Object.values(reply.answer); // Obtener un array de las respuestas
        const answer = answers.find(a => a.questionId === questionId && a.answerId === answerId);
        return count + (answer ? 1 : 0);
      }, 0);
    },
    getAnswerPercentage(questionId, answerId) {
      const totalReplies = this.replies.length;
      const answerReplies = this.getAnswerCount(questionId, answerId);
      return totalReplies > 0 ? ((answerReplies / totalReplies) * 100).toFixed(1) : 0;
    },
    openModal(type, questionId, alternativeId) {
      this.selectedAlternativeId = alternativeId;
      this.selectedQuestionId = questionId;
      if (type === 'alternative') {
        this.showAlternativeModal = true;
      } else if (type === 'development') {
        this.showDevelopmentModal = true;
      }
    },
    handleDoughnutClick(event, array) {
      if (array.length > 0) {
        const index = array[0]._index;
        const label = this.chartDonutData.labels[index];

        let students = [];
        if (label === 'Terminado') {
          students = this.completionStatus.completed.students;
          this.modalTitle = 'Estudiantes que han terminado el diagnóstico';
        } else {
          students = this.completionStatus.remaining.students;
          this.modalTitle = 'Estudiantes que aún no han terminado el diagnóstico';
        }
        this.modalStudents = students.map(student => {
          const reply = this.replies.find(reply => reply.user_id === student.id);
          const time = reply ? this.formatTime(reply.time) : 0;
          const points = reply ? reply.points : 0;
          return {
            id: student.id,
            name: student.name + ' ' + student.lastName,
            time: time,
            points: points  
          };
        });

        this.showModal = true;
      }
    },
    getDonutChart() {
      this.chartDonutData = {
        labels: ['Terminado', 'Faltantes'],
        datasets: [
          {
            data: [this.completionStatus.completed.count, this.completionStatus.remaining.count],
            backgroundColor: ['#36A2EB', '#FF6384'],
            borderColor: ['#36A2EB', '#FF6384']
          }
        ]
      };
      this.chartDonutOptions = {
        maintainAspectRatio: false,
        onClick: this.handleDoughnutClick
      };
    },
    async getReplies() {
      await axios.get(`teacher/poll_activity/replies/${this.pollActivity.id}`)
        .then(response => {
          if (response.data.ok) {
            this.replies = response.data.replies.map(reply => ({
              ...reply,
              answer: JSON.parse(reply.answer)
            }));

            console.log(this.replies)

            // Asignar sección predeterminada si no está definida
            this.questions = this.questions.map(question => ({
              ...question,
              section: question.section || 'Sección Predeterminada'
            }));

            this.answers = this.answers.map(answer => ({
              ...answer,
              section: answer.section || 'Sección Predeterminada'
            }));

            // Organizar las preguntas en secciones
            this.questions.forEach((question) => {
              const sectionIndex = this.sections.findIndex(section => section.title === question.section);
              if (sectionIndex === -1) {
                this.sections.push({ title: question.section, questions: [question] });
              } else {
                this.sections[sectionIndex].questions.push(question);
              }
            });

            this.timeSpent = this.replies.map(reply => reply.time);
            this.timeSpentAverage = (this.timeSpent.reduce((a, b) => a + b, 0) / this.timeSpent.length).toFixed(1);
          } else {
            console.error("No replies found:", response.data.error);
          }
        })
        .catch(error => {
          console.error("Error fetching replies:", error);
        });
    },
    async getActivityCompletionStatus() {
      await axios.get(`teacher/poll_activity/getPollActivityCompletionStatus/${this.pollActivity.id}`)
        .then(response => {
          this.completionStatus = response.data;
          this.getDonutChart();
          this.renderCharts();
        })
        .catch(error => {
          console.error("Error fetching completion status:", error);
        });
    },
    closeDialog() {
      this.resetData();
      this.$emit('close-dialog');
    }
  },
  created() {
    this.text = this.pollActivity && this.pollActivity.text ? this.pollActivity.text : null;
  }
};
</script>
